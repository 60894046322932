import { Button, Collapse, IconButton, Paper, Tooltip, Typography, colors } from "@mui/material";
import { SummaryItemDto } from "../../../types/Common/SummaryItemDto";
import { useState } from "react";
import InfoIcon from '@mui/icons-material/Info';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import "./SummaryItem.css"
import { LR } from ".././Help/LR";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { IconDisplayer } from ".././Help/IconDisplayer";
import i18n from "../../../i18n";

type Args = {
  items: SummaryItemDto[]
  linkCallBack: (item: SummaryItemDto) => void
  hideIcon? : boolean
  useNameInsteadOfLabel? : boolean
};

const SummaryItemCollection = ({ hideIcon, items, useNameInsteadOfLabel, linkCallBack }: Args) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const handleInfoIconClick = (itemName: string) => {
    setExpandedItems((prevExpandedItems) => {
      if (prevExpandedItems.includes(itemName)) {
        return prevExpandedItems.filter((id) => id !== itemName);
      } else {
        return [...prevExpandedItems, itemName];
      }
    });
  };

  const handleArrowIconClick = (item: SummaryItemDto) => {
    linkCallBack(item);
  };

  return (
    <>
      {items.map((item, index) => (
        <Paper key={index} elevation={3} className="fa-paper ">
          <div className="d-flex align-items-center">
            <div className="fa-name flex-grow-1">
              {!hideIcon && <IconDisplayer type={item.label}></IconDisplayer>}
              <Typography variant="h6" >
                <Button type="button" onClick={() => handleArrowIconClick(item)} className="linkbutton" key={item.id}>
                  {
                    useNameInsteadOfLabel && item.name
                  }
                  {
                    !useNameInsteadOfLabel && <LR localResource={item.label}></LR> 
                  }
                  
                  {item.hasCounter && <small>&nbsp;({item.counter})</small>}
                </Button>
              </Typography>
            </div>
            <div className={`fa-paper-cta-description d-flex align-items-center ${item.callToActionDescription === 'COMMON_SUMMARY_ACTIONRECOMMENDED' ? 'fa-paper-cta-recommended' : ''}`}>
            
              {item.callToActionDescription && (
                <Typography variant="body2" style={{ fontSize: '12px' }}>
                  <LR localResource={item.callToActionDescription}></LR>
                </Typography>
              )}
              {item.callToActionDetail && (
                <div>
                  <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                  <IconButton className="fa-info" onClick={() => handleInfoIconClick(item.name)}>
                    <InfoIcon />
                  </IconButton>
                  </Tooltip>
                </div>
              )}
              {!item.callToActionDescription && item.counter! > 0 && (
                <Tooltip title={i18n.t('COMMON_TOOLTIP_GREEN')}>
                  <CheckCircleRoundedIcon className="fa-paper-cta-good" />
                </Tooltip>
              )}
            </div>
            <div className="fa-icons">
              <IconButton onClick={() => handleArrowIconClick(item)} className="fa-arrow">
                <ArrowForwardIosRoundedIcon />
              </IconButton>
            </div>
          </div>

          {item.callToActionDetail && (
            <Collapse in={expandedItems.includes(item.name)}>
              <Typography variant="body1" style={{ marginTop: '16px' }} key={item.id}>
                {item.callToActionDetail.split(',').map((detail, index) => (
                  <small className="cta-description ml-2" key={index}>
                    <LR localResource={detail.replace('LABEL', 'DESCRIPTION').trim()} key={index} /><br />
                  </small>
                ))}
              </Typography>
            </Collapse>
          )}
        </Paper>

      ))}
    </>
  );
}

export {
  SummaryItemCollection
}