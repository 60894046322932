import { Typography } from "@mui/material";
import { useAddMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { MemberForm } from "./MemberForm";
import { useParams } from "react-router";
import { useState } from "react";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import { LR } from "../Common/Help/LR";

type Args =
    {
        userLogged: ApplicationUser,
        setupCallBack: (updatedUser: ApplicationUser) => void
    }

const MemberSetup = ({ userLogged, setupCallBack }: Args) => {
    const [loggedUser, setLoggedUser] = useState<ApplicationUser>(userLogged);

    const mutationCallBack = async (id: string) => {

        const response = await fetch(`${HookConfig.memberUrl}/${id}`, { headers: operatorDefaultHeaders() });
        if (response.ok && response.status === 200) {
            const data = await response.json();
            loggedUser.operator = data;
            loggedUser.doesNeedSetup = false;
            setupCallBack(loggedUser)
        }
    }

    const params = useParams();
    const parentId = params.parentId;
    const memberType = 'Operator'
    const [submitting, setSubmitting] = useState(false);
    const addMemberMutation = useAddMember(memberType, parentId, mutationCallBack);
    const createMemberMutation = useAddMember('User-Contact');
    
    const AddMember = async (member: MemberRequestDto) => {
        setSubmitting(true);
        if (!userLogged.memberId) {
            const memberRequest: MemberRequestDto = {
                countryIso3Code: userLogged.country,
                enrolmentCode: '',
                fullName: userLogged.name,
                languageCode: i18n.language,
                externalReference: GenerateExternalReference(userLogged.givenName),
                memberTypeName: 'OperatorContact',
                person: {
                    email: userLogged.email,
                    firstName: userLogged.givenName,
                    jobTitle: '',
                    lastName: userLogged.familyName,
                }
            };


            createMemberMutation.mutateAsync(memberRequest)
                .then(response => {
                    setLoggedUser(prev => ({ ...prev, memberId: response.data.id }));
                    userLogged.memberId = response.data.id;
                    addMemberMutation.mutate(member);
                })
                .catch(error => {
                    console.error(error);

                });



        } else {
            addMemberMutation.mutate(member)
        }

        setSubmitting(false);
    }


    const member: MemberRequestDto = {
        countryIso3Code: '',
        enrolmentCode: '',
        fullName: '',
        languageCode: i18n.language,
        memberTypeName: memberType
    };

    //#region Dirty
    const handleDirty = () => {

    };




    return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-4 col-11 justify-content-center">
                    <Typography variant="h5"><LR localResource="MEMBER_TITLE_NEWOPERATOR"></LR></Typography>

                    <LR localResource="MEMBER_MESSAGE_SETUP"></LR>
                    <p></p>
                </div>
            </div>
            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-11 justify-content-center">
                    <MemberForm
                        handleDirty={handleDirty}
                        type='Operator'
                        submitting={submitting}
                        member={member}
                        isEdit={false}
                        submitted={(member) => AddMember(member)}
                    />
                </div>
            </div>

            {(addMemberMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={addMemberMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { MemberSetup }