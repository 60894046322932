import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import NotificationsActiveRoundedIcon from '@mui/icons-material/NotificationsActiveRounded';
import CardTravelRoundedIcon from '@mui/icons-material/CardTravelRounded';
import CurrencyPoundRoundedIcon from '@mui/icons-material/CurrencyPoundRounded';
import ContactPhoneRoundedIcon from '@mui/icons-material/ContactPhoneRounded';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import NextWeekRoundedIcon from '@mui/icons-material/NextWeekRounded';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ChecklistRoundedIcon from '@mui/icons-material/ChecklistRounded';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ShareIcon from '@mui/icons-material/Share';
import LanguageIcon from '@mui/icons-material/Language';
import ImageIcon from '@mui/icons-material/Image';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import HealthAndSafetyRoundedIcon from '@mui/icons-material/HealthAndSafetyRounded';
import VolunteerActivismRoundedIcon from '@mui/icons-material/VolunteerActivismRounded';
import DirectionsSubwayRoundedIcon from '@mui/icons-material/DirectionsSubwayRounded';
import ChairAltRoundedIcon from '@mui/icons-material/ChairAltRounded';
import CelebrationRoundedIcon from '@mui/icons-material/CelebrationRounded';
import CoPresentRoundedIcon from '@mui/icons-material/CoPresentRounded';
import EnergySavingsLeafRoundedIcon from '@mui/icons-material/EnergySavingsLeafRounded';
import AccessibleRoundedIcon from '@mui/icons-material/AccessibleRounded';
import RestaurantMenuRoundedIcon from '@mui/icons-material/RestaurantMenuRounded';
import SpaRoundedIcon from '@mui/icons-material/SpaRounded';
import WeekendRoundedIcon from '@mui/icons-material/WeekendRounded';
import VideoCameraBackRoundedIcon from '@mui/icons-material/VideoCameraBackRounded';
import ArchitectureRoundedIcon from '@mui/icons-material/ArchitectureRounded';
import PendingActionsRoundedIcon from '@mui/icons-material/PendingActionsRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import RunningWithErrorsRoundedIcon from '@mui/icons-material/RunningWithErrorsRounded';
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SignpostIcon from '@mui/icons-material/Signpost';
import PermMediaRoundedIcon from '@mui/icons-material/PermMediaRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import VideoLibraryRoundedIcon from '@mui/icons-material/VideoLibraryRounded';
import FormatColorTextRoundedIcon from '@mui/icons-material/FormatColorTextRounded';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import GavelRoundedIcon from '@mui/icons-material/GavelRounded';
import BrandingWatermarkRoundedIcon from '@mui/icons-material/BrandingWatermarkRounded';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import SettingsSharpIcon from '@mui/icons-material/SettingsSharp';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import InventoryIcon from '@mui/icons-material/Inventory';
import LandscapeIcon from '@mui/icons-material/Landscape';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import HistoryIcon from '@mui/icons-material/History';
import SensorsRoundedIcon from '@mui/icons-material/SensorsRounded';
import AnnouncementRoundedIcon from '@mui/icons-material/AnnouncementRounded';
import Groups2RoundedIcon from '@mui/icons-material/Groups2Rounded';
import HubIcon from '@mui/icons-material/Hub';
import LocalParking from '@mui/icons-material/LocalParking';
import ChecklistIcon from '@mui/icons-material/Checklist';
import PortraitIcon from '@mui/icons-material/Portrait';
import InfoIcon from '@mui/icons-material/Info';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CodeIcon from '@mui/icons-material/Code';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';



type Args = {
    type: string,
    titleAccess?: string
    className?: string

};

const IconDisplayer = ({ type, titleAccess, className }: Args) => {

    if (className === 'undefined') {
        className = ''
    }

    switch (type) {
        case "LABEL_EDI_LOCATIONIMPORT":
        case "LABEL_EDI_INVENTORYIMPORT":
        case "LABEL_EDI_IMAGEIMPORT":
        case "LABEL_EDI_NEARBYIMPORT":
        case "LABEL_EDI_FEATUREIMPORT":
        case "LABEL_EDI_LOCATIONINVENTORYSUMMARYIMPORT":
        case "LABEL_EDI_OPERATORIMPORT":
        case "LABEL_EDI_NETWORKIMPORT":
        case "COMMON_LABEL_JOININGINSTRUCTIONS":
        case "COMMON_LABEL_OTHERDOCUMENTS":
            return <UploadFileIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "LABEL_EDI_LOCATIONEXPORT":
        case "LABEL_EDI_INVENTORYEXPORT":
        case "LABEL_EDI_IMAGEEXPORT":
        case "LABEL_EDI_NEARBYEXPORT":
        case "LABEL_EDI_FEATUREEXPORT":
        case "LABEL_EDI_LOCATIONINVENTORYSUMMARYEXPORT":
        case "LABEL_EDI_OPERATOREXPORT":
        case "LABEL_EDI_EVENTATTENDEEEXPORT":
        case "LABEL_EDI_NETWORKEXPORT":
            return <SimCardDownloadIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "LABEL_EDI_LOCATIONIMPORTHISTORY":
        case "LABEL_EDI_INVENTORYIMPORTHISTORY":
        case "LABEL_EDI_OPERATORIMPORTHISTORY":
            return <HistoryIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "MENU_LABEL_PRODUCTS":
            return <span className="material-symbols-outlined">inventory</span>
        case "LABEL_PRODUCTTYPE_COWORKING":
            return <span className="material-symbols-outlined">chair_alt</span>
        case "LABEL_PRODUCTTYPE_EVENT":
            return <span className="material-symbols-outlined">groups</span>
        case "LABEL_PRODUCTTYPE_MEETINGROOM":
            return <span className="material-symbols-outlined">meeting_room</span>
        case "LABEL_PRODUCTTYPE_OFFICE":
            return <span className="material-symbols-outlined">table_restaurant</span>
        case "COMMON_LABEL_BRANDLOGO":
            return <LandscapeIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_INVENTORY":
            return <InventoryIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_LEGALENTITIES":
            return <LocalPoliceIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_CONFIGURATION":
        case "LOCATION_LABEL_CONFIGURATION":
            return <SettingsSharpIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "PRICES_TITLE_PRICES":
            return <CurrencyPoundIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "LOCATION_LABEL_INVENTORIES":
            return <HomeWorkIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_LABEL_LOGOS":
            return <BrandingWatermarkRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_LABEL_TNC":
            return <GavelRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "MEMBER_LABEL_BUSINESSADDRESS":
            return <BusinessRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "MEMBER_LABEL_GOVERNMENTID":
            return <BrandingWatermarkRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_DESCRIPTION":
        case "COMMON_SUMMARY_FULLDESCRIPTION":
        case "COMMON_SUMMARY_CONTENT":
        case "COMMON_SUMMARY_CHECKOUTNOTE":
            return <FormatColorTextRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_TITLE_ADDRESS":
            return <SignpostIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_LABEL_FILEREJECTED":
            return <ErrorRoundedIcon color="error" titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_LABEL_FILECOMPLETED":
            return <AddTaskRoundedIcon color="success" titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "CompletedWithError":
            return <RunningWithErrorsRoundedIcon color="error" titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_LABEL_FILEPENDING":
            return <PendingActionsRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "InProcess":
            return <HourglassTopRoundedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_COMPANYDETAILS":
            return <NextWeekRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_NOTIFICATIONS":
            return <NotificationsActiveRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_FISCAL-DETAILS":
            return <CurrencyPoundRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_KYC-DETAILS":
            return <CardTravelRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_CONTACT-MANAGEMENT":
            return <ContactPhoneRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_CONTACTS":
        case "MEMBER_TITLE_NEWCONTACT":
            return <ContactPageIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_ENROLMENTCODES":
            return <QrCodeIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_OPERATIONAL-DETAILS":
            return <AssignmentRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_LOCATIONS":
        case "COMMON_SUMMARY_LOCATIONDETAILS":
            return <ApartmentRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_PRODUCTS":
            return <ShoppingCartIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_STATUS":
            return <DonutLargeIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_PHONE":
            return <CallIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_EMAIL":
        case "COMMON_SUMMARY_COMMUNICATIONS":
            return <EmailIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_SOCIAL":
            return <ShareIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_WEB":
            return <LanguageIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_DIGITALASSETS":
        case "COMMON_SUMMARY_IMAGES":
        case "COMMON_SUMMARY_EVENTIMAGES":
        case "COMMON_SUMMARY_MEMBERSHIPIMAGES":
        case "COMMON_LABEL_MAINIMAGE":
            return <ImageIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_IMAGE_ADDITIONALIMAGE":
            return <PermMediaRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_DOMAINS":
            return <AlternateEmailIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_FEATURES":
        case "COMMON_SUMMARY_EVENTFEATURES":
        case "COMMON_SUMMARY_MEMBERSHIPFEATURES":
        case "COMMON_SUMMARY_BASICFEATURES":
            return <ChecklistRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_DESCRIPTIONS":
            return <DescriptionRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_HEALTHANDSAFETY":
        case "COMMON_LABEL_HEALTHSAFETY":
        case "COMMON_LABEL_HEALTHANDSAFETY":
            return <HealthAndSafetyRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_FACILITIES":
            return <VolunteerActivismRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_TRANSPORT":
            return <DirectionsSubwayRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_SEATING":
            return <ChairAltRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_COMMUNITY":
            return <CelebrationRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_EQUIPMENT":
            return <CoPresentRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_GREENFEATURES":
            return <EnergySavingsLeafRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_ACCESSIBILITY":
            return <AccessibleRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_CATERING":
            return <RestaurantMenuRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_HEALTHFACILITIES":
            return <SpaRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_RELAXZONES":
            return <WeekendRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_VIDEOSVIRTUALTOURS":
        case "COMMON_LABEL_VIRTUALTOUR":
            return <VideoCameraBackRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_FLOORPLAN":
        case "COMMON_SUMMARY_FLOORPLANSDOCUMENTS":
        case "COMMON_SUMMARY_EVENTDOCUMENTS":
        case "COMMON_SUMMARY_MEMBERSHIPDOCUMENTS":
        case "COMMON_SUMMARY_DOCUMENTS":
        case "COMMON_DIGITALASSET_FLOORPLAN":
            return <ArchitectureRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_FAQ":
            return <PictureAsPdfRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_VIDEO":
            return <VideoLibraryRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_APPLICATIONNETWORKS":
            return <SensorsRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_APPLICATIONPOSTS":
            return <AnnouncementRoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_PARTNEROPERATOR":
            return <Groups2RoundedIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_MEMBERNETWORKS":
            return <HubIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_PARKING":
            return <LocalParking className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_INTERESTS":
            return <ChecklistIcon className={`icon-container ${className}`} />
        case "COMMON_SUMMARY_OPENTO":
            return <PortraitIcon className={`icon-container ${className}`} />
        case "COMMON_LABEL_PRODUCTDETAILS":
            return <InfoIcon className={`icon-container ${className}`} />
        case "FORMAT_BOLD":
            return <FormatBoldIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "FORMAT_ITALIC":
            return <FormatItalicIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "FORMAT_UNDERLINED":
            return <FormatUnderlinedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "INSERT_LINK":
            return <LinkIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "LIST_ORDERED":
            return <FormatListNumberedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "LIST_BULLETED":
            return <FormatListBulletedIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "VIEW_SOURCE":
            return <CodeIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        case "INSERT_IFRAME":
            return <OndemandVideoIcon titleAccess={titleAccess} className={`icon-container ${className}`} />;
        default:
            return <>{type}</>
    }

}

export {
    IconDisplayer
}