import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import { useFetchMembershipById, useUpdateMembershipDescription } from '../../hooks/Memberships/membership';
import { MembershipResponseDto } from '../../types/Membership/MembershipResponseDto';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import 'react-quill/dist/quill.snow.css';
import './MembershipDescription.css';
import '../../main/App.css'


type Args =
    {
        userLogged: ApplicationUser
    }

const MembershipDescription = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Membership id needed");

    const emptyMembershipResponseDto: MembershipResponseDto = {
        id: params.id,
        name: '',
        inventoryTypeName: '',
        locationId: '00000000-0000-0000-0000-000000000000',
        inventoryVariantId: '00000000-0000-0000-0000-000000000000',
        quantity: 0,
        quantityLimitPerOrder: 0,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        soldQuantity: 0,
        locationName: '',
        shortDescription: '',
        description: '',
    };

    const [descriptionState, setDescriptionState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { data: membership, status, isSuccess } = useFetchMembershipById(params.id)
    const [useMembership, setUseMembership] = useState(emptyMembershipResponseDto);

    useEffect(() => {
        if (isSuccess && membership) {
            setUseMembership(membership);
            setDescriptionState(membership.description);
        }
    }, [isSuccess, membership]);

    const handleDescriptionChange = (value: string) => {
        setDescriptionState(value);
        setUseMembership({ ...useMembership, description: value });
        setUnsavedChanges(true); 
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            updateMutation.mutate(useMembership);
            e.preventDefault();
            setSnackbarOpen(true); // Show snackbar
            setUnsavedChanges(false);
        } catch (err: any) {
            console.error(err);
        }
    }

    const linkCallBack = () => {
        nav(`/memberships/${membership!.id}/summary`);
    }

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const updateMutation = useUpdateMembershipDescription(linkCallBack);

    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!membership) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGE")!}
                name={`${membership.name} (${membership.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}
                description={i18n.t('COMMON_SUMMARY_MEMBERSHIPMANAGEMENT')} />

                <CustomQuillEditor
                        value={descriptionState} 
                        onChange={handleDescriptionChange}
                        placeholder={i18n.t("MEMBERSHIP_LABEL_DESCRIPTION", { membershipName: membership.name })}
                />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {unsavedChanges && (
                <div 
                    className="alert alert-center " 
                    role="alert"
                >
                    <p className='warning'>
                        {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { MembershipDescription }