import { IconButton, Paper, Typography } from "@mui/material";
import ReactCountryFlag from "react-country-flag"
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import "./SummaryItem.css"
import { pontCountries } from "../../../data/en/pontCountries";
import ListAltRoundedIcon from '@mui/icons-material/ListAltRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useNavigate } from "react-router";


export enum SummaryIcon {
  Back,
  List,
  Home,
  None
}

type Args = {
  name: string
  countryISOCode3?: string
  details?: string
  backgroundClass?: string
  icon?: SummaryIcon
  simpleText?: boolean | false
  linkOnText?: boolean | false
  textClass?: string,
  textAlignFlex?: string
  linkCallBack?: () => void
};

const SummaryItem = ({ name, countryISOCode3, details, simpleText, linkOnText, textClass, textAlignFlex, icon = SummaryIcon.Back, linkCallBack, backgroundClass }: Args) => {
  const nav = useNavigate();
  const handleArrowIconClick = () => {
    if (linkCallBack) {
      linkCallBack();
    }

    if (icon === SummaryIcon.Home) {
      nav('/')
    }

  };

  return (
    <>
      <Paper elevation={0} className={`fa-paper-individual ${backgroundClass}`}>
        <div className="row d-flex my-auto">
          <div className={`col-auto my-auto fa-arrow-left d-flex justify-content-start ${SummaryIcon.None ? ' fa-summary-no-icon' : ''}`}>

            {icon !== SummaryIcon.None &&

              <IconButton onClick={handleArrowIconClick}>
                {icon === SummaryIcon.Back && <ArrowBackIosRoundedIcon className={`${simpleText ? 'fa-arrow-small' : 'fa-arrow'}`} />}
                {icon === SummaryIcon.List && <ListAltRoundedIcon className="fa-arrow" />}
                {icon === SummaryIcon.Home && <HomeRoundedIcon className="fa-home" />}
              </IconButton>
            }
          </div>

          <div className={`col my-auto fa-name ${textClass ? textClass : ''}`} style={{ display: 'flex', justifyContent: textAlignFlex ? textAlignFlex : 'left' }}>
            {simpleText && !linkOnText && <Typography variant="body1">{name}</Typography>}

            {simpleText && linkOnText && <Typography variant="body1">

              <button className="linkcta" onClick={handleArrowIconClick}>
                {name}
              </button>
            </Typography>}

            {!simpleText && <Typography variant="h6">{name}</Typography>}

            {details &&
              <Typography variant="subtitle1" style={{ marginLeft: '10px' }}>({details})</Typography>
            }
          </div>
          {countryISOCode3 &&
            <div className="col-2 my-auto just-content-end fa-flag">

              <ReactCountryFlag countryCode={pontCountries.find(i => i.Iso3Code === countryISOCode3!)?.Iso2Code!}
                svg
                style={{
                  width: 'auto',
                  height: '2em',
                }}
              />

            </div>
          }
        </div>
      </Paper>
    </>
  );
}

export {
  SummaryItem
}