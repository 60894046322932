import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardContent, Typography } from '@mui/material';
import { LR } from './Help/LR';
import { Button } from 'react-bootstrap';

type Args = {
    showConfirmation : boolean,
    handleConfirm: () => void,
    handleCancel: () => void
  }

const ConfirmationChangesDialog = ({showConfirmation, handleConfirm, handleCancel} : Args) => {
  return (
    <>
      <Dialog open={showConfirmation} onClose={handleCancel}>
        <Card>
        <CardContent>
        <DialogTitle><LR localResource='COMMON_TITLE_UNSAVEDCHANGES'></LR></DialogTitle>
        <DialogContent>
          <DialogContentText>
          <Typography variant="body1" color="textSecondary">
            <LR localResource='COMMON_LABEL_UNSAVEDCHANGES'></LR>
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className='btn-green' onClick={handleCancel}><LR localResource='COMMON_BUTTON_CANCEL'></LR></Button>
          <Button className='btn-danger'  color="error" autoFocus onClick={handleConfirm}><LR localResource='COMMON_BUTTON_LEAVE'></LR></Button>
        </DialogActions>
        </CardContent>
        </Card>
      </Dialog>
    </>
  );
};

export
{ConfirmationChangesDialog}
