import axios, { AxiosError, AxiosResponse } from "axios";
import { useQuery, useQueryClient } from "react-query";
import { NotificationDto } from "../../types/Notification/NotificationDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { NotificationGetByEntityResponseDto } from "../../types/Notification/NotificationGetByEntityResponseDto";
import { NotificationGetByListOfEntitiesResponse } from "../../types/Notification/NotificationGetByListOfEntitiesResponse";

const useUpdateNotification = () => {
  const queryClient = useQueryClient();
  return (notification: NotificationDto) => {
    return axios
      .put(`${HookConfig.notificationUrl}/notifications/${notification.id}`, 
        {id: notification.id, isRead : notification.isRead } ,
      {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        console.log("Successfully updated notification:", notification);
        queryClient.invalidateQueries(["member-notifications", notification.entityKey]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error updating notification:", error);
        return { success: false, error }; 
      });
  };
};

const useMarkAllAsRead = () => {
  const queryClient = useQueryClient();
  return (notifications: NotificationGetByListOfEntitiesResponse[]) => {
    return axios
        .put(`${HookConfig.notificationUrl}/notifications/markAllAsRead`,
            {},
      {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        console.log("Successfully updated notifications:", notifications);
        queryClient.invalidateQueries(["member-notifications", notifications.flatMap(x => x.groupItems).map(notification => notification.id)]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error updating notifications:", error);
        return { success: false, error }; 
      });
  };
};


const useDeleteNotification = () => {
  const queryClient = useQueryClient();
  return (notification: NotificationDto) => {
    return axios
      .delete(`${HookConfig.notificationUrl}/notifications/${notification.id}`, {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        console.log("Successfully deleted notification:", notification);
        queryClient.invalidateQueries(["member-notifications", notification.entityKey]);
        queryClient.invalidateQueries(["member-notifications-count", notification.entityKey]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error deleting notification:", error);
        return { success: false, error }; 
      });
  };
};

const useFetchNotificationList = (memberId: string) => {
  return useQuery<NotificationGetByEntityResponseDto[], AxiosError>(["member-notifications", memberId], () =>
    axios.get(`${HookConfig.notificationUrl}/notifications/member/${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchGroupedNotificationList = (memberId: string) => {
  return useQuery<NotificationGetByListOfEntitiesResponse[], AxiosError>(["grouped-member-notifications", memberId], () =>
    axios.get(`${HookConfig.notificationUrl}/notifications/member/${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const doFetchNotificationCount = (memberId: string) => {
    return axios.get(`${HookConfig.notificationUrl}/notifications/member/${memberId}/count`,
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        }
      ).then((resp) => resp.data);
}

export {
  useFetchNotificationList,
  useFetchGroupedNotificationList,
  useDeleteNotification,
  useUpdateNotification,
  useMarkAllAsRead,
  doFetchNotificationCount
}
