import { useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { ContactManage } from "./ContactManage";
import { AccountSettings } from "./AccountSettings";


type Args =
    {
        userLogged: ApplicationUser
        isCurrentUserEdit?: boolean
    }

const ContactEdit = ({ userLogged, isCurrentUserEdit }: Args) => {
    const queryStringParams = useParams();
    const id = queryStringParams.id;
    if (!id) throw Error("Contact id needed");

    const member = {
        countryIso3Code: '',
        enrolmentCode: '',
        fullName: '',
        languageCode: i18n.language,
        memberTypeName: "OperatorContact",
        person: {
            email: '',
            firstName: '',
            jobTitle: '',
            lastName: '',
        }
    };


    const [memberRequest, setMemberRequest] = useState<MemberRequestDto>(member);

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(id)


    useEffect(() => {
        if (operator) {
            setMemberRequest({
                ...memberRequest,
                fullName: operator.fullName,
                person: operator.person,
                countryIso3Code: operator.countryIso3Code,
                languageCode: operator.languageCode,
                externalReference: operator.externalReference,
                registeredUserId: operator.registeredUserId
            })
        }
    }, [operator]);



    if ((!operatorIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;
    return (
        <>
            {
                memberRequest.fullName && !isCurrentUserEdit &&
                <ContactManage isEdit={true} userLogged={userLogged} member={memberRequest}></ContactManage>
            }

            {
                memberRequest.fullName && isCurrentUserEdit &&
                <AccountSettings isEdit={true} userLogged={userLogged} member={memberRequest}></AccountSettings>
            }

        </>
    );
};


export { ContactEdit }