import { ConfigurationDto } from "../types/Common/ConfigurationDto";

const locationConfigurations: ConfigurationDto[] = [
  {
    name: "INVENTORY_LABEL_VARIANTS",
    url: "variants",
    groupName: "MENU_LABEL_INVENTORY"
  },
  {
    name: "INVENTORY_LABEL_BASEPRICES",
    url: "base-prices",
    groupName: "MENU_LABEL_INVENTORY"
  },
]

export {
  locationConfigurations
}