import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { getLabelsByOperatorType, useFetchMemberById, useFetchMemberSummaryById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import i18n from "../../i18n";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { useEffect, useState } from "react";
import { SectionHead } from "../Common/Help/SectionHead";


type Args =
    {
        userLogged: ApplicationUser
    }

const HostSummary = ({ userLogged }: Args) => {

    const nav = useNavigate();
    let summaryName = null;
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Operator id needed");

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const titleLabel = getSummaryLabel(summaryName!);

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "MemberSummary":
                nav(`/operators/${queryStringParams.id}/companydetails`);
                break;
            case "ContactManagementSummary":
                //nav(`/operators/${queryStringParams.id}/summary/contact-management`);
                //TODO > right now we are going directly to Contacts page. We'll need to change this
                nav(`/operators/${queryStringParams.id}/contacts`);
                break;
            case "ContactSummary":
                nav(`/operators/${queryStringParams.id}/contacts`);
                break;
            case "KycSummary":
                //TODO => we will need to implement the multiple country behaviour
                //nav(`/operators/${queryStringParams.id}/summary/kyc-details`);
                nav(`/operators/${queryStringParams.id}/kyc-details/${country}`);
                break;
            case "ImagesSummary":
                nav(`/operators/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            case "FiscalSummary":
                nav(`/operators/${queryStringParams.id}/fiscal-details/${country}`);
                break;
            case "NotificationSummary":
                nav(`/operators/${queryStringParams.id}/contact-management/notifications`);
                break;
            case "OperatorLocationSummary":
                nav(`/operators/${queryStringParams.id}/locations`);
                break;
            case "CommunicationSummary":
                nav(`/operators/${queryStringParams.id}/summary/communications`);
                break;
            case "PhoneSummary":
                nav(`/operators/${queryStringParams.id}/communications/phone`);
                break;
            case "EmailSummary":
                nav(`/operators/${queryStringParams.id}/communications/email`);
                break;
            case "SocialSummary":
                nav(`/operators/${queryStringParams.id}/communications/social`);
                break;
            case "WebSummary":
                nav(`/operators/${queryStringParams.id}/communications/web`);
                break;
            case "LegalEntitySummary":
                nav(`/operators/${queryStringParams.id}/legal-entities`);
                break;
            case "DomainSummary":
                nav(`/operators/${queryStringParams.id}/domains`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    const linkCallBack = () => {
        nav(`/operators/${queryStringParams.id}/summary`);
    }




    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchMemberSummaryById(queryStringParams.id!, summaryName!);
    const [country, setCountry] = useState<string>('');

    useEffect(() => {
        if (operator)
            setCountry(operator.countryIso3Code);

    }, [operator]);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    const homeCallBack = () => {
        nav(`/`);
    }

    if (!data || !operator) return <ApiStatus status={status} />;

    const labels = getLabelsByOperatorType(operator);
    return (
        <>

            {!queryStringParams.summaryName &&
                <SectionHead linkCallBack={homeCallBack} 
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t(labels!.title)}
                description={i18n.t(labels!.label)} />
            }

            {queryStringParams.summaryName &&
                <>
                <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("MEMBER_SUMMARY_OPERATORMAINPAGE")!}
                    name={`${operator.fullName} (${operator.externalReference})`}
                    title={i18n.t(titleLabel!)}
                    description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />
                </>
            }


            <div className={`row d-flex justify-content-center `}>
                <div className="col-md-5 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { HostSummary }