import { useState } from "react";
import "./SummaryItems/SummaryItem.css"
import { LR } from "./Help/LR";
import i18n from "../../i18n";
import React from "react";
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import MessageAlert, { AlertStyle } from "./Errors/MessageAlert";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { LabelAndHelp } from "./Help/LabelAndHelp";
import { Backdrop, CircularProgress } from "@mui/material";

type Args = {
  items: CommunicationTypeDto[]
  label:string
  linkCallBack: (item: CommunicationTypeDto[]) => void,
  handleDirty: () => void

};

const CommunicationTypeCollection = ({ items, label, linkCallBack, handleDirty }: Args) => {

  const isFormValid = items.every((item) => !item.isMandatory || item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<CommunicationTypeDto[]>(items);
  const [iFormValidState, setisFormValidState] = useState<boolean>(isFormValid);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const setItemValue = (item: CommunicationTypeDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setisFormValidState(updatedItemsState.every((item) => ((!item.isMandatory || item.value.trim() !== '') && !item.errorMessage)));
    setItemsState(updatedItemsState);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      setSubmitting(true);
      e.preventDefault();
      linkCallBack(itemsState);
      setSubmitting(false);

    } catch (err: any) {
      console.error(err);
    }
  }

  //#region Validation
  const validateForm = (item: CommunicationTypeDto): CommunicationTypeDto => {
    switch (item.communicationGroupName.toLowerCase()) {
      case "phone":

        if (isValidPhoneNumber(item.value)) {
          const parsedPhoneNumber = parsePhoneNumber(item.value);
          item.value = parsedPhoneNumber.formatInternational()
          item.errorMessage = '';
        } else {
          if (!item.isMandatory && item.value.trim() === '') {
            item.errorMessage = '';
          } else {
            item.errorMessage = 'COMMON_ERROR_PHONEFORMATTING'
          }
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(item.value.trim())) {
          if (!item.isMandatory && item.value.trim() === '') {
            item.errorMessage = '';
          } else {
            item.errorMessage = 'COMMON_ERROR_EMAILFORMATTING'
          }
        } else {
          item.errorMessage = '';
        }
        break;
      case "web":

      const webRegex = /^(https?:\/\/)?([w]{3}\.)?([a-zA-Z0-9-]{3,}(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})$/;
      if (!webRegex.test(item.value)) {
        item.errorMessage = 'COMMON_ERROR_WEBFORMATTING';
      } else {
        // Remove "http://" or "https://"
        let cleanedValue = item.value.replace(/^(https?:\/\/)/, '');
      
        // Add "www" prefix if it doesn't exist
        if (!cleanedValue.startsWith('www.')) {
          cleanedValue = 'www.' + cleanedValue;
        }
      
        item.value = cleanedValue;
        item.errorMessage = '';
      }

        break;
    }
    return item;
  };
  //#endregion

  return (
    <form onSubmit={onFormSubmit}>

<Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={submitting}>
                <CircularProgress color="inherit" />
            </Backdrop>

    
      {itemsState.map((item) => (
        <React.Fragment key={item.communicationTypeLabel}>
          <LabelAndHelp mandatory={item.isMandatory}  message={item.communicationTypeLabel} ></LabelAndHelp>
          <div className="row d-flex justify-content-center mt-1 mb-1">
            <div className="col-md-5 col-11 justify-content-center">
              <input type="text"
                value={item.value}
                id={item.communicationTypeName}
                className="form-control fa-form"
                onChange={(e) => { setItemValue(item, e.target.value); handleDirty() }}
                placeholder={i18n.t(`COMMON_PLACEHOLDER_${item.communicationGroupName.toUpperCase()}`).toString()}
                required={item.isMandatory}>
              </input>
            </div>
          </div>
          {(item.errorMessage) &&
            <div className="row d-flex justify-content-center">
              <div className="col-md-5 col-11 small justify-content-center">
                <MessageAlert message={item.errorMessage} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }
        </React.Fragment>
      ))}

      <div className="row d-flex justify-content-center mt-3">
        <div className="col-md-2 col-6 justify-content-center">
          <button type="submit" disabled={!iFormValidState || submitting} className="btn btn-green btn-primary btn-block">
            <LR localResource="COMMON_LABEL_SAVE"></LR>
          </button>
        </div>
      </div>
    </form>
  );
}

export {
  CommunicationTypeCollection
}