import React, { useState } from 'react';

import { LegalEntity } from './LegalEntityCard'
import { LocationsCard } from './LocationsCard';
import { ProductsCard } from './ProductsCard';
import { EventsCard } from './EventsCard';
import { MembershipsCard } from './MembershipsCard';
import { HelpDialog } from '../Common/Help/HelpDialog';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';

type Args = {
  userLogged: ApplicationUser
  
}


const Dashboard = ({userLogged} : Args) => {

  const [helpOpen, setHelpOpen] = useState(false);
  const [helpReference, setHelpReference] = useState('');

  const handleOpen = () => {
    setHelpOpen(true);
  };

  const handleClose = () => {
    setHelpOpen(false);
  };

  const setHelp = (value: string) => {
    setHelpReference(value);
    handleOpen();
  }


  return (
    <>
      <HelpDialog contentReference={helpReference} showHelp={helpOpen} handleClose={handleClose} handleConfirm={handleOpen} ></HelpDialog>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-11 col-11 justify-content-center">
          <div className="mt-5 row justify-content-center">
            
            <div className="mt-2 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <LocationsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="mt-2 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <LegalEntity userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="mt-2 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <ProductsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="mt-2 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <EventsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
            <div className="mt-2 col-lg-3 col-md-6 col-sm-6 d-flex justify-content-center mb-3">
              <MembershipsCard userLogged={userLogged} setHelp={setHelp} />
            </div>
          </div>
        </div>
      </div>

    </>

  );

}

export { Dashboard };
