import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchLocationById } from "../../hooks/Location/location";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";




type Args =
    {
        userLogged: ApplicationUser
    }

const LocationDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/summary/`);
    }

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('location', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location || !data) return <ApiStatus status={status} />;

    return (
        <>


            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { LocationDigitalAssets }