import { useState } from "react";
import i18n from "../../i18n";
import { LR } from "../Common/Help/LR";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { InventoryUnavailabilities } from "./InventoryUnavailabilities";
import { InventoryAllocation } from "./InventoryAllocation";
import {  Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { useDeleteInventory } from "../../hooks/Inventory/inventory";
import { useNavigate } from "react-router-dom";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';

type Args = {
    isEdit: boolean,
    inventory: InventoryDto,
    submitted: (inventory: InventoryDto) => void;
};

const InventoryForm = ({ inventory, isEdit, submitted }: Args) => {
    const nav = useNavigate();
    const [inventoryState, setInventoryState] = useState({ ...inventory });
    const [submitting, setSubmitting] = useState(false);
    const [dialogConfirmation, setDialogConfirmation] = useState<boolean>(false);
    const [dialogName, setDialogName] = useState<string>('');
    const [dialogId, setDialogId] = useState<string>('');
    const [dialogAction, setDialogAction] = useState<string>('');
    const [error, setError] = useState<string>('');
    const deleteInventory = useDeleteInventory();

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            setSubmitting(true);

            submitted(inventoryState);

        } catch (err: any) {
            setSubmitting(false)
            console.error(err);
        }
    }

    const handleDelete = (name: string, id: string) => {
        setError('');
        setDialogAction("DELETE")
        setDialogName(name);
        setDialogId(id);
        setDialogConfirmation(true);
    }

    const handleDisable = (name: string, id: string) => {
        setError('');
        setDialogAction(inventoryState.isDisabled ? "ENABLE" : "DISABLE")
        setDialogName(name);
        setDialogId(id);
        setDialogConfirmation(true);
    }


    const confirmDialog = async (id: string) => {
        switch (dialogAction.toUpperCase()) {
            case "DELETE":
                const resultDelete = await deleteInventory(id);
                if (resultDelete.success) {
                    nav(`/locations/${inventory.locationId}/inventories`)
                } else {
                    setError("INVENTORY_ERROR_ALLOCATIONDELETION");
                }
                break;
            case "DISABLE":
            case "ENABLE":
                var status = inventoryState.isDisabled;
                inventoryState.isDisabled = !status;
                submitted(inventoryState);
                setInventoryState({ ...inventoryState, isDisabled: !status })
                break;
        }


        setDialogConfirmation(false);
    }

    const generateUniqueCode = (value: string) => {

        if (inventoryState.externalReference === '') {
            const formattedCode = GenerateExternalReference(value);
            setInventoryState({ ...inventoryState, externalReference: formattedCode });
        }

    };

    return (
        <>
            <form onSubmit={onFormSubmit}>

               


                <LabelAndHelp mandatory={true} fullscreen={true} message='INVENTORY_LABEL_NAME' ></LabelAndHelp>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <input
                            type="text"
                            className="form-control fa-form"
                            placeholder={i18n.t("INVENTORY_LABEL_NAME").toString()}
                            value={inventoryState.name}
                            onChange={e => { setInventoryState({ ...inventoryState, name: e.target.value }); }} onBlur={e => generateUniqueCode(e.target.value)} required></input>
                    </div>
                </div>
                <LabelAndHelp mandatory={true} fullscreen={true} message='INVENTORY_LABEL_AREASQM' ></LabelAndHelp>
                <div className="row d-flex justify-content-center">
                    <div className="justify-content-center">
                        <input
                            type="number"
                            min={0}
                            max={100000}
                            className="form-control fa-form"
                            placeholder={i18n.t("INVENTORY_LABEL_AREASQM").toString()}
                            value={inventoryState.areaInSqm}
                            onChange={e => { setInventoryState({ ...inventoryState, areaInSqm: parseInt(e.target.value) }); }} required></input>
                    </div>
                </div>
                {
                    isEdit &&
                    <>
                        <div className="row d-flex justify-content-center mt-2">
                            <div className="justify-content-center">
                                <InventoryAllocation items={inventoryState.allocations!}></InventoryAllocation>
                            </div>
                        </div>
                        <div className="row d-flex justify-content-center mt-3">
                            <div className="justify-content-center">
                                <InventoryUnavailabilities items={inventoryState.unavailability!}></InventoryUnavailabilities>
                            </div>
                        </div>
                    </>
                }


                {(inventoryState.isDisabled) &&
                    <div className="row d-flex justify-content-center mt-3">
                        <div className=" small justify-content-center">
                            <MessageAlert message={'INVENTORY_LABEL_INVENTORYDISABLED'} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        </div>
                    </div>
                }

                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-6 justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || inventoryState.name.length < 2} type="submit">
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>
                    </div>
                </div>

                {isEdit &&
                    <div className="row  justify-content-center mt-2">
                        <div className="col-3 justify-content-center" style={{ textAlign: 'center' }}>

                            <Tooltip title={i18n.t(inventoryState.isDisabled ? `COMMON_BUTTON_ENABLE` : `COMMON_BUTTON_DISABLE`, { param0: inventoryState.name })}>
                                <IconButton onClick={(e) => handleDisable(inventoryState.name, inventoryState.id!.toString())}>
                                    {!inventoryState.isDisabled && <VisibilityOffSharpIcon color={'warning'} />}
                                    {inventoryState.isDisabled && <VisibilityTwoToneIcon color={'success'} />}
                                </IconButton>
                            </Tooltip>
                            <Typography variant="caption" color="textSecondary">
                                <LR localResource={inventoryState.isDisabled ? `COMMON_BUTTON_ENABLE` : `COMMON_BUTTON_DISABLE`}></LR>
                            </Typography>

                        </div>
                        <div className="col-3 justify-content-center" style={{ textAlign: 'center' }}>

                            <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: inventoryState.name })}>
                                <IconButton onClick={(e) => handleDelete(inventoryState.name, inventoryState.id!.toString())} >
                                    <DeleteRoundedIcon color={'error'} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="caption" color="textSecondary">
                                <LR localResource="COMMON_BUTTON_DELETE"></LR>
                            </Typography>

                        </div>
                    </div>
                }
                {
                    error && <div className="row d-flex justify-content-center">
                        <div className="justify-content-center">
                            <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        </div>
                    </div>
                }


                <Dialog open={dialogConfirmation} onClose={() => setDialogConfirmation(false)}>
                    <Card>
                        <CardContent>
                            <DialogTitle><LR localResource={`INVENTORY_TITLE_${dialogAction}`} /></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant="body1" color="textSecondary">
                                        <LR localResource={`INVENTORY_LABEL_${dialogAction}`} params={{ name: dialogName }} />
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className='btn btn-secondary' onClick={() => setDialogConfirmation(false)}>
                                    <LR localResource='COMMON_BUTTON_CANCEL' />
                                </button>
                                <button className={inventoryState.isDisabled ? 'btn-primary btn-green btn' : 'btn-danger btn'} type="button" onClick={() => { confirmDialog(dialogId); }} color="error" autoFocus>
                                    <LR localResource={`COMMON_BUTTON_${dialogAction}`} />
                                </button>
                            </DialogActions>
                        </CardContent>
                    </Card>
                </Dialog>

            </form>
        </>

    );
}


export { InventoryForm }