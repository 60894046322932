import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import "../../Common/react-draft-wysiwyg.css";
import 'react-quill/dist/quill.snow.css';
import './PostContent.css';
import { ApplicationUser } from '../../../types/Authentication/ApplicationUser';
import { useFetchPostById, useUpdatePostContent } from '../../../hooks/Posts/posts';
import { PostResponseDto } from '../../../types/Post/PostResponseDto';
import ApiStatus from '../../Common/Errors/ApiStatus';
import { SectionHead } from '../../Common/Help/SectionHead';
import MessageAlert from '../../Common/Errors/MessageAlert';
import i18n from '../../../i18n';
import { LR } from '../../Common/Help/LR';
import CustomQuillEditor from '../../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../../Common/SaveSnackBar';
import '../../../main/App.css'

type Args =
    {
        userLogged: ApplicationUser
    }

const PostContent = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.postId) throw Error("Post id needed");
    const applicationId = params.id;

    
    const defaultPost: PostResponseDto = {
        id: '',
        title: '',
        headline: '',
        priority: 0,
        fromDate: new Date(),
        toDate: new Date(),
        callToAction: '',
        sectionName: 'Announcement',
        author: '',
        applicationId: parseInt(applicationId!),
        features: [],
        content: ''
    };

    const [contentState, setContentState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { data: post, status, isSuccess } = useFetchPostById(params.postId);
    const [usePost, setUsePost] = useState(defaultPost);

    useEffect(() => {
        if (isSuccess && post) {
            setUsePost(post);
            setContentState(post.content!);
            setUnsavedChanges(false); 
        }
    }, [isSuccess, post]);

    const handleDescriptionChange = (value: string) => {
        setContentState(value);
        setUnsavedChanges(true);
        setUsePost({ ...usePost, content: value });
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            updateMutation.mutate(usePost);
            e.preventDefault();
            setSnackbarOpen(true); // Show snackbar
            setUnsavedChanges(false);  
        } catch (err: any) {
            console.error(err);
        }
    }

    const linkCallBack = () => {
        nav(`/application/${applicationId}/configuration/posts/${post!.id}`);
    }

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const updateMutation = useUpdatePostContent(linkCallBack);


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!post) return <MessageAlert message="POST_LABEL_POSTNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("POSTS_LABEL_TITLE")!}
                name={`${post.title}`}
                title={i18n.t('COMMON_SUMMARY_CONTENT')}
                description={i18n.t('COMMON_SUMMARY_POSTS')} />

            <CustomQuillEditor
                value={contentState}
                onChange={handleDescriptionChange}
                placeholder={i18n.t("POSTS_LABEL_CONTENT", { postTitle: post.title })}
            />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {unsavedChanges && (
                <div 
                    className="alert alert-center " 
                    role="alert"
                >
                    <p className='warning-label'>
                         {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { PostContent }