import { useState } from "react";
import i18n from "../../../i18n";
import { LR } from "../../Common/Help/LR";
import ApiStatus from "../../Common/Errors/ApiStatus";
import React from "react";
import { SaveSnackBar } from "../../Common/SaveSnackBar";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";

import { useFetchDataInterchanges } from "../../../hooks/Data-Interchange/fileUpload";
import { Collapse, IconButton, Paper, Typography } from "@mui/material";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import FileUploader from "../../Common/Upload/FileUploader";
import { DictionaryStringNumber } from "../../../types/Common/Dictionaries";
import { GetFileNameFromResponseHeaders } from "../../../functions/utils/helper";
import { operatorDefaultHeaders } from "../../../functions/api/api";
import axios from "axios";
import { HookConfig } from "../../../config/HookConfig";
import { IconDisplayer } from "../../Common/Help/IconDisplayer";

type Args = {
    userLogged: ApplicationUser
    type: string

};

const BulkUpload = ({ type, userLogged }: Args) => {

    const [expandedItems, setExpandedItems] = useState<number[]>([]);
    const { data, status, isSuccess } = useFetchDataInterchanges(type, userLogged.operator?.id!);
    const [myDictionary, setMyDictionary] = useState<DictionaryStringNumber>({});
    const [exportError, setExportError] = useState<string>('');

    if ((!isSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    const handleCollapseClick = (id: number) => {
        setExpandedItems((prevExpandedItems) => {
            if (prevExpandedItems.includes(id)) {
                return prevExpandedItems.filter((id) => id !== id);
            } else {
                return [...prevExpandedItems, id];
            }
        });
    };

    const handleFilesCount = (key: string, value: number) => {

        setMyDictionary((prevDictionary) => {
            // Check if the key already exists in the dictionary
            if (prevDictionary.hasOwnProperty(key)) {
                // Update the existing record
                return {
                    ...prevDictionary,
                    [key]: value,
                };
            } else {
                // Insert a new record
                return {
                    ...prevDictionary,
                    [key]: value,
                };
            }
        });
    };


    const handleFileExport = async (label: string) => {
        try {
            setExportError('');
            let url = "";
            switch (label) {
                case "LABEL_EDI_LOCATIONEXPORT":
                    url = (`/location-management/export/locations?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_IMAGEEXPORT":
                    url = (`/location-management/export/location-images?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_NEARBYEXPORT":
                    url = (`/location-management/export/location-nearby-facilities?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_FEATUREEXPORT":
                    url = (`/location-management/export/location-features?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_INVENTORYEXPORT":
                    url = (`/inventory-management/export/inventories?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_LOCATIONINVENTORYSUMMARYEXPORT":
                    url = (`/inventory-management/export/location-summaries?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_OPERATOREXPORT":
                    url = (`/member-management/export/operators?operatormemberid=${userLogged.operator?.id!}`);
                    break;
                case "LABEL_EDI_EVENTATTENDEEEXPORT":
                    url = (`/order-management/export/event-attendees`);
                    break;
                default:
                    url = "";
                    break;
            }

            const response = await axios.get(url, {
                responseType: 'blob',
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
            );

            const file = new Blob([response.data], { type: response.headers['content-type'], });
            const fileUrl = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.download = GetFileNameFromResponseHeaders(response.headers);
            link.rel = 'noopener noreferrer';
            link.click();

        } catch (error) {
            setExportError("Error downloading the file");
        }
    };


    return (
        <>
            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    {type === 'location' && <>
                        <LR localResource="LOCATION_MESSAGE_LOCATIONINSTRUCTIONS"></LR><a className="greenLink" href="/files/import/Data-Location.csv">here</a>
                    </>
                    }
                    {type === 'inventory' && <>
                        <LR localResource="LOCATION_MESSAGE_INVENTORYINSTRUCTIONS"></LR><a className="greenLink" href="/files/import/Data-Inventory.csv">here</a>
                    </>
                    }
                    {type === 'operator' && <>
                        <LR localResource="LOCATION_MESSAGE_OPERATORINSTRUCTIONS"></LR><a className="greenLink" href="/files/import/Data-Operator.csv">here</a>
                    </>
                    }
                    {type === 'order' && <>
                        <LR localResource="LOCATION_MESSAGE_ORDERINSTRUCTIONS"></LR><a className="greenLink" href="/files/import/Data-Order.csv">here</a>
                    </>
                    }
                </div></div>
            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    {data.map((item, index) => (

                        <Paper key={index} elevation={3} className="fa-paper">
                            <div className="d-flex align-items-center">
                                <div className="fa-name flex-grow-1">
                                    <IconDisplayer type={item.label}></IconDisplayer>
                                    <Typography key={index} variant="h6" className="linkbutton">
                                        <LR localResource={item.label} />
                                    </Typography>
                                </div>
                                <div className="fa-icons">
                                    <IconButton className="fa-arrow" onClick={() => handleCollapseClick(item.id)}>
                                        {!expandedItems.includes(item.id) && <ArrowForwardIosRoundedIcon />}
                                        {expandedItems.includes(item.id) && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
                                    </IconButton>
                                </div>
                            </div>

                            <Collapse in={expandedItems.includes(item.id)}>
                                {item.ediTypeName === 'Import' &&
                                    <FileUploader defaultOpen={true} minimalUpload={true} item={item.fileRequirement!} setFilesCount={(handleFilesCount)} groupName={item.ediTypeName!} version="1"></FileUploader>
                                }

                                {item.ediTypeName === 'Export' &&
                                    <>
                                        <div className="row d-flex justify-content-center mb-3">
                                            <div className="col-md-5 col-5 justify-content-center">
                                                <button className="btn btn-green btn-primary btn-block" onClick={() => handleFileExport(item.label!)}>
                                                    <LR localResource="LABEL_EDI_DOWNLOAD"></LR>
                                                </button>
                                            </div>
                                        </div>
                                    </>
                                }


                            </Collapse>

                        </Paper>



                    ))}
                </div>
            </div>
        </>
    );
}


export { BulkUpload }