import { useNavigate } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import i18n from "../../../i18n";
import { productConfigurations } from "../../../data/productConfigurations";
import { ConfigurationCollection } from "../../Common/SummaryItems/ConfigurationCollection";
import { useFetchMemberById } from "../../../hooks/Member/member";
import { SectionHead } from "../../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const ProductConfiguration = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)
   

    if ((!operatorIsSuccess )) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    const homeCallBack = () => {
        nav(`/`);
    }


    if ( !operator) return <ApiStatus status={operatorStatus} />;

    
    return (
        <>
          <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!} name='' title={i18n.t('PRODUCTS_SUMMARY_CONFIGURATION')!} description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />
        
            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                <ConfigurationCollection showGroup={false} items={productConfigurations}></ConfigurationCollection>
                </div>
            </div>

            
        </>
    );
};


export { ProductConfiguration }