import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { getLabelsByOperatorType, useFetchMemberById, } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useState } from "react";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { useFetchMemberFiscalDetails } from "../../hooks/Member/fiscaldetails";
import { FiscalDetailsCollection } from "./FiscalDetailsCollection";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const HostFiscalDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    if (!queryStringParams.id) throw Error("Operator id needed");
    if (!queryStringParams.country) throw Error("Country id needed");

    //#region navigation
    const linkCallBack = () => {
        let url = `/operators/${queryStringParams.id}/summary`;
        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        } else {
            nav(url);
        }

    }


    //#endregion

    //#region dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchMemberFiscalDetails(queryStringParams.id!, queryStringParams.country!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;


    const labels = getLabelsByOperatorType(operator);

    return (
        <>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>


            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(labels!.cta)!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_KYC-DETAILS')}
                description={i18n.t(labels!.label)} />


            <FiscalDetailsCollection handleDirty={handleDirty} items={data} entityName="Member" entityKey={queryStringParams.id!}></FiscalDetailsCollection>

        </>
    );
};


export { HostFiscalDetails }

