import { useParams } from "react-router";
import { useAddLocation } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LocationRequestDto } from "../../types/Location/LocationRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LocationForm } from "./LocationForm";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { useNavigate } from "react-router-dom";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationAdd = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const queryStringParams = useParams();
    const addLocationMutation = useAddLocation();


    const location: LocationRequestDto = {
        countryIso3Code: '',
        name: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId: (queryStringParams.operatorId) ? queryStringParams.operatorId : userLogged.operator?.id!,
        languageCode: i18n.language
    };

    const homeCallBack = () => {
        nav(`/`);
    }


    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                name={`${userLogged.operator?.fullName} (${userLogged.operator?.externalReference})`}
                title={i18n.t('LOCATION_TITLE_NEWLOCATION'!)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LR localResource="LOCATION_MESSAGE_NEWLOCATION"></LR>
                </div>
            </div>


            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LocationForm
                        location={location} isEdit={false}
                        submitted={(location) => addLocationMutation.mutate(location)}
                    />
                </div>
            </div>

            {(addLocationMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={addLocationMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { LocationAdd }