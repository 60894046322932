const getSummaryLabel = (input: string) => {

  switch (input) {
    case "phone":
      return "COMMON_SUMMARY_PHONE";
    case "email":
      return "COMMON_SUMMARY_EMAIL";
    case "social":
      return "COMMON_SUMMARY_SOCIALNETWORKS";
    case "web":
      return "COMMON_SUMMARY_WEB";
    case "domains":
      return "COMMON_SUMMARY_DOMAINS";
    case "digital-assets":
      return "COMMON_SUMMARY_DIGITALASSETS"
    case "images":
    case "eventimages":
      return "COMMON_SUMMARY_IMAGES"
    case "videosvirtualtours":
      return "COMMON_SUMMARY_VIDEOSVIRTUALTOURS"
    case "floorplansdocuments":
    case "eventdocs":
      return "COMMON_SUMMARY_FLOORPLANSDOCUMENTS"
    case "contact-management":
      return "COMMON_SUMMARY_CONTACTMANAGEMENT"
    case "fiscal-details":
      return "COMMON_SUMMARY_FISCALINFORMATION"
    case "kyc-details":
      return "COMMON_SUMMARY_KYCDOCUMENTATION"
    case "communications":
      return "COMMON_SUMMARY_COMMUNICATIONS"
    case "documents":
      return "COMMON_SUMMARY_DOCUMENTS"
    default:
      return input;
  }
}

export {
  getSummaryLabel
}