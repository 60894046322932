import { useNavigate, useParams } from "react-router";
import {  useFetchLocationById } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { InventoryForm } from "./InventoryForm";
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { useManageInventory } from "../../hooks/Inventory/inventory";
import { useState } from "react";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const InventoryAdd = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const nav = useNavigate();
    if (!queryStringParams.id) throw Error("Location id needed");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)

    const success = (inventoryId? : string) => {
        nav(`/locations/${queryStringParams.id!}/inventories/${inventoryId}`);
        setSnackbarOpen(true);
    }


    const manageInventoryMutation = useManageInventory(success, false);
    const inventory: InventoryDto = {
        name: '',
        externalReference: '',
        inventoryGroupName: 'Space',
        areaInSqm: 0,
        isDisabled: false,
        locationId: queryStringParams.id!
    };

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/inventories/`);
    }

    if ((!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location) return <ApiStatus status={locationStatus} />;

    return (
        <>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>

            
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_LABEL_INVENTORIES")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('INVENTORY_LABEL_ADDINVENTORY')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />


           

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <InventoryForm inventory={inventory} isEdit={false} submitted={(inventory) => manageInventoryMutation.mutate(inventory)}></InventoryForm>
                </div>
            </div>

            {(manageInventoryMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={manageInventoryMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { InventoryAdd }