import {v4 as uuidv4} from 'uuid';

const operatorDefaultHeaders =  function () {

   return { 
    "Pont-Source" : "OperatorPortal",
    "Pont-Partner": "PONT",
    "Pont-Api-Key" : "c498656d-a376-49d8-a610-c00777ff25d6",
    "X-CSRF": "1",
    "Content-Type": "application/json",
    "CorrelationId": uuidv4()
 };
}

 const operatorFileUploadHeaders = function () {
     return {
         "Pont-Source": "OperatorPortal",
         "Pont-Partner": "PONT",
         "X-CSRF": "1",
         "Content-Type": "multipart/form-data",
         "CorrelationId": uuidv4()
     };
 }



export{
    operatorDefaultHeaders,
    operatorFileUploadHeaders

}
