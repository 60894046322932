import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { useFetchMemberById, } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import { CommunicationTypeCollection } from "../Common/CommunicationTypeCollection";
import { CommunicationTypeDto } from "../../types/Common/CommunicationTypeDto";
import { useManageCommunication, useFetchCommsTypes } from "../../hooks/Common/communications";
import i18n from "../../i18n";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useState } from "react";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const HostCommunicationTypes = ({ userLogged }: Args) => {

    const nav = useNavigate();
    let commType = null;
    const queryStringParams = useParams();
    const { mutate } = useManageCommunication();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');

    if (!queryStringParams.id) throw Error("Operator id needed");
    if (!queryStringParams.communicationType) throw Error("Communication Type is needed");

    if (queryStringParams.communicationType)
        commType = queryStringParams.communicationType;


    let commTypeLabel= `COMMON_LABEL_${commType?.toUpperCase()}`//getSummaryLabel(commType!);
    
  
   

    //#region navigation
    const linkCallBackCollection = (items: CommunicationTypeDto[]) => {
        mutate([queryStringParams.id!, queryStringParams.communicationType!, items, "Member"]);
    }

    const linkCallBack = () => {
        let url = `/operators/${queryStringParams.id}/summary/communications`;

        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        }
        else {
            nav(url);
        }
    }
  
    //#endregion

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion


    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchCommsTypes(queryStringParams.id!, commType!, "Member");

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    return (
        <>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('COMMON_SUMMARY_COMMUNICATIONS')!}
                name={`${operator.fullName} (${operator.externalReference})`}
                title={i18n.t(commTypeLabel!)}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />
           


            <CommunicationTypeCollection label={commTypeLabel!} handleDirty={handleDirty} items={data} linkCallBack={linkCallBackCollection} ></CommunicationTypeCollection>

        </>
    );
};


export { HostCommunicationTypes }

