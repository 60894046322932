import { IconButton, Input, InputAdornment } from "@mui/material";
import i18n from "../../i18n";
import { useEffect, useState } from "react";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import { useFetchLocations } from "../../hooks/Location/location";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { DialogLocationSelector } from "./DialogLocationSelector";

type Args =
  {
    operatorId: string,
    locationId: string,
    placeholder?: string,
    handleConfirm: (operator: LocationResponseDto) => void
  }

const LocationSelector = ({ operatorId, locationId, placeholder, handleConfirm }: Args) => {
  const { data: locations, status, isSuccess } = useFetchLocations(operatorId);
  const [selectedRow, setSelectedRow] = useState<LocationResponseDto | null>(null);
  const [open, setOpen] = useState(false);
  const [locationName, setLocationName] = useState('');


  useEffect(() => {
    if (operatorId && locations) {
      if (locationId !== '00000000-0000-0000-0000-000000000000' && locationId !== null) {
        const selectedLocation = locations.find(x => x.id === locationId);
        if (selectedLocation) {
          setSelectedRow(selectedLocation);
          handleValueSelection(selectedLocation);
        }
      }
    }
  }, [operatorId, locations, locationId]);


  useEffect(() => {
    if (locations) {
      if (locations.length === 1) //only 1 location. we'll select it automatically
      {
        setSelectedRow(locations[0]);
        handleValueSelection(locations[0]);
      }
    }

  }, [locations]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueSelection = (location: LocationResponseDto) => {
    setLocationName(location.name)
    handleConfirm(location);
    handleClose();
  };

  const getPlaceholder = () => {
    return i18n.t(placeholder ?? "LOCATION_LABEL_LEGALENTITY").toString();
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!locations) return <MessageAlert message="MEMBER_LABEL_MEMBERSNOTFOUND"></MessageAlert>;

  return (
    <>
      <DialogLocationSelector locations={locations} open={open} handleCancel={handleClose} handleConfirm={handleValueSelection}></DialogLocationSelector>
      <div>
        <Input
          style={{ opacity: 1 }}
          type="text"
          className="form-control fa-form"
          placeholder={getPlaceholder()}
          value={locationName}
          disabled
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleOpen}>
                <SearchRoundedIcon style={{ color: "white" }} />
              </IconButton>
            </InputAdornment>
          }
        />
      </div>
    </>
  );
};

export { LocationSelector }