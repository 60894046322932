import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { useNavigate } from "react-router-dom";
import { ConnectedAccountDto } from "../../types/Order/ConnectedAccount";
import { useAddConnectedAccount } from "../../hooks/Order/connectedAccount";
import { ConnectedAccountForm } from "./ConnectedAccountForm";

type Args =
    {
        userLogged: ApplicationUser
    }

const ConnectedAccountAdd = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const addConnectedAccountMutation = useAddConnectedAccount();

    const connectedAccount: ConnectedAccountDto = { 
        id: '',
        legalEntityMemberId: '',
        merchantAccountId: '',
        name: '',
        pspAccountIdentifier: '',
        merchantAccountName: '',
        legalEntityMemberName: '',
     };


    const homeCallBack = () => {
        nav(`/payments`);
    }

    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_SYSTEM')!}
                name={``}
                title={i18n.t('CONNECTEDACCOUNT_TITLE_NEWCONNECTEDACCOUNT'!)}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LR localResource="CONNECTEDACCOUNT_MESSAGE_NEWCONNECTEDACCOUNT"></LR>
                </div>
            </div>


            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ConnectedAccountForm
                        connectedAccount={connectedAccount} isEdit={false}
                        submitted={(connectedAccount) => addConnectedAccountMutation.mutate(connectedAccount)}
                    />
                </div>
            </div>

            {(addConnectedAccountMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={addConnectedAccountMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { ConnectedAccountAdd }
