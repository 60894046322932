import { Card, IconButton, Tooltip } from "@mui/material";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert from "../Common/Errors/MessageAlert";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import { useNavigate } from "react-router";
import { GetRowClassName } from "../../functions/utils/helper";
import { SectionHead } from "../Common/Help/SectionHead";
import { useFetchMemberships } from "../../hooks/Memberships/membership";

type Args =
  {
    userLogged: ApplicationUser
  }

const Memberships = ({ userLogged }: Args) => {
  const nav = useNavigate();
  const { data: memberships, status, isSuccess } = useFetchMemberships(userLogged.operator?.id!);

  const homeCallBack = () => {
    nav(`/`);
  }


  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!memberships) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: '' }}></MessageAlert>;

  return (
    <>

      <SectionHead linkCallBack={homeCallBack}
        ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
        name={`${userLogged.operator!.fullName} (${userLogged.operator!.externalReference})`}
        title={i18n.t("COMMON_LABEL_MEMBERSHIPS")}
        description={i18n.t("COMMON_SUMMARY_MEMBERSHIPMANAGEMENT")} />

      <div className="row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <Card variant="outlined">
            <DataGrid
              rows={memberships}
              getRowId={(row) => row.id}
              columns={[
                { field: 'name', headerName: i18n.t('MEMBERSHIP_LABEL_NAME')!, flex: 1 },
                { field: 'inventoryTypeName', headerName: i18n.t('MEMBERSHIP_LABEL_TYPE')!, flex: 1 },

                { field: 'locationName', headerName: i18n.t('MEMBERSHIP_LABEL_LOCATION')!, flex: 1 },

                {
                  field: 'id', headerName: '', flex: 0.1, align: "center",
                  renderCell: (params) => {

                    return <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                      <IconButton className="fa-info" onClick={() => nav(`${params.id}/summary`)}>
                        <EditRoundedIcon sx={{ color: '#00be95' }} />
                      </IconButton>
                    </Tooltip>
                  },
                }
              ]}

              components={{
                Toolbar: GridToolbar, // Enable search bar in the toolbar
              }}
              classes={{
                toolbarContainer: 'toolbarContainer'
              }}

              getRowClassName={GetRowClassName}

              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};


export { Memberships }