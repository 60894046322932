import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchLocationById } from "../../hooks/Location/location";
import { FeaturesTreeview } from "../Common/Features/FeaturesTreeview";
import { useFetchLocationFeatureByGroupName, useManageLocationFeatures } from "../../hooks/Location/locationFeatures";
import { LR } from "../Common/Help/LR";
import { useEffect, useState } from "react";
import { FeaturesDto } from "../../types/Common/FeaturesDto";
import '../Common/Features/FeatureGroup.css'
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { SectionHead } from "../Common/Help/SectionHead";
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import '../../main/App.css'

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationFeatureGroup = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const [selectedFeatures, setSelectedFeatures] = useState<FeaturesDto[]>([]);
    const [description, setDescription] = useState<string>('');
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const queryStringParams = useParams();
    const { mutate } = useManageLocationFeatures();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.featuregroup) throw Error("FeatureGroup id needed");
    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchLocationFeatureByGroupName(queryStringParams.id!, queryStringParams.featuregroup!);


    const linkCallBack = () => {

        let url = `/locations/${queryStringParams.id}/features`;
        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        }
        else {
            nav(url);
        }
    }

    const callBack = (features: FeaturesDto[]) => {
        setSelectedFeatures(features);

    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setShowConfirmation(false);
            unsetDirty();
            //we need to add the first element and the description
            selectedFeatures.push({ description: description, value: data.value });

            mutate([queryStringParams.id!, queryStringParams.featuregroup!, selectedFeatures]);
            e.preventDefault();
            setSnackbarOpen(true); // Show snackbar
        } catch (err: any) {
            console.error(err);
        }
    }

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (data) {
            setDescription(data.description);
        }

    }, [data]);



    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !location) return <ApiStatus status={status} />;

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("COMMON_SUMMARY_FEATURES")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(data.label)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-10 justify-content-center">
                    <FeaturesTreeview handleDirty={handleDirty} items={data} callback={callBack}></FeaturesTreeview>
                </div>
            </div>

            <CustomQuillEditor
                value={description}
                onChange={(newDescription) => { setDescription(newDescription); handleDirty(); }}
                placeholder={i18n.t("LOCATION_LABEL_FEATUREDESCRIPTION", { featureName: data.label }).toString()}
            />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={!isDirty}>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {isDirty && (
                 <div 
                 className="alert alert-center " 
                 role="alert"
             >
                 <p className='warning-label'>
                    {i18n.t('WARNING_LABEL')}
                 </p>
             </div>
            )}

            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { LocationFeatureGroup }