import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { getLabelsByOperatorType, useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const KycDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Operator id needed");
    if (!queryStringParams.country) throw Error("Country id needed");

    const linkCallBackHome = () => {
        nav(`/operators/${queryStringParams.id}/summary`);
    }

    const linkCallBack = () => {
        nav(`/operators/${queryStringParams.id}/summary/`);
    }

 

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('kyc', queryStringParams.id!, queryStringParams.country!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

   const labels = getLabelsByOperatorType(operator);

    return (
        <>
             <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(labels!.cta)!}
                    name={`${operator.fullName} (${operator.externalReference})`}
                    title={i18n.t('COMMON_SUMMARY_KYC-DETAILS')}
                    description={i18n.t(labels!.label)} />
           
            <FileuploadContainer items={data} groupname={queryStringParams.country!}></FileuploadContainer>

            
        </>
    );
};


export { KycDetails }