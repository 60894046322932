import React, { useRef, useState, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./CustomQuillEditor.css";
import Modal from 'react-bootstrap/Modal';
import EditorToolbar from './EditorToolbar';

interface CustomQuillEditorProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
    onSaveChanges?: (html: string) => void;
}

const CustomQuillEditor: React.FC<CustomQuillEditorProps> = ({ value, onChange, placeholder, onSaveChanges }) => {
    const quillRef = useRef<ReactQuill>(null);
    const [showSourceModal, setShowSourceModal] = useState(false);
    const [editorHtml, setEditorHtml] = useState(value); 

    const handleViewSource = useCallback(() => {
        const editor = quillRef.current?.getEditor();
        if (editor) {
            setEditorHtml(editor.root.innerHTML);
            setShowSourceModal(true);
        }
    }, []);

    const handleEditorChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setEditorHtml(e.target.value);
    }, []);

    const handleSaveChanges = useCallback(() => {
        if (onSaveChanges) {
            onSaveChanges(editorHtml); 
        } else {
            onChange(editorHtml);  
        }
        setShowSourceModal(false);
    }, [editorHtml, onSaveChanges, onChange]);
    

    return (
        <div className="row d-flex justify-content-center mt-2">
            <div className="col-md-5 col-10 justify-content-center mt-1">
                <EditorToolbar onViewSourceClick={handleViewSource} />
                <ReactQuill
                    ref={quillRef}
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    modules={{ toolbar: '#toolbar' }}
                    formats={['bold', 'italic', 'underline', 'link', 'list', 'bullet']}
                />
            </div>
            <Modal className="custom-modal-position" show={showSourceModal} onHide={() => setShowSourceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className='modal-title'>View Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <textarea 
                        className="form-control"
                        value={editorHtml}
                        onChange={handleEditorChange}
                    />
                    <div className="original-text-display">
                        <p className="text-header">Original Text:</p>
                        <div dangerouslySetInnerHTML={{ __html: value }} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="primary-button" onClick={handleSaveChanges}>Save Changes</button>
                    <button className="secondary-button" onClick={() => setShowSourceModal(false)}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CustomQuillEditor;
