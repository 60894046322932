import { useNavigate, useParams } from "react-router";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";
import { useState } from "react";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import ApiStatus from "../Common/Errors/ApiStatus";
import { ConnectedAccountDto } from "../../types/Order/ConnectedAccount";
import { useUpdateConnectedAccount, useFetchConnectedAccountById } from "../../hooks/Order/connectedAccount";
import { ConnectedAccountForm } from "./ConnectedAccountForm";

type Args =
    {
        userLogged: ApplicationUser
    }

const ConnectedAccountEdit = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const nav = useNavigate();
    if (!queryStringParams.id) throw Error("ConnectedAccount id needed");
    const { data: connectedAccount, status: connectedAccountStatus, isSuccess: connectedAccountIsSuccess } = useFetchConnectedAccountById(queryStringParams.id!)

    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const success = () => {
        setSnackbarOpen(true);
    }

    const updateConnectedAccountMutation = useUpdateConnectedAccount(success);

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    const homeCallBack = () => {
        nav(`/payments`);
    }

    if (( !connectedAccountIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={connectedAccountStatus} />
                </div>
            </div>
        </>
    )

    if (!connectedAccount ) return <ApiStatus status={connectedAccountStatus} />;

    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_SYSTEM')!}
                name={``}
                title={i18n.t('CONNECTEDACCOUNT_TITLE_NEWCONNECTEDACCOUNT'!)}
                description={i18n.t('COMMON_SUMMARY_SYSTEMMANAGEMENT')} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LR localResource="CONNECTEDACCOUNT_MESSAGE_NEWCONNECTEDACCOUNT"></LR>
                </div>
            </div>


            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ConnectedAccountForm
                        connectedAccount={connectedAccount} isEdit={true}
                        submitted={(connectedAccount) => updateConnectedAccountMutation.mutate(connectedAccount)}
                    />
                </div>
            </div>

            {(updateConnectedAccountMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={updateConnectedAccountMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};
export { ConnectedAccountEdit }
