import { useState } from "react";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import React from "react";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { FiscalDetailDto } from "../../types/Member/FiscalDetailDto";
import { useManageMemberFiscalDetails } from "../../hooks/Member/fiscaldetails";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";

type Args = {
  entityKey: string,
  entityName: string,
  items: FiscalDetailDto[],
  handleDirty: () => void
};

const FiscalDetailsCollection = ({ entityKey, entityName, items, handleDirty }: Args) => {

  const isFormValid = items.every((item) => item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<FiscalDetailDto[]>(items);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(isFormValid);
  const mutateManageFiscal = useManageMemberFiscalDetails();

  const setItemValue = (item: FiscalDetailDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setIsFormValidState(updatedItemsState.every((item) => item.value.trim() !== '' && !item.errorMessage));
    setItemsState(updatedItemsState);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      mutateManageFiscal.mutate([entityKey, itemsState]);
    } catch (err: any) {
      console.error(err);
    }
  }



  const validateForm = (item: FiscalDetailDto): FiscalDetailDto => {

    const ibantools = require('ibantools');

    switch (item.fiscalDetailTypeName.toUpperCase()) {
      case "IBAN":
        const iban = ibantools.electronicFormatIBAN(item.value); 
      const validation = ibantools.validateIBAN(iban);
        
        if (!validation.valid) {
          item.errorMessage = "MEMBER_ERROR_IBANFORMATTING"
        } else {
          item.value = ibantools.friendlyFormatIBAN(iban);
          item.errorMessage = ""
        }
        return item;
      default:
        return item;

    }
  };


  return (
    <form onSubmit={onFormSubmit}>
      {itemsState.map((item, index) => (
        <React.Fragment key={index}>
          
          <LabelAndHelp mandatory={true} message={item.label}></LabelAndHelp>
          
          <div className="row d-flex justify-content-center mt-1 mb-3">
            <div className="col-md-5 col-11 d-flex align-items-center">
              <input
                type="text"
                value={item.value}
                className={`form-control fa-form `}
                onChange={(e) => { setItemValue(item, e.target.value); handleDirty() }}
                placeholder={i18n.t(item.label).toString()}
              />
            </div>
          </div>
          {(item.errorMessage) &&
            <div className="row d-flex justify-content-center">
              <div className="col-md-5 col-11 small justify-content-center">
                <MessageAlert message={item.errorMessage} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }
        </React.Fragment>
      ))}

      <div className="row d-flex justify-content-center mt-3">
        <div className="col-md-2 col-6 justify-content-center">
          <button
            type="submit"
            disabled={!isFormValidState}
            className="btn btn-green btn-primary btn-block">
            <LR localResource="COMMON_LABEL_SAVE" />
          </button>
        </div>
      </div>
    </form>
  );
}

export { FiscalDetailsCollection };
