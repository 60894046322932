import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchDigitalAssetsRequirements } from "../../hooks/Common/fileUpload";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { FileuploadContainer } from "../Common/Upload/FileUploadContainer";
import { SectionHead } from "../Common/Help/SectionHead";
import { useFetchMembershipById } from "../../hooks/Memberships/membership";

type Args =
    {
        userLogged: ApplicationUser
    }

const MembershipDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Membership id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");

    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/memberships/${queryStringParams.id}/summary`);
    }

    const { data: membership, status: membershipStatus, isSuccess: membershipIsSuccess } = useFetchMembershipById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('membership', queryStringParams.id!, queryStringParams.groupName!);

    if ((!isSuccess || !membershipIsSuccess) && (status !== 'success') && (membershipStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={membershipStatus} />
                </div>
            </div>
        </>
    )

    if (!membership || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGE")!}
                name={`${membership.name} (${membership.externalReference})`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('COMMON_SUMMARY_MEMBERSHIPMANAGEMENT')} />

            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { MembershipDigitalAssets }