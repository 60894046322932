import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { SummaryItemDto } from "../../../types/Common/SummaryItemDto";
import { useEffect, useState } from "react";
import { SectionHead } from "../../Common/Help/SectionHead";
import i18n from "../../../i18n";
import { LabelAndHelp } from "../../Common/Help/LabelAndHelp";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { SummaryItemCollection } from "../../Common/SummaryItems/SummaryItemCollection";
import { Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Tooltip, Typography } from "@mui/material";
import { LR } from "../../Common/Help/LR";
import { deletePostById, useCreatePost, useFetchPostById, useFetchPostSummariesById, useUpdatePost } from "../../../hooks/Posts/posts";
import { pontPostSections } from "../../../data/en/pontPostSections";
import Select from "react-select";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { SaveSnackBar } from "../../Common/SaveSnackBar";
import { PostRequestDto } from "../../../types/Post/PostRequestDto";

type Args =
    {
        userLogged: ApplicationUser,
        isEdit: boolean
    }

const PostDetails = ({ userLogged, isEdit }: Args) => {
    const nav = useNavigate();
    const queryStringParams = useParams();
    const applicationId = queryStringParams.id;
    let summaryName: string = '';

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const updatedCallback = () => {
        nav(`/application/${applicationId}/configuration/posts/${queryStringParams.postId}`);
    }

    const createdCallback = (postId: string) => {
        nav(`/application/${applicationId}/configuration/posts/${postId}`);
    }

    const homeCallBack = () => {
        nav(`/application/${applicationId}/configuration/posts`);
    }

    const defaultPost: PostRequestDto = {
        id: '',
        title: '',
        headline: '',
        priority: 0,
        fromDate: new Date(),
        toDate: new Date(),
        callToAction: '',
        sectionName: 'Announcement',
        author: '',
        applicationId: parseInt(applicationId!),
        features: []
    };

    const { data: post, status: postStatus, isSuccess: postIsSuccess } = useFetchPostById(queryStringParams.postId!, isEdit);
    const [postState, setPostState] = useState(defaultPost);
    const { mutate } = useUpdatePost(updatedCallback);
    const create = useCreatePost(createdCallback);
    const [selectedSection, setSelectedSection] = useState(pontPostSections[0]);
    const { data: summaries } = useFetchPostSummariesById(queryStringParams.postId!, summaryName!, isEdit);
    
    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "ContentSummary":
                nav(`/application/${applicationId}/configuration/posts/${queryStringParams.postId}/content`);
                break;
            case "FeatureSummary":
                nav(`/application/${applicationId}/configuration/posts/${queryStringParams.postId}/features`);
                break;
            case "ImageSummary":
                nav(`/application/${applicationId}/configuration/posts/${queryStringParams.postId}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    useEffect(() => {
        if (isEdit) {
            if (postIsSuccess && post) {
                post.fromDate = new Date(post.fromDate);
                post.toDate = new Date(post.toDate);
                delete post.content;
                setPostState(post);
                let selectedSection = pontPostSections.find(x => x.value === post.sectionName);
                setSelectedSection(selectedSection!);
            }
        }
    }, [postStatus]);

    const requiredPopulated = () => {
        return !!postState.title &&
            !!postState.headline &&
            postState.toDate > postState.fromDate;
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();

            if (isEdit) {
                await mutate(postState);
            }
            else {
                await create.mutate(postState);
            }
            setSnackbarOpen(true);
        } catch (err: any) {
            console.error(err);
        }
    }

    //#region snackbar
    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    const handleSectionChange = (selectedOption: any) => {
        setSelectedSection(selectedOption);
        setPostState({ ...postState, sectionName: selectedOption.value });
    }

    const [error, setError] = useState<string>('');
    const [dialogConfirmation, setDialogConfirmation] = useState<boolean>(false);
    const [dialogName, setDialogName] = useState<string>('');
    const [dialogId, setDialogId] = useState<string>('');
    const [dialogAction, setDialogAction] = useState<string>('');

    const confirmDialog = async (id: string) => {
        switch (dialogAction.toUpperCase()) {
            case "DELETE":
                const resultDelete = await deletePostById(id);
                if (resultDelete.success) {
                    nav(`/application/${applicationId}/configuration/posts`)
                } else {
                    setError("POSTS_ERROR_ALLOCATIONDELETION");
                }
                break;
        }

        setDialogConfirmation(false);
    }

    const handleDelete = (name: string, id: string) => {
        setError('');
        setDialogAction("DELETE")
        setDialogName(name);
        setDialogId(id);
        setDialogConfirmation(true);
    }

    return (
        <>
            <form onSubmit={onFormSubmit} >
                <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>

                {!queryStringParams.summaryName &&
                    <SectionHead linkCallBack={homeCallBack}
                        ctaText={i18n.t('APPLICATION_LABEL_POSTS')!}
                        name={isEdit ? `${postState.title}` : ''}
                        title={i18n.t('POSTS_LABEL_TITLE')}
                        description={i18n.t('APPLICATION_LABEL_POSTS')} />

                }

                <div className="row d-flex justify-content-center">
                    <div className="col-md-5 col-11 justify-content-center">
                        <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_TITLE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="txext"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("POST_LABEL_TITLE").toString()}
                                    value={postState.title}
                                    onChange={e => { setPostState({ ...postState, title: e.target.value }); }} required></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_HEADLINE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="txext"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("POST_LABEL_HEADLINE").toString()}
                                    value={postState.headline}
                                    onChange={e => { setPostState({ ...postState, headline: e.target.value }); }} required></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_CALLTOACTION' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="txext"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("POST_LABEL_CALLTOACTION").toString()}
                                    value={postState.callToAction}
                                    onChange={e => { setPostState({ ...postState, callToAction: e.target.value }); }}></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_AUTHOR' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="txext"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("POST_LABEL_AUTHOR").toString()}
                                    value={postState.author}
                                    onChange={e => { setPostState({ ...postState, author: e.target.value }); }}></input>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_PRIORITY'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="quantity"
                                    min={0}
                                    value={postState.priority}
                                    placeholder={i18n.t('POST_LABEL_PRIORITY')!}
                                    className="form-control fa-form"
                                    onChange={(e) => { setPostState({ ...postState, priority: Number(e.target.value) }); }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='POST_LABEL_SECTION'></LabelAndHelp>
                                <Select
                                    value={selectedSection} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                    className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                    options={pontPostSections}
                                    onChange={handleSectionChange}
                                    placeholder={i18n.t("POST_LABEL_SECTION").toString()}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t("POST_LABEL_FROM").toString()}
                                    className="fa-datepicker"
                                    value={dayjs(postState.fromDate)}
                                    onChange={(newValue) => { setPostState({ ...postState, fromDate: newValue?.toDate()! }); }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t("POST_LABEL_TO").toString()}
                                    className="fa-datepicker"
                                    value={dayjs(postState.toDate)}
                                    onChange={(newValue) => { setPostState({ ...postState, toDate: newValue?.toDate()! }); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row d-flex justify-content-center`}>
                    <div className="col-md-5 col-11 justify-content-center">
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summaries || []}></SummaryItemCollection>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-md-2 col-6 justify-content-center">
                        <Tooltip title={!requiredPopulated() ? i18n.t('EVENT_REQUIRED_TOOLTIP') : ''}>
                            <span>
                                <button type="submit" disabled={!requiredPopulated()} style={!requiredPopulated() ? { pointerEvents: 'none' } : {}} className="btn btn-green btn-primary btn-block">
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>

                <div className={`row d-flex justify-content-center`}>
                    {isEdit &&
                        <div className="col-3 justify-content-center" style={{ textAlign: 'center' }}>
                            <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: postState.title })}>
                                <IconButton onClick={(e) => handleDelete(postState.title, postState.id!.toString())} >
                                    <DeleteRoundedIcon color={'error'} />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="caption" color="textSecondary">
                                <LR localResource="COMMON_BUTTON_DELETE"></LR>
                            </Typography>
                        </div>
                    }
                </div>

                <Dialog open={dialogConfirmation} onClose={() => setDialogConfirmation(false)}>
                    <Card>
                        <CardContent>
                            <DialogTitle><LR localResource={`POST_TITLE_${dialogAction}`} /></DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant="body1" color="textSecondary">
                                        <LR localResource={`POST_LABEL_${dialogAction}`} params={{ name: dialogName }} />
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <button className='btn btn-secondary' onClick={() => setDialogConfirmation(false)}>
                                    <LR localResource='COMMON_BUTTON_CANCEL' />
                                </button>
                                <button className='btn-primary btn-green btn' type="button" onClick={() => { confirmDialog(dialogId); }} color="error" autoFocus>
                                    <LR localResource={`COMMON_BUTTON_${dialogAction}`} />
                                </button>
                            </DialogActions>
                        </CardContent>
                    </Card>
                </Dialog>
            </form >

        </>
    );
};

export { PostDetails }