import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import { ProductBenefitDto } from '../../types/Product/ProductBenefitDto';
import { useFetchBenefitById, useManageProductBenefit } from '../../hooks/Product/benefits';
import { LabelAndHelp } from '../Common/Help/LabelAndHelp';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { pontBenefitTypeConstants, pontBenefitTypes } from '../../data/en/pontBenefitTypes';
import Select from 'react-select';
import { pontCreditUnits } from '../../data/en/pontCreditUnits';
import { pontCreditPeriodUnits } from '../../data/en/pontCreditPeriodUnits';
import { pontDiscountTypes } from '../../data/en/pontDiscountTypes';
import { useFetchProductTypes } from '../../hooks/Product/product';
import { OperationResultDto } from '../../types/Common/OperationResultDto';
import { RuleProductTypeResponseDto } from '../../types/Product/RuleProductTypeResponseDto';
import { ProductType } from './Configuration/ProductType';
import { ProductTypeResponseDto } from '../../types/Product/ProductTypeResponseDto';

type Args =
    {
        userLogged: ApplicationUser,
        isEdit: boolean
    }

const BenefitDetails = ({ userLogged, isEdit }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.productId) throw Error("Product id needed");
    const productId = params.productId;
    const benefitId = params.id;

    const emptyProductBenefitDto: ProductBenefitDto = {
        id: '',
        name: '',
        benefitTypeName: 'Note',
        displayOrder: 0,
        description: '',
        creditUnitName: '',
        creditValue: 0,
        discountTypeName: '',
        discountValue: 0,
        productTypeFlags: 0,
        productId: productId,
        creditPeriodUnitName: ''
    };

    const { data: benefit, status, isSuccess } = useFetchBenefitById(productId, benefitId!, isEdit);
    const [useBenefit, setUseBenefit] = useState(emptyProductBenefitDto);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [selectedBenefitType, setSelectedBenefitType] = useState(pontBenefitTypes[0]);
    const creditUnitOptions = pontCreditUnits.map(x => ({ label: x.label, value: x.id.toString() }));
    const creditPeriodUnitOptions = pontCreditPeriodUnits.map(x => ({ label: x.label, value: x.id.toString() }));
    const [selectedCreditOption, setSelectedCreditOption] = useState(creditUnitOptions[0]);
    const [selectedCreditPeriodOption, setSelectedCreditPeriodOption] = useState(creditPeriodUnitOptions[0]);
    const [selectedDiscountOption, setSelectedDiscountOption] = useState(pontDiscountTypes[0]);
    const { data: productTypes } = useFetchProductTypes();
    const [useProductTypes, setUseProductTypes] = useState<ProductTypeResponseDto[]>([]);
    const [initialToggles, setInitialProductToggles] = useState<boolean[]>();

    const linkCallBack = () => {
        nav(`/products/${productId}`);
    }
    const updateMutation = useManageProductBenefit(productId, linkCallBack);

    useEffect(() => {
        if (productTypes) {
            setUseProductTypes(productTypes.slice(0, 6));
        }
    }, [productTypes]);

    useEffect(() => {
        if (isSuccess && benefit) {
            benefit.productId = productId;
            setUseBenefit(benefit);
            let loadedBenefitType = pontBenefitTypes.find(x => x.value === benefit.benefitTypeName);
            if (loadedBenefitType) {
                setSelectedBenefitType(loadedBenefitType);
            }

            let creditUnitLookup = pontCreditUnits.find(x => x.unitName === benefit.creditUnitName);
            let loadedCreditOption = creditUnitOptions.find(x => x.value === creditUnitLookup?.id.toString());
            if (loadedCreditOption) {
                setSelectedCreditOption(loadedCreditOption);
            }

            let loadedDiscountOption = pontDiscountTypes.find(x => x.value === benefit.discountTypeName);
            if (loadedDiscountOption) {
                setSelectedDiscountOption(loadedDiscountOption);
            }

            let creditPeriodLookUp = pontCreditPeriodUnits.find(x => x.unitName === benefit.creditPeriodUnitName);
            let loadedCreditPeriodOption = creditPeriodUnitOptions.find(x => x.value === creditPeriodLookUp?.id.toString());
            if (loadedCreditPeriodOption) {
                setSelectedCreditPeriodOption(loadedCreditPeriodOption);
                console.log(loadedCreditPeriodOption);
            }

            const flags = [];
            for (let i = 0; i < 6; i++) {
                flags.push((benefit.productTypeFlags & (1 << i)) !== 0);
            }
            setInitialProductToggles(flags);

        }
    }, [isSuccess, benefit]);

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion Dirty

    //#region Snackbar
    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion Snackbar

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            updateMutation.mutate(useBenefit);
            e.preventDefault();
        } catch (err: any) {
            console.error(err);
        }
    }

    const handleTypeChange = (selectedOption: any) => {
        setSelectedBenefitType(selectedOption);
        let creditUnit = '';
        let discountUnit = '';
        if (selectedOption.value === pontBenefitTypeConstants.credit) {
            creditUnit = pontCreditUnits[0].unitName;
        }
        if (selectedOption.value === pontBenefitTypeConstants.discount) {
            discountUnit = pontDiscountTypes[0].value;
        }
        if (selectedOption.value === pontBenefitTypeConstants.discountAndCredit) {
            creditUnit = pontCreditUnits[0].unitName;
            discountUnit = pontDiscountTypes[0].value;
        }
        setUseBenefit({ ...useBenefit!, benefitTypeName: selectedOption.value, creditUnitName: creditUnit, discountTypeName: discountUnit });
        handleDirty();
    }

    const handleCreditUnitChange = (selectedOption: any) => {
        setSelectedCreditOption(selectedOption);
        setUseBenefit({ ...useBenefit, creditUnitName: pontCreditUnits[selectedOption.value].unitName });
        handleDirty();
    }

    const handleCreditPeriodUnitChange = (selectedOption: any) => { 
        setSelectedCreditPeriodOption(selectedOption);
        setUseBenefit({ ...useBenefit, creditPeriodUnitName: pontCreditPeriodUnits[selectedOption.value].unitName });
        handleDirty();
    }

    const handleDiscountUnitChange = (selectedOption: any) => {
        setSelectedDiscountOption(selectedOption);
        setUseBenefit({ ...useBenefit, discountTypeName: selectedOption.value });
        handleDirty();
    }

    const rulesProductsTypesCallBack = (flags: boolean[]) => {
        let flagValue: number = 0;
        flags.forEach((flag, index) => {
            if (flag) {
                flagValue |= (1 << index);
            }
        });
        setUseBenefit({ ...useBenefit, productTypeFlags: flagValue });
    }

    if (isEdit && !isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!benefit && isEdit) return <MessageAlert message="BENEFIT_LABEL_BENEFITNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("COMMON_SUMMARY_PRODUCTMANAGEMENT")!}
                name={`${useBenefit.name}`}
                title={i18n.t('BENEFIT_TITLE_EDIT')}
                description={i18n.t('COMMON_SUMMARY_PRODUCTMANAGEMENT')} />

            <LabelAndHelp mandatory={true} message='BENEFIT_LABEL_NAME'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center">
                    <input type="text"
                        value={useBenefit!.name! || ''}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseBenefit({ ...useBenefit!, name: e.target.value, description: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("BENEFIT_LABEL_NAME").toString()}
                        required>
                    </input>
                </div>
            </div>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center">
                    <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_TYPE'></LabelAndHelp>
                    <Select
                        value={selectedBenefitType} getOptionLabel={(option) => { return i18n.t(option.label) }}
                        className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                        options={pontBenefitTypes}
                        onChange={handleTypeChange}
                        placeholder={i18n.t("BENEFIT_LABEL_TYPE").toString()}
                    />
                </div>
            </div>

            {(useBenefit.benefitTypeName === 'Credit' || useBenefit.benefitTypeName === 'Discount and Credit') &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5 col-11 justify-content-center">
                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-4">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_CREDITVALUE'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="credit"
                                    min={0}
                                    max={10000}
                                    value={useBenefit.creditValue}
                                    placeholder={i18n.t('BENEFIT_LABEL_CREDITVALUE')!}
                                    className="form-control fa-form"
                                    onChange={(e) => { setUseBenefit({ ...useBenefit, creditValue: Number(e.target.value) }); handleDirty(); }}
                                />
                            </div>
                            <div className="col-md-4">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_CREDITUNIT'></LabelAndHelp>
                                <Select
                                    value={selectedCreditOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                    className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                    options={creditUnitOptions}
                                    onChange={handleCreditUnitChange}
                                    placeholder={i18n.t("BENEFIT_LABEL_CREDITUNIT").toString()}
                                />
                            </div>
                            <div className="col-md-4">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_CREDITPERIODUNIT'></LabelAndHelp>
                                <Select
                                    value={selectedCreditPeriodOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                    className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                    options={creditPeriodUnitOptions}
                                    onChange={handleCreditPeriodUnitChange}
                                    placeholder={i18n.t("BENEFIT_LABEL_CREDITUNIT").toString()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {(useBenefit.benefitTypeName === 'Discount' || useBenefit.benefitTypeName === 'Discount and Credit') &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5 col-11 justify-content-center">
                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_DISCOUNTVALUE'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="discount"
                                    min={0}
                                    max={10000}
                                    value={useBenefit.discountValue}
                                    placeholder={i18n.t('BENEFIT_LABEL_DISCOUNTVALUE')!}
                                    className="form-control fa-form"
                                    onChange={(e) => { setUseBenefit({ ...useBenefit, discountValue: Number(e.target.value) }); handleDirty(); }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_DISCOUNTUNIT'></LabelAndHelp>
                                <Select
                                    value={selectedDiscountOption} getOptionLabel={(option) => { return i18n.t(option.label) }}
                                    className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                    options={pontDiscountTypes}
                                    onChange={handleDiscountUnitChange}
                                    placeholder={i18n.t("BENEFIT_LABEL_DISCOUNTUNIT").toString()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {useBenefit.benefitTypeName !== 'Note' && productTypes &&
                <div className="row d-flex justify-content-center mt-2">
                    <div className="col-md-5 col-11 justify-content-center" >
                        <ProductType initialToggles={initialToggles!} mutationCallBack={rulesProductsTypesCallBack} items={useProductTypes!}></ProductType>
                    </div>
                </div>
            }

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <div className="row d-flex justify-content-center mt-4 mb-3">
                        <div className="col-md-6 col-11">
                            <LabelAndHelp mandatory={true} fullscreen={true} message='BENEFIT_LABEL_DISPLAYORDER'></LabelAndHelp>
                            <input
                                type="number"
                                id="displayorder"
                                min={0}
                                max={100}
                                value={useBenefit.displayOrder}
                                placeholder={i18n.t('BENEFIT_LABEL_DISPLAYORDER')!}
                                className="form-control fa-form"
                                onChange={(e) => { setUseBenefit({ ...useBenefit, displayOrder: Number(e.target.value) }); handleDirty(); }}
                            />
                        </div>
                        <div className="col-md-6 col-11">
                        </div>
                    </div>
                </div>
            </div>

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button disabled={!useBenefit.name} type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
        </form >
    );
};

export { BenefitDetails }