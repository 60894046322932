import { useNavigate, useParams } from "react-router";
import {  useFetchLocationById } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { useFetchInventoryById,  useManageInventoryUnavailability } from "../../hooks/Inventory/inventory";
import { useEffect, useState } from "react";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import { UnavailabilityForm } from "./UnavailabilityForm";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const UnavailabilityAdd = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const nav = useNavigate();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.inventoryId) throw Error("Inventory id needed");

    const success = () => {
        nav(callbackLink)
        setSnackbarOpen(true);
    }

    const manageInventoryUnavailabilityMutation = useManageInventoryUnavailability(success, false);

    const defaultFromDate = new Date();
    const defaultToDate = new Date();
    defaultToDate.setDate(defaultFromDate.getDate() + 10);

    const emptyInventoryUnavailability: InventoryUnavailabilityDto = {
        inventoryId : queryStringParams.inventoryId!,
        fromDate : defaultFromDate,
        toDate : defaultToDate
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);    
    const { data: inventory, status: inventoryStatus, isSuccess: inventoryIsSuccess } = useFetchInventoryById( queryStringParams.inventoryId);
    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const [callbackLink, setCallback] = useState(`/locations/${queryStringParams.id!}/inventories/${queryStringParams.inventoryId!}/`);

    useEffect(() => {
        if(inventory && inventory.inventoryGroupName === 'Membership') {
            setCallback(`/memberships/${queryStringParams.inventoryId!}/summary`);
        }
    }, [inventory])
    //#region snackbar
    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    const linkCallBack = () => {
        nav(callbackLink);
    }

    if ((!inventoryIsSuccess) || (!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={inventoryStatus} />
                </div>
            </div>
        </>
    )

    if (!inventory || !location) return <ApiStatus status={inventoryStatus} />;

    return (
        <>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>

            <SectionHead linkCallBack={linkCallBack} ctaText={inventory.name}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('INVENTORY_LABEL_NEWUNAVAILABILITY')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <UnavailabilityForm  locationId={location.id!} inventoryUnavailability={emptyInventoryUnavailability} isEdit={false} submitted={(inventoryUnavailability) => {manageInventoryUnavailabilityMutation.mutate(inventoryUnavailability)}}></UnavailabilityForm>
                </div>
            </div>

            {(manageInventoryUnavailabilityMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={manageInventoryUnavailabilityMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { UnavailabilityAdd }