import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import 'react-quill/dist/quill.snow.css';
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { useFetchLocationById, useUpdateLocationDescription } from '../../hooks/Location/location';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import '../../main/App.css'

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationDescription = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Location id needed");

    const emptyLocationResponseDto: LocationResponseDto = {
        id: '',
        name: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId : '',
        legalEntityMemberId : '',
        longDescription: '',
        countryIso3Code: '',
        shortDescription: '',
        summaries: [],
        brandName:''

    };

    const [descriptionState, setDescriptionState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const { data: location, status, isSuccess } = useFetchLocationById(params.id)
    const [useLocation, setUseLocation] = useState(emptyLocationResponseDto);

    useEffect(() => {
        if (isSuccess && location) {
            setUseLocation(location);
            setUseLocation({ ...useLocation, id: location.id });
            setDescriptionState(location.longDescription);
        }
    }, [isSuccess, location]);

    const handleDescriptionChange = (value: string) => {
        setDescriptionState(value);
        setUseLocation({ ...useLocation, longDescription: value });
        setUnsavedChanges(true);
    }


    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            updateMutation.mutate(useLocation);
            e.preventDefault();
            setSnackbarOpen(true); // Show snackbar
            setUnsavedChanges(false);
        } catch (err: any) {
            console.error(err);
        }
    }

    const linkCallBack = () => {
        nav(`/locations/${location!.id}/summary`);
    }

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const updateMutation = useUpdateLocationDescription(linkCallBack);

    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!location) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

             <CustomQuillEditor
                value={descriptionState}
                onChange={handleDescriptionChange}
                placeholder={i18n.t("LOCATION_LABEL_FEATUREDESCRIPTION", { locationDescription: location.name }).toString()}
            /> 
            
            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {unsavedChanges && (
                <div 
                    className="alert alert-center " 
                    role="alert"
                >
                    <p className='warning'>
                        {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { LocationDescription }