import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { useFetchLocationById } from "../../hooks/Location/location";
import { useFetchInventoryByLocationId } from "../../hooks/Inventory/inventory";
import { InventoryItemCollection } from "../Inventory/InventoryItemCollection";
import { IconButton, Tooltip } from "@mui/material";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { LR } from "../Common/Help/LR";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationInventories = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");

    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/summary/`);
    }

    const addNewItem = () => {
        nav(`/locations/${queryStringParams.id}/inventories/add`);
    };

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchInventoryByLocationId(queryStringParams.id!, userLogged.operator?.id!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('LOCATION_LABEL_INVENTORIES')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-5 col-11 d-flex align-items-center">
                    <Tooltip title={i18n.t('INVENTORY_LABEL_ADDINVENTORY')}>
                        <IconButton onClick={addNewItem} className="btn plusButton mr-2"> {/* Added mr-2 for some spacing */}
                            <AddCircleRoundedIcon fontSize="medium" sx={{ color: '#00be95' }} />
                        </IconButton>
                    </Tooltip>
                    <span className="lead mb-0"> {/* Set margin-bottom to 0 */}
                        <LR localResource="LOCATION_TITLE_NEWINVENTORY" />
                    </span>
                </div>
            </div>

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <InventoryItemCollection items={data}></InventoryItemCollection>
                </div>
            </div>
        </>
    );
};


export { LocationInventories }