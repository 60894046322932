const HookConfig = {
    memberUrl : "/member-management/members",
    memberMgmtUrl : "/member-management",
    locationUrl : "/location-management/locations",
    locationBaseUrl : "/location-management",
    inventoryUrl : "/inventory-management",
    productUrl : "/product-management",
    notificationUrl: "/notification-management",
    orderUrl: "/order-management",
    postUrl: "/post-management"
}

export
{
    HookConfig
}