import { useNavigate, useParams } from "react-router";
import {  useFetchLocationById } from "../../hooks/Location/location";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { useFetchInventoryById,  useManageAllocationInventory } from "../../hooks/Inventory/inventory";
import { useState } from "react";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { InventoryAllocationDto } from "../../types/Inventory/InventoryAllocationDto";
import { AllocationForm } from "./AllocationForm";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const AllocationAdd = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const nav = useNavigate();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.inventoryId) throw Error("Inventory id needed");

    const success = (inventoryAllocation: InventoryAllocationDto ) => {
        nav(`/locations/${queryStringParams.id!}/inventories/${inventoryAllocation.inventoryId}/allocations/${inventoryAllocation.id}`)
        setSnackbarOpen(true);
    }


    const manageInventoryAllocationMutation = useManageAllocationInventory(success, false);
    const emptyInventory: InventoryAllocationDto = {
        inventoryId : queryStringParams.inventoryId!,
        inventoryTypeName : '',
        inventoryVariantId : '',
        quantity : 0,
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);    
    const { data: inventory, status: inventoryStatus, isSuccess: inventoryIsSuccess } = useFetchInventoryById( queryStringParams.inventoryId!);
    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/inventories/${queryStringParams.inventoryId!}`);
    }

    if ((!inventoryIsSuccess) || (!locationIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={inventoryStatus} />
                </div>
            </div>
        </>
    )

    if (!inventory || !location) return <ApiStatus status={inventoryStatus} />;

    return (
        <>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>


            <SectionHead linkCallBack={linkCallBack} ctaText={inventory.name}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('INVENTORY_LABEL_NEWALLOCATION')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

          

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <AllocationForm countryIsoCode3={location.countryIso3Code} locationId={location.id!} inventoryAllocation={emptyInventory} isEdit={false} submitted={(inventoryAllocation) => manageInventoryAllocationMutation.mutate(inventoryAllocation)}></AllocationForm>
                </div>
            </div>

            {(manageInventoryAllocationMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={manageInventoryAllocationMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { AllocationAdd }