import { useNavigate } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../../Common/SummaryItems/SummaryItemCollection";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SummaryItemDto } from "../../../types/Common/SummaryItemDto";
import { useFetchPricingCataloues } from "../../../hooks/Product/product";

type Args =
    {
        userLogged: ApplicationUser
    }

const PricingCatalogueConfigurationSummary = ({ userLogged }: Args) => {

    const nav = useNavigate();
    let summaryName = null;

    const titleLabel = "MENU_LABEL_PRODUCTS";
    const productLink = (item: SummaryItemDto) => {
        nav(item.entityKey)
    }

    const handleArrowIconClick = () => {
        nav(`configuration`);
    };


    const {data, status, isSuccess} = useFetchPricingCataloues(userLogged.operator?.id!)

    if ((!isSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data) return <ApiStatus status={status} />;

    return (
        <>
          
            <div className={`row d-flex justify-content-center`}>
                <div className="col-md-5 col-11 justify-content-center">
                    <SummaryItemCollection useNameInsteadOfLabel={true} hideIcon={true} linkCallBack={productLink} items={data}></SummaryItemCollection>

                </div>
            </div>
        </>
    );
};


export { PricingCatalogueConfigurationSummary }


