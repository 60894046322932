import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { DigitalAssetDto } from "../../types/Common/DigitalAssetDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";

const HandleViewFiles = async (version: string, entityKey: string, entityName: string, sourceKey: string, sourceName: string) => {
  return useQuery<string[], AxiosError>(["files", entityKey, entityName, sourceKey, sourceName], () =>
    axios.get(`/file-management/v${version}/Files/${entityName}-${entityKey}/search?path=${sourceName}/${sourceName}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchDigitalAssetsRequirements = (type: string, id: string, groupName: string) => {

  let url: string;
  switch (type) {
    case "location":
      url = `${HookConfig.locationUrl}/${id}/digital-assets?group=${groupName}`;
      break;
    case "member":
      url = `${HookConfig.memberUrl}/${id}/digital-assets?group=${groupName}`;
      break;
    case "event":
      url = `${HookConfig.inventoryUrl}/events/${id}/digital-assets?group=${groupName}`;
      break;
    case "post":
        url = `${HookConfig.postUrl}/posts/${id}/digital-assets?group=${groupName}`;
        break;
    case "kyc":
      url = `${HookConfig.memberUrl}/${id}/kyc-details?countryiso3code=${groupName}`;
      break;
    case "membership":
      url = `${HookConfig.inventoryUrl}/memberships/${id}/digital-assets?group=${groupName}`;
      break;
    case "product":
      url = `${HookConfig.productUrl}/products/${id}/digital-assets?group=${groupName}`;
      break;
  }

  return useQuery<DigitalAssetDto[], AxiosError>(["digital-assets", id, groupName], () =>

    axios.get(
      url,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

export {
  HandleViewFiles,
  useFetchDigitalAssetsRequirements

}