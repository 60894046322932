import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import "../Common/react-draft-wysiwyg.css";
import { SectionHead } from '../Common/Help/SectionHead';
import { useFetchEventById, useUpdateEventDescription } from '../../hooks/Events/event';
import { EventResponseDto } from '../../types/Event/EventResponseDto';
import CustomQuillEditor from '../Common/Input/CustomQuillEditor';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import 'react-quill/dist/quill.snow.css';
import './EventDescription.css';
import '../../main/App.css'


type Args =
    {
        userLogged: ApplicationUser
    }

const EventDescription = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Event id needed");

    const emptyEventResponseDto: EventResponseDto = {
        id: params.id,
        name: '',
        isOnlineEvent: false,
        locationId: '00000000-0000-0000-0000-000000000000',
        timeZoneName: '',
        ownerMemberId: '00000000-0000-0000-0000-000000000000',
        legalEntityMemberId: '00000000-0000-0000-0000-000000000000',
        quantity: 0,
        retainedQuantity: 0,
        quantityLimitPerOrder: 0,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        ticketsSold: 0,
        saleCount: 0,
        ownerMemberFullName: '',
        legalEntityMemberFullName: '',
        locationName: '',
        shortDescription: '',
        description: '',
        checkOutNote: ''
    };

    const [descriptionState, setDescriptionState] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const { data: event, status, isSuccess } = useFetchEventById(params.id)
    const [useEvent, setUseEvent] = useState(emptyEventResponseDto);
    const [unsavedChanges, setUnsavedChanges] = useState(false);  

    useEffect(() => {
        if (isSuccess && event) {
            setUseEvent(event);
            setDescriptionState(event.description);
        }
    }, [isSuccess, event]);

    const handleDescriptionChange = (value: string) => {
        setDescriptionState(value);
        setUnsavedChanges(true);
        setUseEvent({ ...useEvent, description: value });
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            updateMutation.mutate(useEvent);
            e.preventDefault();
            setSnackbarOpen(true); 
        } catch (err: any) {
            console.error(err);
        }
    }

    const linkCallBack = () => {
        nav(`/events/${event!.id}/summary`);
    }

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const updateMutation = useUpdateEventDescription(linkCallBack, "Full");


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!event) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("EVENT_SUMMARY_EVENTMAINPAGE")!}
                name={`${event.name} (${event.externalReference})`}
                title={i18n.t('COMMON_SUMMARY_DESCRIPTIONS')}
                description={i18n.t('COMMON_SUMMARY_EVENTMANAGEMENT')} />

            <CustomQuillEditor
                value={descriptionState} 
                onChange={handleDescriptionChange}
                placeholder={i18n.t("EVENT_LABEL_DESCRIPTION", { eventDescription: EventDescription.name })}
                onSaveChanges={(updatedHtml) => {
                    handleDescriptionChange(updatedHtml);  
                }}
            />

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block">
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {unsavedChanges && (
                <div 
                    className="alert alert-center " 
                    role="alert"
                >
                    <p className='warning-label'>
                        {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { EventDescription }