import { useNavigate } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SectionHead } from "../Common/Help/SectionHead";
import i18n from "../../i18n";
import { useFetchApplicationSummaryById, useFetchApplications } from "../../hooks/Member/applicationsConfiguration";
import Select from "react-select";
import { useEffect, useState } from "react";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";

type Args =
    {
        userLogged: ApplicationUser
    }

const ApplicationConfiguration = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const { data: applications } = useFetchApplications()
    const [useApplication, setUseApplication] = useState<{ value: string, label: string }>();
    const [mappedApplications, setMappedApplications] = useState<{ value: string, label: string }[]>([]);
    const { data: summaries, loading } = useFetchApplicationSummaryById(useApplication?.value ?? '');

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "ApplicationPostSummary":
                nav(`/application/${useApplication?.value}/configuration/posts`);
                break;
            case "PartnerOperatorSummary":
                nav(`/application/${useApplication?.value}/configuration/operators`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    useEffect(() => {
        if (applications && applications.length > 0) {
            setUseApplication({ value: applications[0].applicationId, label: applications[0].applicationName });
            setMappedApplications(applications.map(app => ({
                label: app.applicationName,
                value: app.applicationId
            })));
        }
    }, [applications])

    const homeCallBack = () => {
        nav(`/`);
    }

    const handleApplicationChange = (selectedOption: any) => {
        setUseApplication(selectedOption);
    }

    return (
        <>

            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!} name='' title={i18n.t('APPLICATION_CONFIGURATION_PAGE')!} description={i18n.t('COMMON_SUMMARY_APPLICATIONMANAGEMENT')} />

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LabelAndHelp mandatory={false} fullscreen={true} message='APPLICATION_MAIN_LABEL' ></LabelAndHelp>
                    <Select
                        value={useApplication}
                        className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                        options={mappedApplications}
                        onChange={handleApplicationChange}
                        placeholder={i18n.t("APPLICATION_MAIN_LABEL").toString()}
                    />

                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summaries ?? []}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { ApplicationConfiguration }