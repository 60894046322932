import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import i18n from "../../i18n";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useState } from "react";
import { LR } from "../Common/Help/LR";
import { GetRowClassName } from "../../functions/utils/helper";

type Args =
    {
        open: boolean
        operators: MemberResponseDto[]
        type: string,
        handleConfirm: (operator: MemberResponseDto) => void,
        handleCancel: () => void
    }

const DialogOperatorSelector = ({ handleCancel, type, handleConfirm, open,  operators }: Args) => {
   
    const [selectedRow, setSelectedRow] = useState<MemberResponseDto | null>(null);

    const handleRowSelection = (params: any) => {
        const selectedOperator = operators.find(x => x.id === params[0])
        setSelectedRow(selectedOperator!);
      };

      const handleRowDoubleClick = (params: any) => {
        const selectedOperator = operators.find(x => x.id === params[0])
        setSelectedRow(selectedOperator!);
        handleValueSelection();
      };
    
      const handleValueSelection = () => {
        if (selectedRow) {
          handleConfirm(selectedRow);
          handleCancel();
        }
      };


    return (
        <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
          <Card>
        <DialogTitle>
          {type === 'Operator' && <LR localResource="COMMON_LABEL_SELECTOPERATOR"></LR>}
          {type === 'LegalEntity' && <LR localResource="COMMON_LABEL_SELECTLEGALENTITY"></LR>}
          
          
          </DialogTitle>
        <DialogContent>
          <div style={{ height: 400, width: '100%' }}>
            <DataGrid
              rows={operators}
              columns={[
                { field: 'fullName', headerName: i18n.t('MEMBER_LABEL_COMPANYNAME')!, flex: 1 },
                { field: 'countryIso3Code', headerName: i18n.t('COMMON_LABEL_COUNTRY')!, flex: 1 },
                
              ]}
              onRowSelectionModelChange={handleRowSelection}
              onRowDoubleClick={handleRowDoubleClick}
              
              components={{
                Toolbar: GridToolbar, // Enable search bar in the toolbar
              }}
              classes={{
                toolbarContainer : 'toolbarContainer'
              }}
    
              getRowClassName={GetRowClassName}              
              checkboxSelection={false}
              disableColumnSelector={true}
              disableDensitySelector={true}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={handleCancel}><LR localResource='COMMON_BUTTON_CANCEL' /></Button>
          <Button variant={"contained"} onClick={handleValueSelection} disabled={!selectedRow}>
            Select
          </Button>
        </DialogActions>
        </Card>
      </Dialog>
    );
};


export { DialogOperatorSelector }