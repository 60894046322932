import { Alert, Snackbar } from "@mui/material";
import { LR } from "./Help/LR";


type Args = {
    isOpen: boolean,
    onClose: () => void

};

const SaveSnackBar = ({ isOpen, onClose }: Args) => {

    return (
        <Snackbar open={isOpen} autoHideDuration={3000} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
            <Alert onClose={onClose} severity="success" sx={{ width: '100%' }}>
                <LR localResource='COMMON_LABEL_SAVE_SUCCESS'></LR>
            </Alert>
        </Snackbar>
    )
}

export {
    SaveSnackBar
}