import * as path from 'path';

const GenerateExternalReference = (input : string) => {
    const consonants = input
        .toUpperCase()
        .replace(/\s+/g, '')
        .split('')
        .filter(char => 'BCDFGHJKLMNPQRSTVWXYZ'.includes(char));

    const randomConsonants = [];
    const consonantCount = consonants.length;

    for (let i = 0; i < 3; i++) {
        const randomIndex = Math.floor(Math.random() * consonantCount);
        randomConsonants.push(consonants[randomIndex]);
    }

    const randomCode = Math.floor(Math.random() * 900000) + 100000;
    return `${randomConsonants.join('')}-${randomCode}`;
};

function GetFileNameWithoutExtension(filePath: string): string {
    const fileName = filePath.split('/').pop() || filePath.split('\\').pop(); // Handles both forward and backward slashes
    const lastDotIndex = fileName!.lastIndexOf('.');
    return lastDotIndex > 0 ? fileName!.slice(0, lastDotIndex) : fileName!;
  }

  function GetRowClassName(params : any) {
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow';
  }
  

  function FormatDate(date: Date): string {
    const parsedDate = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
  
    return new Intl.DateTimeFormat(undefined, options).format(parsedDate);
  }

  function GetFileNameFromResponseHeaders(headers: any): string {
    const contentDisposition = headers['content-disposition'];
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      const rawFileName = matches[1].replace(/['"]/g, '');
      const decodedFileName = decodeURIComponent(rawFileName);
      return decodedFileName;
    }
    return 'file';
}

function DateShim(date: any): Date {
    let newDate: Date;

    if (date instanceof Date) {
        // If theDate is already a Date object
        newDate = date;
    }
    else { 
        newDate = new Date(date);
    }
    newDate.setHours(0, 0, 0, 0);
    var offsetInMinutes = newDate.getTimezoneOffset();
    newDate.setMinutes(newDate.getMinutes() - offsetInMinutes);
    return newDate;
}

function AddDaysToDate(dateString: any, amount: number) {
    // Parse the date string into a Date object
    const date = new Date(dateString);

    // Add the specified amount of days
    date.setDate(date.getDate() + amount);

    // Convert the updated Date object back to a string in ISO format
    return date;
}


export{
    GenerateExternalReference,
    GetFileNameWithoutExtension,
    GetFileNameFromResponseHeaders,
    GetRowClassName,
    FormatDate,
    DateShim,
    AddDaysToDate
}