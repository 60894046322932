import { useMutation, useQuery, useQueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";


const getLabelsByOperatorType = (operator :MemberResponseDto) =>
{
  let label = 'COMMON_SUMMARY_OPERATORMANAGEMENT';
  let cta = 'MEMBER_SUMMARY_OPERATORMAINPAGE';
  let title = 'MEMBER_SUMMARY_OPERATORMAINPAGE'
if(operator)
{
  
  switch(operator.memberTypeName)
  {
      case 'OperatorContact':
          label = 'COMMON_SUMMARY_CONTACTMANAGEMENT'
          cta = 'MEMBER_SUMMARY_CONTACTMAINPAGE'
          title = ''
          break;
      case 'LegalEntity':
          label = 'COMMON_SUMMARY_LEGALENTITYMANAGEMENT'
          cta = 'MEMBER_SUMMARY_LEGALENTITYMAINPAGE'
          title = 'MEMBER_SUMMARY_LEGALENTITYMAINPAGE'
          break;
  }

  return {label : label, cta : cta, title : title}
}
}

const useFetchMembers = (memberTypeFlags: number, operatorId : string, searchRelationShip? :number) => {
    return useQuery<MemberResponseDto[], AxiosError>(["members", operatorId, memberTypeFlags, searchRelationShip], () => {
        let url = `${HookConfig.memberUrl}?memberTypeFlags=${memberTypeFlags}&searchRelationShip=${searchRelationShip}`;

        if (operatorId) {
            url += `&memberId=${operatorId}`;
        }

        return axios.get(url, {
            withCredentials: true,
            headers: operatorDefaultHeaders()
        })
            .then((resp) => resp.data);
    });

}

const useFetchContactsByMemberId = (id: string) => {
  return useQuery<MemberResponseDto[], AxiosError>(["contacts", id], () =>
  axios.get(`${HookConfig.memberUrl}/${id}/contacts`,
  {
    withCredentials : true,
    headers: operatorDefaultHeaders()
  }
  ).then(
    (resp) => 
    resp.data)
);
}


const useFetchMemberSummaryById = (id: string, summaryName : string) => {
  return useQuery<SummaryItemDto[],AxiosError>(["member-summary", id, summaryName], () =>
    axios.get(
      (summaryName ?
        `${HookConfig.memberUrl}/${id}/${summaryName}/summary` : 
        `${HookConfig.memberUrl}/${id}/summary/`),
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};


const useFetchMemberById = (id: string) => {
  return useQuery<MemberResponseDto,AxiosError>(["member", id], () =>
    axios.get(`${HookConfig.memberUrl}/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      })
      .then(
        (response) => {

          var member : MemberResponseDto = response.data;
          if(!member.address && member.additionalAddresses)
          {
            member.address = member.additionalAddresses[0];
            member.additionalAddresses.length = 0;
          }
          return member;
        }
      ));
};


const useAddMember = (type : string, parentId? : string, callBack? : (id : string) => void ) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, MemberRequestDto>(
    (memberRequest) => axios.post(HookConfig.memberUrl, memberRequest,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      }
    ),
    {
      onSuccess: (_, member) => {
        switch(type)
        {
          case "OperatorContact":
            queryClient.invalidateQueries(["contacts", _.data.id]);
            nav(`/operators/${parentId}/contacts`);
            break;  
          case "User-Contact":
            queryClient.invalidateQueries(["member", _.data.id]);
            break;
            case "LegalEntity":
              queryClient.invalidateQueries(["member", _.data.id]);
              nav(`/legal-entities/${_.data.id}/companydetails`);
              break;
          case "Operator":
            queryClient.invalidateQueries(["member", _.data.id]);                        
            //nav('/?check');
            if (callBack)
            {
              callBack(_.data.id)
            }else
            {
              nav(`/operators/${_.data.id}/companydetails`);
            }
            break;
        }
        
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useUpdateMember = (type : string, parentId? : string, callback?: () => void) => {
  const queryClient = useQueryClient();
  const nav = useNavigate();
  return useMutation<AxiosResponse, AxiosError, MemberResponseDto>(
    (memberResponse) => axios.put(`${HookConfig.memberUrl}/${memberResponse.id}`, memberResponse,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders() 
      }
    ),
    {
      onSuccess: (_, member) => {
        switch (type) {
          case "OperatorContact":
            queryClient.invalidateQueries(["member", member.id]);
            queryClient.invalidateQueries(["contacts", parentId]);
            if(parentId === 'AccountSettings')
            {
              nav(`/`);
            }else
            {
              nav(`/operators/${parentId}/contacts`);
            }
            
            break;
          case "Operator":
            queryClient.invalidateQueries(["member", member.id]);
            //nav(`/operators/${member.id}/summary`);
            callback?.();
            break;
        }
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};

export {
  getLabelsByOperatorType,
  useFetchMemberSummaryById,
  useFetchMembers,
  useFetchContactsByMemberId,
  useFetchMemberById,
  useUpdateMember,
  useAddMember
}