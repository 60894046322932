import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Problem from "../../types/Problem";
import { DomainDto } from "../../types/Common/DomainDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";



const   useManageMemberDomains = () => {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    const manageDomains = async (
        [id, items]: [string, DomainDto[]]
      ): Promise<AxiosResponse<any, any>[]> => {
        const promises = items
        .map(async (item) => {
          const url = item.id === "00000000-0000-0000-0000-000000000000" ?  `${HookConfig.memberUrl}/${id}/domains` :`${HookConfig.memberUrl}/${id}/domains/${item.id}`;
          const method = item.id === "00000000-0000-0000-0000-000000000000" ? "post" : "put";

        const body = item.id === "00000000-0000-0000-0000-000000000000" ? 
            {value: item.value, memberId : id } :
            {id: item.id, memberId : id, value: item.value} 

          const response = await axios[method](
            url, body,
            {
              withCredentials: true,
               headers: operatorDefaultHeaders() ,
            }
          );
          return response;
        });
        const responses = await Promise.all(promises);
        return responses;
      };
  
    const mutation = useMutation<
      AxiosResponse<any, any>[],
      AxiosError<Problem>,
      [string, DomainDto[]]>(manageDomains, {
        onSuccess: (responses, [id, comunicationType] ) => {
            queryClient.invalidateQueries(["member-domains", id]);
            nav(`/operators/${id}/companydetails`);
          },
    });
  
    return mutation;
  };

  const useFetchMemberDomains = (id: string) => {
    return useQuery<DomainDto[],AxiosError>(["member-domains", id], () =>
      axios.get(`${HookConfig.memberUrl}/${id}/domains`,
        {
          withCredentials: true,
          headers: operatorDefaultHeaders() 
        })
        .then(
          (response) => {
            return response.data;
          }
        ));
  };


  const useDeleteDomain = () => {
    const queryClient = useQueryClient();
    return (domain: DomainDto) => {
      return axios
        .delete(`${HookConfig.memberUrl}/${domain.entityKey}/domains/${domain.id}`, {
          withCredentials: true,
           headers: operatorDefaultHeaders() ,
        })
        .then(() => {
          console.log("Successfully deleted domain:", domain);
          queryClient.invalidateQueries(["member-domains", domain.entityKey]);
          return { success: true }; 
        })
        .catch((error) => {
          console.log("Error deleting domain:", error);
          return { success: false, error }; 
        });
    };
  };

  export{
    useManageMemberDomains,
    useFetchMemberDomains,
    useDeleteDomain
  }
 