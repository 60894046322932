import { useState } from "react";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import i18n from "../../i18n";
import CountryDropDown from "../Common/CountryDropDown";
import { LR } from "../Common/Help/LR";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { GenerateExternalReference } from "../../functions/utils/helper";

type Args = {
    isEdit: boolean
    member: MemberRequestDto,
    submitting: boolean
    type?: string
    submitted: (member: MemberRequestDto) => void;
    handleDirty: () => void
};

const MemberForm = ({ member, isEdit, type, submitting, submitted, handleDirty }: Args) => {

    const [memberState, setMemberState] = useState<MemberRequestDto>({ ...member });
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {


            memberState.externalReference = (!memberState.externalReference) ? GenerateExternalReference(memberState.fullName) : memberState.externalReference;
            e.preventDefault();
            submitted(memberState);

        } catch (err: any) {
            console.error(err);
        }
    }

    const setEmail = (value: string) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value.trim())) {
            setEmailError('COMMON_ERROR_EMAILFORMATTING')
        } else {
            setEmailError('');
        }
        setMemberState({ ...memberState, person: { ...memberState.person, email: value } });
    }

    const setPhone = (value: string) => {
        if (isValidPhoneNumber(value)) {
            const parsedPhoneNumber = parsePhoneNumber(value);
            value = parsedPhoneNumber.formatInternational()
            setPhoneError('');
        } else {
            if (value.trim() === '') {
                setPhoneError('');
            } else {
                setPhoneError('COMMON_ERROR_PHONEFORMATTING')
            }
        }
        setMemberState({ ...memberState, person: { ...memberState.person, telephone: value } });
    }

    const ChangeCountry = (value: string) => {

        setMemberState({ ...memberState, countryIso3Code: value })
    }

    return (
        <>
            <form onSubmit={onFormSubmit}>

                {type === 'OperatorContact' &&
                    <>

                    {isEdit && 
                    <>
                    <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_EXTERNALREFERENCE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2 disabled-content">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form readonlyInput"
                                    placeholder={i18n.t("COMMON_LABEL_EXTERNALREFERENCE").toString()}
                                    value={memberState.externalReference}
                                    readOnly></input>
                            </div>
                        </div>
                        </>
                        }


                        <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_FIRSTNAME' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("COMMON_LABEL_FIRSTNAME").toString()}
                                    value={memberState.person?.firstName}
                                    required
                                    onChange={e => { setMemberState({ ...memberState, person: { ...memberState.person, firstName: e.target.value } }); handleDirty() }} ></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_LASTNAME' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("COMMON_LABEL_LASTNAME").toString()}
                                    value={memberState.person?.lastName}
                                    required
                                    onChange={e => { setMemberState({ ...memberState, person: { ...memberState.person, lastName: e.target.value } }); handleDirty() }}
                                ></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_EMAIL' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2 disabled-content">
                            <div className="justify-content-center">
                                <input
                                    type="email"
                                    className={`form-control fa-form ${isEdit ? `readonlyInput`: ``}`}
                                    placeholder={i18n.t("COMMON_PLACEHOLDER_EMAIL").toString()}
                                    value={memberState.person?.email}
                                    title={`${isEdit ? i18n.t(`COMMON_LABEL_EMAILNOTEDITABLE`): ``}`}
                                    required
                                    readOnly={isEdit}
                                    onChange={e => {setEmail(e.target.value); handleDirty()}}
                                ></input>
                            </div>
                        </div>
                        {(emailError) &&
                            <div className="row d-flex justify-content-center">
                                <div className="justify-content-center small">
                                    <MessageAlert message={emailError} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                                </div>
                            </div>
                        }

                        <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_PHONE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("COMMON_PLACEHOLDER_PHONE").toString()}
                                    value={memberState.person?.telephone}
                                    onChange={e => { setPhone(e.target.value); handleDirty() }}
                                ></input>
                            </div>
                        </div>
                        {(phoneError) &&
                            <div className="row d-flex justify-content-center">
                                <div className="justify-content-center small">
                                    <MessageAlert message={phoneError} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                                </div>
                            </div>
                        }

                        <LabelAndHelp mandatory={false} fullscreen={true} message='COMMON_LABEL_JOBTITLE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("COMMON_LABEL_JOBTITLE").toString()}
                                    value={memberState.person?.jobTitle}
                                    onChange={e => { setMemberState({ ...memberState, person: { ...memberState.person, jobTitle: e.target.value } }); handleDirty() }}
                                ></input>
                            </div>
                        </div>
                    </>
                }
                {
                    (type === 'Operator' || type === 'LegalEntity') &&
                    <>
                        <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_FULLNAME' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("COMMON_LABEL_FULLNAME").toString()}
                                    value={memberState.fullName}
                                    onChange={e => { setMemberState({ ...memberState, fullName: e.target.value }); handleDirty() }} required
                                ></input>
                            </div>
                        </div>
                    </>

                }

                <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_COUNTRY' ></LabelAndHelp>


                <div className="row d-flex justify-content-center mb-2">
                    <div className="justify-content-center">
                        <CountryDropDown valueChanged={ChangeCountry} inputCountry={isEdit ? memberState.countryIso3Code : ''}></CountryDropDown>
                    </div>
                </div>
                <div className="row d-flex justify-content-center mb-2">
                    <div className="col-6 justify-content-center">
                        <button className="btn btn-green btn-primary btn-block" disabled={submitting || ((type === 'Operator' || type === 'LegalEntity') ? (memberState.fullName.length < 4) : (emailError !== '' || phoneError !== '' || memberState.person!.firstName!.length < 2 || memberState.person!.lastName!.length < 2 || memberState.person!.email!.length < 2))} type="submit"  >
                            <LR localResource="COMMON_LABEL_SAVE"></LR>
                        </button>
                    </div>
                </div>

            </form>
        </>

    );
}


export { MemberForm }