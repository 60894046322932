import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import {  Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import "./cards.css"
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import i18n from '../../i18n';
import HelpIcon from '@mui/icons-material/Help';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { LR } from '../Common/Help/LR';
import { useFetchEvents } from '../../hooks/Events/event';

type Args = {
  userLogged: ApplicationUser
  setHelp: (value: string) => void
}

const EventsCard = ({ userLogged, setHelp }: Args) => {
  const nav = useNavigate();

   // eslint-disable-next-line react-hooks/rules-of-hooks
   const [isListVisible, setIsListVisible] = useState(false);

   const handleToggleList = () => {
     setIsListVisible(!isListVisible);
   };

  const { data: events, status, isSuccess } = useFetchEvents(userLogged.operator?.id!);//globalLoggedUser?.operator?.id!;

  const editEvent = (eventId: string) => {
    nav(`/events/${eventId}/summary`);
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!events) return <MessageAlert message="EVENTS_LABEL_EVENTSNOTFOUND"></MessageAlert>;

  return (
    <>

<Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
      <Card>
        <React.Fragment>
          {/* Header with Title and Arrow */}
          <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='cardTitle' gutterBottom>
              <LR localResource='COMMON_LABEL_EVENTS'></LR>
            </Typography>
            <IconButton className="fa-arrow" onClick={handleToggleList}>
              {!isListVisible && <ArrowForwardIosRoundedIcon />}
              {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </CardContent>

          <div className="list-container" >
            {/* Initial Display */}
            {!isListVisible && (
              <div className='cardCounter locationCardCounter'>
                <Typography variant="h1">{events.length}</Typography>
              </div>
            )}

            {/* List Container */}
            <Collapse in={isListVisible}>
              <List component="nav" aria-label="main mailbox folders">
                {events.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Paper key={index} elevation={3} className="fa-paper-card">
                        <div className="d-flex align-items-center">
                          <div className="fa-name flex-grow-1 fa-name-card">
                            {item.name}
                          </div>
                          <div className="fa-icons">
                            <IconButton onClick={(event) => editEvent(item.id)}>
                              <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                            </IconButton>
                          </div>
                        </div>
                      </Paper>
                    </React.Fragment>
                  );
                })}
              </List>

            </Collapse>
          </div>

          {/* Card Actions */}
          <CardActions className='DashBoardCard'
            disableSpacing
            sx={{
              alignSelf: "stretch",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              p: 0,
            }}>
            <IconButton aria-label="share" className='btn plusButton mr-2' href="/events/add">
              <Tooltip title={i18n.t('EVENT_TITLE_NEWEVENT')}>
                <AddCircleRoundedIcon  sx={{ color: '#00be95' }} />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" href="/events">
              <Tooltip title={i18n.t('EVENT_TITLE_SEARCHEVENTS')}>
                <SearchRoundedIcon  />
              </Tooltip>
            </IconButton>
            <IconButton aria-label="share" onClick={(e) => setHelp('EVENT_MESSAGE_NEWEVENT')}>
              <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                <HelpIcon  />
              </Tooltip>
            </IconButton>
          </CardActions>
        </React.Fragment>
      </Card>
    </Box>
    </>
  );

}

export { EventsCard }
  