import { useEffect, useState } from 'react';

const useDirtyState = (): [boolean, () => void, () => void] => {
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (isDirty) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  const setDirty = () => {
    setIsDirty(true);
  };

  const unsetDirty = () => {
    setIsDirty(false);
  };

  return [isDirty, setDirty, unsetDirty ];
};

export{useDirtyState}