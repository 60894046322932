import { useMutation, useQuery, useQueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import { ProductResponseDto } from "../../types/Product/ProductResponseDto";
import { ProductRuleSummaryResponseDto } from "../../types/Product/ProductRuleSummaryResponseDto";
import { ProductRuleResponseDto } from "../../types/Product/ProductRuleResponseDto";
import { RuleProductTypeResponseDto } from "../../types/Product/RuleProductTypeResponseDto";
import { BusinessRuleResponseDto } from "../../types/Product/BusinessRuleResponseDto";
import { ProductPortfolioResponseDto } from "../../types/Product/ProductPortfolioResponseDto";
import { ProductPortfolioProductInstanceDto } from "../../types/Product/ProductPortfolioProductInstanceDto";
import Problem from "../../types/Problem";
import { ProductProductRuleRequestDto } from "../../types/Product/ProductProductRuleRequestDto";
import { OperationResultDto } from "../../types/Common/OperationResultDto";
import { ProductRuleProductTypeDto } from "../../types/Product/ProductRuleProductTypeDto";
import { PriceDto } from "../../types/Price/PriceDto";
import { PortfolioProductPriceDto } from "../../types/Price/PortfolioProductPriceDto";
import { ProductTypeResponseDto } from "../../types/Product/ProductTypeResponseDto";

const useFetchProducts = (memberId: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["products_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/products/summary?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchProductRules = (memberId: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["productrules_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/summary?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchPricingCataloues = (memberId: string) => {
  return useQuery<SummaryItemDto[], AxiosError>(["pricingportfolio_summary", memberId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/summary?operatormemberid=${memberId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchProductPortfolioById = (catalogueId: string) => {
  return useQuery<ProductPortfolioResponseDto, AxiosError>(["pricingportfolio", catalogueId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchProductPortfolioProducts = (catalogueId: string) => {
  return useQuery<ProductPortfolioProductInstanceDto[], AxiosError>(["pricingportfolio-products", catalogueId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}/products`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}



const useFetchProductById = (productId: string, doFetch: boolean) => {
  return useQuery<ProductResponseDto, AxiosError>(["products", productId], () =>
    axios.get(`${HookConfig.productUrl}/products/${productId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data),
    { enabled: doFetch }
  );
}


const useFetchProductRuleById = (ruleId: string) => {
  return useQuery<ProductRuleResponseDto, AxiosError>(["product-rules", ruleId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}


const useFetchRuleProductTypes = (ruleId: string) => {
  return useQuery<RuleProductTypeResponseDto[], AxiosError>(["rule-product-type", ruleId], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}/product-types`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchProductTypes = () => {
  return useQuery<ProductTypeResponseDto[], AxiosError>(["product-types"], () =>
    axios.get(`${HookConfig.productUrl}/product-types`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchBusinessRulesRule = (ruleId: string, typeName: string) => {
  return useQuery<BusinessRuleResponseDto[], AxiosError>(["business-rule", ruleId, typeName], () =>
    axios.get(`${HookConfig.productUrl}/product-rules/${ruleId}/business-rules?businessRuleTypeName=${typeName}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then((resp) => resp.data)
  );
}

const useFetchCataloguePrices = (catalogueId: string, productId: string) => {
  return useQuery<PriceDto[], AxiosError>(["catalogue-prices", catalogueId, productId], () =>
    axios.get(`${HookConfig.productUrl}/portfolios/${catalogueId}/products/${productId}/prices`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};


const useFetchRulesByProductId = (productId: string, doFetch: boolean) => {
  return useQuery<ProductRuleSummaryResponseDto[], AxiosError>(["products-rules", productId], () =>
    axios.get(`${HookConfig.productUrl}/products/${productId}/product-rules`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ).then(
      (resp) =>
        resp.data),
    { enabled: doFetch }
  );
}


const useManageProductsRules = (callback: (productRuleId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductProductRuleRequestDto>(
    (productRuleRequest) => {
      const method = productRuleRequest.isDelete ? 'delete' : 'post';
      const url = productRuleRequest.isDelete ?
        `${HookConfig.productUrl}/products/${productRuleRequest.productId}/product-rules/${productRuleRequest.productRuleId}` :
        `${HookConfig.productUrl}/products/${productRuleRequest.productId}/product-rules/`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleRequest, config);
    },
    {
      onSuccess: (_, productRule) => {
        callback(productRule.productRuleId, (productRule.isDelete ? '00000000-0000-0000-0000-000000000000' : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRule) => {
        callback(productRule.productRuleId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useManageProductPortfolioProducts = (callback: (productId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductPortfolioProductInstanceDto>(
    (productPortfolioRequest) => {
      const method = productPortfolioRequest.isDelete ? 'delete' : 'post';
      const url = `${HookConfig.productUrl}/portfolios/${productPortfolioRequest.portfolioId}/products/${productPortfolioRequest.productId}` 

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productPortfolioRequest, config);
    },
    {
      onSuccess: (_, productPortfolioRequest) => {
        callback(productPortfolioRequest.productId, (productPortfolioRequest.isDelete ? '00000000-0000-0000-0000-000000000000' : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productPortfolioRequest) => {
        callback(productPortfolioRequest.productId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};



const useManageProductsRulesProductTypes = (callback: (productRuleId: string, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, ProductRuleProductTypeDto>(
    (productRuleProductTypeRequest) => {
      const method = productRuleProductTypeRequest.isDelete ? 'delete' : 'post';
      const url = productRuleProductTypeRequest.isDelete ?
        `${HookConfig.productUrl}/product-rules/${productRuleProductTypeRequest.productRuleId}/product-types/${productRuleProductTypeRequest.productTypeName}` :
        `${HookConfig.productUrl}/product-rules/${productRuleProductTypeRequest.productRuleId}/product-types/`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleProductTypeRequest, config);
    },
    {
      onSuccess: (_, productRuleProductTypeRequest) => {
        callback(productRuleProductTypeRequest.productRuleId, (productRuleProductTypeRequest.isDelete ? '00000000-0000-0000-0000-000000000000' : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRuleProductTypeRequest) => {
        callback(productRuleProductTypeRequest.productRuleId, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useManageProductsRulesBusinessRules = (callback: (item: BusinessRuleResponseDto, responseId: string, operationResult: OperationResultDto) => void) => {

  return useMutation<AxiosResponse, AxiosError, BusinessRuleResponseDto>(
    (productRuleBusinessRule) => {
      const method = productRuleBusinessRule.isDelete ? 'delete' : (productRuleBusinessRule.id === '00000000-0000-0000-0000-000000000000') ? 'post' : 'put';
      const url = productRuleBusinessRule.isDelete ?
        `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/${productRuleBusinessRule.id}` :
        (productRuleBusinessRule.id === '00000000-0000-0000-0000-000000000000') ?
          `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/` :
          `${HookConfig.productUrl}/product-rules/${productRuleBusinessRule.productRuleId}/business-rules/${productRuleBusinessRule.id}`;

      const config = {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      };

      // If the method is 'delete', don't include a body in the request
      return method === 'delete' ?
        axios[method](url, config) :
        axios[method](url, productRuleBusinessRule, config);
    },
    {
      onSuccess: (_, productRuleBusinessRule) => {
        callback(productRuleBusinessRule, (productRuleBusinessRule.isDelete ? '00000000-0000-0000-0000-000000000000' : _.data.id), { isSuccess: true, errorMessage: '' });
      },
      onError: (_, productRuleBusinessRule) => {
        callback(productRuleBusinessRule, '', { isSuccess: false, errorMessage: _.message });
      }
    }
  );
};



const useManageProductRule = (callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductRuleResponseDto>(
    (productRule) => axios[productRule.id === '' ? 'post' : 'put'](
      productRule.id === '' ? `${HookConfig.productUrl}/product-rules/` : `${HookConfig.productUrl}/product-rules/${productRule.id}`,
      productRule,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, productRule) => {

        queryClient.invalidateQueries(["product-rules", productRule.id]);
        callback({ isSuccess: true, errorMessage: '' });
      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useManageProduct = (callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductResponseDto>(
    (product) => axios[product.id === '' ? 'post' : 'put'](
      product.id === '' ? `${HookConfig.productUrl}/products/` : `${HookConfig.productUrl}/products/${product.id}`,
      product,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (response, product) => {
        queryClient.invalidateQueries(["products", product.id]);
        callback({ isSuccess: true, errorMessage: '', data: response.data.id });
      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useManageProductPortfolio = (callback: (operationResult: OperationResultDto) => void) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ProductPortfolioResponseDto>(
    (productPortfolio) => axios[productPortfolio.id === '' ? 'post' : 'put'](
      productPortfolio.id === '' ? `${HookConfig.productUrl}/portfolios/` : `${HookConfig.productUrl}/portfolios/${productPortfolio.id}`,
      productPortfolio,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, productPortfolio) => {

        queryClient.invalidateQueries(["products", productPortfolio.id]);
        callback({ isSuccess: true, errorMessage: '' });


      },
      onError: (_, error) => {
        console.log(error);
        callback({ isSuccess: false, errorMessage: _.message });
      }
    }
  );
};

const useManageProductPortfolioPrices = () => {
  const managePrices = async (
    [items, callback]: [PriceDto[], (operationResult: OperationResultDto) => void]
  ): Promise<AxiosResponse<any, any>[]> => {

    const promises = items.filter(item => item.price !== 0)
      .map(async (item) => {
        const entityKeyValues = item.entityKey.split('~');
        const baseUrl = `${HookConfig.productUrl}/portfolios/${entityKeyValues[0]}/products/${entityKeyValues[1]}/prices`
        const url = item.id === 0 ? `${baseUrl}` : `${baseUrl}/${item.id}`;
        const method = item.id === 0 ? "post" : "put";
        let portfolioPriceItem: PortfolioProductPriceDto = item as PortfolioProductPriceDto;
        portfolioPriceItem.portfolioId = entityKeyValues[0];
        portfolioPriceItem.productId = entityKeyValues[1];

        const response = await axios[method](
          url, portfolioPriceItem,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          }
        );
        return response;
      });
    const responses = await Promise.all(promises);
    return responses;
  };


  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [prices: PriceDto[], callback: (operationResult: OperationResultDto) => void]>(
      managePrices, {
      onSuccess: (responses, [items, callback]) => {
        callback({ isSuccess: true, errorMessage: '' });
      },
      onError: (_, [items, callback]) => {
        callback({ isSuccess: false, errorMessage: _.message });
      }
    });

  return mutation;
};

export {
  useFetchProducts,
  useFetchProductById,
  useFetchRulesByProductId,
  useFetchPricingCataloues,
  useFetchProductRules,
  useFetchProductRuleById,
  useFetchProductTypes,
  useFetchRuleProductTypes,
  useFetchBusinessRulesRule,
  useFetchProductPortfolioById,
  useFetchProductPortfolioProducts,
  useManageProductPortfolio,
  useManageProductsRules,
  useManageProduct,
  useManageProductsRulesProductTypes,
  useManageProductsRulesBusinessRules,
  useManageProductRule,
  useFetchCataloguePrices,
  useManageProductPortfolioProducts,
  useManageProductPortfolioPrices
}
