import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import i18n from "../../i18n";
import { SectionHead } from "../Common/Help/SectionHead";
import { useCreateMembership, useFetchMembershipSummaryById, useUpdateMembership, useFetchMembershipById, useFetchMembershipUnavailabilities } from "../../hooks/Memberships/membership";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { useEffect, useState } from "react";
import { GenerateExternalReference } from "../../functions/utils/helper";
import Select, { SingleValue } from "react-select";
import { LR } from "../Common/Help/LR";
import { pontCurrencies } from "../../data/en/pontCurrencies";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';
import FormattedNumberInput from "../Common/Prices/FormatNumberInput";
import { PriceDto } from "../../types/Price/PriceDto";
import { LocationResponseDto } from "../../types/Location/LocationResponseDto";
import { MembershipResponseDto } from "../../types/Membership/MembershipResponseDto";
import { MembershipPriceDto } from "../../types/Membership/MembershipPriceDto";
import { InventoryTypeSelectOption } from "../../types/Inventory/InventoryTypeDto";
import { useFetchInventoryTypes } from "../../hooks/Inventory/inventory";
import { InventoryVariantDto, InventoryVariantSelectOption } from "../../types/Inventory/InventoryVariantDto";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { HookConfig } from "../../config/HookConfig";
import axios from "axios";
import { LocationSelector } from "../Location/LocationSelector";
import { InventoryUnavailabilities } from "../Inventory/InventoryUnavailabilities";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import Tooltip from "@mui/material/Tooltip/Tooltip";

type Args =
    {
        userLogged: ApplicationUser,
        isEdit: boolean
    }

const MembershipSummary = ({ userLogged, isEdit }: Args) => {

    const nav = useNavigate();
    let summaryName: string = '';
    const queryStringParams = useParams();
    if (isEdit && !queryStringParams.id) throw Error("Membership id needed");

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "MembershipFeaturesSummary":
                nav(`/memberships/${queryStringParams.id}/features`);
                break;
            case "MembershipDescriptionSummary":
                nav(`/memberships/${queryStringParams.id}/description`);
                break;
            case "FeatureSummary":
                nav(`/memberships/${queryStringParams.id}/features`);
                break;
            case "MembershipImagesSummary":
            case "MembershipDocsSummary":
                nav(`/memberships/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }

    const updatedCallback = () => {
        nav(`/memberships/${queryStringParams.id}/summary`);
    }

    const createdCallback = (membershipId: string) => {
        nav(`/memberships/${membershipId}/summary`);
    }

    const homeCallBack = () => {
        nav(`/`);
    }

    const createPriceItem = (membershipPriceDto: MembershipPriceDto, membershipId: string) => {
        let priceDto: PriceDto = {
            id: membershipPriceDto?.id ?? 0,
            entityName: 'Membership',
            entityKey: membershipId ?? '',
            unitName: 'Ticket',
            fromDate: membershipPriceDto?.fromDate ?? Date,
            toDate: membershipPriceDto?.toDate ?? Date,
            currencyCode: membershipPriceDto?.currencyCode ?? 'GBP',
            price: membershipPriceDto?.price ?? 0
        }
        return priceDto;
    }

    const defaultMembership: MembershipResponseDto = {
        id: queryStringParams.id!,
        name: '',
        inventoryTypeName: '',
        locationId: '00000000-0000-0000-0000-000000000000',
        inventoryVariantId: '00000000-0000-0000-0000-000000000000',
        quantity: 0,
        quantityLimitPerOrder: 0,
        externalReference: '',
        isDisabled: false,
        internalIdentifier: '',
        fromDate: new Date(),
        toDate: new Date(),
        prices: [],
        soldQuantity: 0,
        locationName: '',
        shortDescription: '',
        description: '',
    };

    const defaultSummaries: SummaryItemDto[] = [];
    const { data: membership, status: membershipStatus, isSuccess: membershipIsSuccess } = useFetchMembershipById(queryStringParams.id!, isEdit);
    const { data, status } = useFetchMembershipSummaryById(queryStringParams.id!, summaryName!, isEdit);
    const [membershipState, setMembershipState] = useState(defaultMembership);
    const [summariesState, setMembershipSummaries] = useState(defaultSummaries);
    const [selectedPrice, setSelectedPrice] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState<{ value: string, label: string, countryCode: string }>({ value: 'GBP', label: 'British Pound Sterling - £', countryCode: 'GBR' });
    const [priceItemState, setPriceItemState] = useState<PriceDto>(createPriceItem(null!, null!));
    const { mutate } = useUpdateMembership(updatedCallback);
    const create = useCreateMembership(createdCallback);
    const { data: unavailabilityData } = useFetchMembershipUnavailabilities(queryStringParams.id!, isEdit);
    const [unavailabilityState, setUnavailability] = useState<InventoryUnavailabilityDto[]>([])

    useEffect(() => {
        if (isEdit) {
            if (membershipIsSuccess && membership) {
                setMembershipState(membership);
                setSelectedPrice(membership?.prices[0]?.price ?? 0);
                var priceCurrency = pontCurrencies.find(x => x.value === membership?.prices[0]?.currencyCode ?? 'GBP')
                setSelectedCurrency(priceCurrency!);
                setPriceItemState(createPriceItem(membership?.prices[0], membership.id));
                if (data) {
                    setMembershipSummaries(data)
                }
            }
        }
    }, [status, membershipStatus]);

    useEffect(() => {
        if (unavailabilityData) {
            setUnavailability(unavailabilityData);
        }
    }, [unavailabilityData])

    const handleCurrencyChange = (selectedOption: any) => {
        setSelectedCurrency(selectedOption);
        setPriceItemState({ ...priceItemState!, currencyCode: selectedOption });
    }

    const generateUniqueCode = () => {
        if (membershipState.externalReference === '' || membershipState.externalReference === null) {
            const formattedCode = GenerateExternalReference(membershipState.name ?? '');
            setMembershipState({ ...membershipState, externalReference: formattedCode });
        }
    };

    const confirmLocation = (location: LocationResponseDto) => {
        setMembershipState({ ...membershipState, locationId: location.id });
        fetchVariantData(location.id);
    }

    const onPriceItemChange = (value: string) => {
        setPriceItemState({ ...priceItemState!, price: Number(value) });
    }

    const addMembershipPrice = () => {
        let price: MembershipPriceDto = {
            id: 0,
            unitName: 'Day',
            fromDate: membershipState.fromDate,
            toDate: membershipState.toDate,
            currencyCode: priceItemState.currencyCode,
            price: priceItemState.price
        }
        membershipState.prices.push(price);
    }

    const requiredPopulated = () => {
        return !!membershipState.name &&
            !!membershipState.shortDescription &&
            !!membershipState.locationId && membershipState.toDate > membershipState.fromDate;
    }

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault();
            membershipState.inventoryTypeName = selectedInventoryType?.value!;
            membershipState.inventoryVariantId = selectedVariant?.value!;
            let ticketPrice = membershipState.prices?.find(x => x.unitName === 'Ticket');
            if (ticketPrice) {
                ticketPrice.price = priceItemState.price;
                ticketPrice.currencyCode = priceItemState.currencyCode;
                ticketPrice.fromDate = membershipState.fromDate;
                ticketPrice.toDate = membershipState.toDate;
            }
            else {
                addMembershipPrice();
            }

            if (isEdit) {
                mutate(membershipState);
            }
            else {
                create.mutate(membershipState);
            }
        } catch (err: any) {
            console.error(err);
        }
    }
    const [selectedInventoryType, setSelectedInventoryType] = useState<InventoryTypeSelectOption>();
    const [inventoryTypes, setInventoryTypes] = useState<InventoryTypeSelectOption[]>([]);
    const { data: retrievedTypes } = useFetchInventoryTypes();

    const fetchVariantData = async (locationId: string) => {
        const response = await axios.get(`${HookConfig.inventoryUrl}/inventory-variants/${locationId}/${selectedInventoryType?.value ?? 'DayPass'}`,
            {
                withCredentials: true,
                headers: operatorDefaultHeaders()
            }
        );
        const variants: InventoryVariantDto[] = response.data;
        const variantOptions = variants.filter(x => x.id !== '00000000-0000-0000-0000-000000000000').map((variant) => ({
            label: variant.value,
            value: variant.id
        }));
        setInventoryVariants(variantOptions);
    }

    useEffect(() => {
        if (retrievedTypes) {
            const selectOptions = retrievedTypes.map((inventoryType) => ({
                label: i18n.t(inventoryType.label),
                value: inventoryType.name,
                quantityVisible: inventoryType.quantityVisible,
                unitName: inventoryType.unitName,
            }));

            setInventoryTypes(selectOptions);
            let dayPassOption = selectOptions.find(x => x.value === 'DayPass');
            setSelectedInventoryType(dayPassOption);
            if (membership) {
                let type = selectOptions.find(x => x.value === membership.inventoryTypeName) ?? dayPassOption;
                setSelectedInventoryType(type);
            }
        }
    }, [retrievedTypes, membership]);

    const handleDropdownChange = (value: SingleValue<InventoryTypeSelectOption>) => {
        var selectedType = inventoryTypes.find(x => x.value === value?.value);
        setSelectedInventoryType(selectedType);
    };

    const handleDropdownChangeVariant = (value: SingleValue<{ value: string, label: string | undefined }>) => {
        const selectedVariant = inventoryVariants.find(x => x.value === value?.value);

        if (selectedVariant) {
            setSelectedVariant(selectedVariant);
        }
    };
    const [inventoryVariants, setInventoryVariants] = useState<InventoryVariantSelectOption[]>([]);
    const [selectedVariant, setSelectedVariant] = useState<InventoryVariantSelectOption>();

    useEffect(() => {
        let selectedVariant = inventoryVariants.find(x => x.value === membership?.inventoryVariantId);
        setSelectedVariant(selectedVariant ?? inventoryVariants[0]);
    }, [inventoryVariants]);

    return (
        <>
            <form onSubmit={onFormSubmit} >
                {!queryStringParams.summaryName &&
                    <SectionHead linkCallBack={homeCallBack}
                        ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                        name={isEdit ? `${membershipState.name} (${membershipState.externalReference})` : ''}
                        title={i18n.t(!isEdit ? 'MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGEADD' : 'MEMBERSHIP_SUMMARY_MEMBERSHIPMAINPAGEEDIT'!)}
                        description={i18n.t('COMMON_SUMMARY_MEMBERSHIPMANAGEMENT')} />
                }

                <div className="row d-flex justify-content-center">
                    <div className="col-md-5 col-11 justify-content-center">
                        <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_NAME' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_NAME").toString()}
                                    value={membershipState.name}
                                    onChange={e => { setMembershipState({ ...membershipState, name: e.target.value }); }} onBlur={() => generateUniqueCode()} required></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_DESCRIPTION' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_DESCRIPTION").toString()}
                                    value={membershipState.shortDescription}
                                    onChange={e => { setMembershipState({ ...membershipState, shortDescription: e.target.value }); }} required></input>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_LOCATION'></LabelAndHelp>
                        <LocationSelector locationId={membershipState.locationId!}
                            placeholder="MEMBERSHIP_LABEL_LOCATION"
                            operatorId={userLogged.operator?.id!}
                            handleConfirm={confirmLocation} />

                        <LabelAndHelp mandatory={true} fullscreen={true} message='INVENTORY_LABEL_TYPE' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">

                                <Select value={selectedInventoryType} options={inventoryTypes} className="form-control fa-form fa-dropdown-container disabled-content" classNamePrefix="fa-dropdown"
                                    onChange={(e) => handleDropdownChange(e)} placeholder={i18n.t('INVENTORY_LABEL_TYPE')}></Select>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_VARIANT' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <Select value={selectedVariant} options={inventoryVariants} className="form-control fa-form fa-dropdown-container" classNamePrefix="fa-dropdown"
                                    onChange={(e) => handleDropdownChangeVariant(e)} placeholder={i18n.t('INVENTORY_LABEL_VARIANT')}></Select>
                            </div>
                        </div>

                        <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_IDENTIFIER' ></LabelAndHelp>
                        <div className="row d-flex justify-content-center mb-2">
                            <div className="justify-content-center">
                                <input
                                    type="text"
                                    className="form-control fa-form"
                                    placeholder={i18n.t("MEMBERSHIP_LABEL_IDENTIFIER").toString()}
                                    value={membershipState.externalReference}
                                    onChange={e => { setMembershipState({ ...membershipState, externalReference: e.target.value }); }} required></input>
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center  mb-2">
                            <div className="col-md-6">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_QUANTITY'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="quantity"
                                    min={0}
                                    value={membershipState.quantity}
                                    placeholder={i18n.t('MEMBERSHIP_LABEL_QUANTITY')!}
                                    className="form-control fa-form"
                                    onChange={(e) => { setMembershipState({ ...membershipState, quantity: Number(e.target.value) }); }}
                                />
                            </div>

                            <div className="col-md-3">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_LIMIT'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="limit"
                                    min={0}
                                    max={membershipState.quantity}
                                    value={membershipState.quantityLimitPerOrder}
                                    placeholder={i18n.t('MEMBERSHIP_LABEL_LIMIT')!}
                                    className="form-control fa-form"
                                    onChange={(e) => { setMembershipState({ ...membershipState, quantityLimitPerOrder: Number(e.target.value) }); }}
                                />
                            </div>
                            <div className="col-md-3">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='MEMBERSHIP_LABEL_SOLD'></LabelAndHelp>
                                <input
                                    type="number"
                                    id="sold"
                                    disabled
                                    min={0}
                                    value={membershipState.soldQuantity}
                                    placeholder={i18n.t('MEMBERSHIP_LABEL_SOLD')!}
                                    className="form-control fa-form"
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_DAILYPRICE' ></LabelAndHelp>
                                <FormattedNumberInput
                                    allowEmpty={false}
                                    value={selectedPrice}
                                    onChange={(item, value) => onPriceItemChange(value)}
                                    currencyValue={selectedCurrency!.value}
                                    item={priceItemState!}></FormattedNumberInput>
                            </div>

                            <div className="col-md-6">
                                <LabelAndHelp mandatory={true} fullscreen={true} message='COMMON_LABEL_CURRENCY' ></LabelAndHelp>
                                <Select
                                    value={selectedCurrency}
                                    className="form-control fa-form fa-dropdown-container disabled-content" classNamePrefix="fa-dropdown"
                                    options={pontCurrencies}
                                    onChange={handleCurrencyChange}
                                    placeholder={i18n.t("COMMON_LABEL_SELECTCURRENCY").toString()}
                                />
                            </div>
                        </div>

                        <div className="row d-flex justify-content-center mt-4 mb-3">
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t("MEMBERSHIP_LABEL_FROMDATE").toString()}
                                    className="fa-datepicker"
                                    value={dayjs(membershipState.fromDate)}
                                    onChange={(newValue) => { setMembershipState({ ...membershipState, fromDate: newValue?.toDate()! }); }}
                                />
                            </div>
                            <div className="col-md-6 col-11">
                                <DatePicker
                                    label={i18n.t("MEMBERSHIP_LABEL_TODATE").toString()}
                                    className="fa-datepicker"
                                    value={dayjs(membershipState.toDate)}
                                    onChange={(newValue) => { setMembershipState({ ...membershipState, toDate: newValue?.toDate()! }); }}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`row d-flex justify-content-center`}>
                    <div className="col-md-5 col-11 justify-content-center">
                        {isEdit && <InventoryUnavailabilities items={unavailabilityState} locationId={membershipState.locationId}></InventoryUnavailabilities>}
                        <SummaryItemCollection linkCallBack={linkCallBackCollection} items={summariesState}></SummaryItemCollection>
                    </div>
                </div>

                <div className="row d-flex justify-content-center mt-3">
                    <div className="col-md-2 col-6 justify-content-center">
                        <Tooltip title={!requiredPopulated() ? i18n.t('MEMBERSHIP_REQUIRED_TOOLTIP') : ''}>
                            <span>
                                <button type="submit" disabled={!requiredPopulated()} style={!requiredPopulated() ? { pointerEvents: 'none' } : {}} className="btn btn-green btn-primary btn-block">
                                    <LR localResource="COMMON_LABEL_SAVE"></LR>
                                </button>
                            </span>
                        </Tooltip>
                    </div>
                </div>
            </form >

        </>
    );
};

export { MembershipSummary }