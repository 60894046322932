import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryItemCollection } from "../Common/SummaryItems/SummaryItemCollection";
import ApiStatus from "../Common/Errors/ApiStatus";
import { SummaryItemDto } from "../../types/Common/SummaryItemDto";
import i18n from "../../i18n";
import { useFetchLocationById, useFetchLocationSummaryById } from "../../hooks/Location/location";
import { getSummaryLabel } from "../../hooks/Common/labels";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationSummary = ({ userLogged }: Args) => {

    const nav = useNavigate();
    let summaryName = null;
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");

    if (queryStringParams.summaryName)
        summaryName = queryStringParams.summaryName;

    const titleLabel = getSummaryLabel(summaryName!);


    const linkCallBackCollection = (item: SummaryItemDto) => {
        switch (item.name) {
            case "LocationSummary":
                nav(`/locations/${queryStringParams.id}/locationdetails`);
                break;
            case "DescriptionSummary":
                nav(`/locations/${queryStringParams.id}/description`);
                break;
            case "CommunicationSummary":
                nav(`/locations/${queryStringParams.id}/summary/communications`);
                break;
            case "FeatureSummary":
                nav(`/locations/${queryStringParams.id}/features`);
                break;
            case "DigitalAssetSummary":
                nav(`/locations/${queryStringParams.id}/summary/digital-assets`);
                break;
            case "FloorPlansDocumentsSummary":
            case "ImagesSummary":
            case "VideosVirtualToursSummary":
                nav(`/locations/${queryStringParams.id}/digital-assets/${item.uri.substring(item.uri.indexOf('group=') + 6)}`);
                break;
            case "PhoneSummary":
                nav(`/locations/${queryStringParams.id}/communications/phone`);
                break;
            case "LocationInventorySummary":
                nav(`/locations/${queryStringParams.id}/inventories`);
                break;
            case "EmailSummary":
                nav(`/locations/${queryStringParams.id}/communications/email`);
                break;
            case "SocialSummary":
                nav(`/locations/${queryStringParams.id}/communications/social`);
                break;
            case "WebSummary":
                nav(`/locations/${queryStringParams.id}/communications/web`);
                break;
            case "LocationConfigurationSummary":
                nav(`/locations/${queryStringParams.id}/configuration`);
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }
    }


    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/summary`);
    }

    const homeCallBack = () => {
        nav(`/`);
    }


    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchLocationSummaryById(queryStringParams.id!, summaryName!);

    if ((!isSuccess || !locationIsSuccess) && (status !== 'success') && (locationStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !location) return <ApiStatus status={status} />;

    return (
        <>
            {!queryStringParams.summaryName &&
                <SectionHead linkCallBack={homeCallBack} 
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('LOCATION_SUMMARY_LOCATIONMAINPAGE'!)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />                
            }

            {queryStringParams.summaryName &&


                <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!}
                    name={`${location.name} (${location.externalReference})`}
                    title={i18n.t(titleLabel!)}
                    description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

            }


            <div className={`row d-flex justify-content-center`}>
                <div className="col-md-5 col-11 justify-content-center">
                    <SummaryItemCollection linkCallBack={linkCallBackCollection} items={data}></SummaryItemCollection>
                </div>
            </div>
        </>
    );
};


export { LocationSummary }