import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryIcon, SummaryItem } from "../Common/SummaryItems/SummaryItem";
import { useFetchMemberById } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import i18n from "../../i18n";
import { NotificationItemCollection } from "./NotificationItemCollection";
import { TitleWithIcon } from "../Common/Help/TitleWithIcon";
import { useFetchGroupedNotificationList } from "../../hooks/Notification/notification";

type Args =
    {
        userLogged: ApplicationUser
    }

const Notifications = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Operator id needed");

    const linkCallBack = () => {
        nav(`/operators/${queryStringParams.id}/summary`);
    }
   
    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchGroupedNotificationList(userLogged.memberId);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    return (
        <>
            <TitleWithIcon label="COMMON_SUMMARY_NOTIFICATIONS"/>
            <div className="mt-1 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <NotificationItemCollection items={data}></NotificationItemCollection>
                </div>
            </div>
        </>
    );
};


export { Notifications }