import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { getSummaryLabel } from "../../../hooks/Common/labels";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { SectionHead } from "../../Common/Help/SectionHead";
import { FileuploadContainer } from "../../Common/Upload/FileUploadContainer";
import i18n from "../../../i18n";
import { useFetchPostById } from "../../../hooks/Posts/posts";
import { useFetchDigitalAssetsRequirements } from "../../../hooks/Common/fileUpload";

type Args =
    {
        userLogged: ApplicationUser
    }

const PostDigitalAssets = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Application id needed");
    if (!queryStringParams.postId) throw Error("Application id needed");
    if (!queryStringParams.groupName) throw Error("Digital Asset Type id needed");
    const postId = queryStringParams.postId;
    const applicationId = queryStringParams.id;


    const titleLabel = getSummaryLabel(queryStringParams.groupName!);

    const linkCallBack = () => {
        nav(`/application/${applicationId}/configuration/posts/${postId}`);
    }

    const { data: post, status: postStatus, isSuccess: postIsSuccess } = useFetchPostById(queryStringParams.postId!)
    const { data, status, isSuccess } = useFetchDigitalAssetsRequirements('post', queryStringParams.postId!, queryStringParams.groupName!);

    if ((!isSuccess || !postIsSuccess) && (status !== 'success') && (postStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={postStatus} />
                </div>
            </div>
        </>
    )

    if (!post || !data) return <ApiStatus status={status} />;

    return (
        <>
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("APPLICATION_LABEL_POSTS")!}
                name={`${post.title}`}
                title={i18n.t(titleLabel!)}
                description={i18n.t('APPLICATION_LABEL_POSTS')} />

            <FileuploadContainer items={data} groupname={queryStringParams.groupName!}></FileuploadContainer>
        </>
    );
};


export { PostDigitalAssets }