import { useNavigate, useParams } from "react-router";
import {  useFetchLocationById } from "../../hooks/Location/location";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import ApiStatus from "../Common/Errors/ApiStatus";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { useFetchInventoryById, useFetchInventoryAllocationById, useFetchInventoryAllocationPrices,  useManageInventoryAllocationPrices,  useManageAllocationInventory } from "../../hooks/Inventory/inventory";
import {  useState } from "react";
import { SaveSnackBar } from "../Common/SaveSnackBar";
import { InventoryAllocationDto } from "../../types/Inventory/InventoryAllocationDto";
import { AllocationForm } from "./AllocationForm";
import { PriceDto } from "../../types/Price/PriceDto";
import { SectionHead } from "../Common/Help/SectionHead";
import i18n from "../../i18n";

type Args =
    {
        userLogged: ApplicationUser
    }

const AllocationEdit = ({ userLogged }: Args) => {
    const queryStringParams = useParams();
    const nav = useNavigate();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.inventoryId) throw Error("Inventory id needed");
    if (!queryStringParams.inventoryAllocationId) throw Error("Inventory allocation id needed");
    
    const successPrices = () => {        
        setSnackbarOpen(true);
    }

    const successInventoryAllocation = (inventoryAllocation: InventoryAllocationDto) => {
        setSnackbarOpen(true);
        nav(`/locations/${queryStringParams.id!}/inventories/${queryStringParams.inventoryId!}`)
    }


    const manageInventoryAllocationMutation = useManageAllocationInventory(successInventoryAllocation, true);
    const manageInventoryAllocationPricesMutation = useManageInventoryAllocationPrices();
    const [snackbarOpen, setSnackbarOpen] = useState(false);    
    const { data: inventory, status: inventoryStatus, isSuccess: inventoryIsSuccess } = useFetchInventoryById( queryStringParams.inventoryId!);
    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    const { data: prices, status: pricesStatus, isSuccess: pricesIsSuccess } = useFetchInventoryAllocationPrices(queryStringParams.inventoryId!, queryStringParams.inventoryAllocationId!)
    const { data, status , isSuccess } = useFetchInventoryAllocationById(queryStringParams.inventoryId!, queryStringParams.inventoryAllocationId!)

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar

    const handleSaving = (inventoryAllocation : InventoryAllocationDto, inventoryAllocationPrices?: PriceDto[]) => 
    {
        manageInventoryAllocationMutation.mutate(inventoryAllocation);
        manageInventoryAllocationPricesMutation.mutate(['allocation', inventoryAllocationPrices!, successPrices ])
    }



    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/inventories/${queryStringParams.inventoryId!}`);
    }

    if ((!inventoryIsSuccess) || (!locationIsSuccess) || (!isSuccess) || (!pricesIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={inventoryStatus} />
                </div>
            </div>
        </>
    )

    if (!inventory || !location || !data || !prices) return <ApiStatus status={inventoryStatus} />;

    return (
        <>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>


            <SectionHead linkCallBack={linkCallBack} ctaText={inventory.name}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t('INVENTORY_LABEL_EDITALLOCATION')}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />

          
            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <AllocationForm inventoryName={`for ${inventory.name}`} countryIsoCode3={location.countryIso3Code} inventoryAllocationPrices={prices} locationId={location.id!} inventoryAllocation={data!} isEdit={true} submitted={handleSaving}></AllocationForm>
                </div>
            </div>

            {(manageInventoryAllocationMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={manageInventoryAllocationMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { AllocationEdit }