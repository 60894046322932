import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { AddressDto } from "../../types/Common/AddressDto";
import CountryDropDown from "../Common/CountryDropDown";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { SummaryItemDto } from '../../types/Common/SummaryItemDto';
import { useDirtyState } from '../../hooks/Common/dirty';
import { ConfirmationChangesDialog } from '../Common/ConfirmationChangesDialog';
import { useFetchLocationById, useUpdateLocation } from '../../hooks/Location/location';
import { LocationResponseDto } from '../../types/Location/LocationResponseDto';
import { ParentMemberSelector } from '../Member/ParentMemberSelector';
import { MemberResponseDto } from '../../types/Member/MemberResponseDto';
import AddressContainer from '../Common/Address/AddressContainer';
import { SaveSnackBar } from '../Common/SaveSnackBar';
import { LabelAndHelp } from '../Common/Help/LabelAndHelp';
import { HookConfig } from '../../config/HookConfig';
import { operatorDefaultHeaders } from '../../functions/api/api';

import { SectionHead } from '../Common/Help/SectionHead';

type Args =
    {
        userLogged: ApplicationUser
    }



const LocationDetails = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const params = useParams();

    if (!params.id) throw Error("Location id needed");

    //#region Empty Entities
    const emptyLocationResponseDto: LocationResponseDto = {
        id: '',
        internalIdentifier: '',
        externalReference: '',
        operatorMemberId: '00000000-0000-0000-0000-000000000000',
        legalEntityMemberId: '00000000-0000-0000-0000-000000000000',
        name: '',
        countryIso3Code: '',
        summaries: [],
        shortDescription: '',
        longDescription: '',
        brandName: ''
    };

    const emptyAddressDTO: AddressDto = {

        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Location',
        entityKey: '',
        entityName: '',
        city: '',
        area: ''
    }

    let addressPassed: AddressDto = {
        line1: '',
        countryIso3Code: '',
        languageCode: i18n.language,
        addressTypeName: 'Location',
        entityKey: '',
        entityName: 'Location',
        city: '',
        area: '',
    };

    //#endregion

    const success = () => {
        setSnackbarOpen(true);
    }

    const { data: location, status, isSuccess } = useFetchLocationById(params.id)
    const [useLocation, setUseLocation] = useState<LocationResponseDto>(emptyLocationResponseDto);
    const [useAddress, setUseAddress] = useState<AddressDto>(emptyAddressDTO);
    const [useCountry, setUseCountry] = useState<string>('');

    const { mutate } = useUpdateLocation(success);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    //#region snackbar

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };
    //#endregion snackbar


    function OperatorFetch(): Promise<any> {
        return fetch(`${HookConfig.memberUrl}/${useLocation.operatorMemberId}`,
            { credentials: "include", headers: operatorDefaultHeaders() })
            .then((response) => response.json());
    }


    const copyAddressFromOperator = async () => {
        try {
            const fetchedData: MemberResponseDto = await OperatorFetch(); // Call the custom hook

            if (fetchedData && fetchedData.address) {
                addressPassed.entityKey = location?.id!
                addressPassed.entityName = "location"
                addressPassed.line1 = fetchedData.address.line1;
                addressPassed.line2 = fetchedData.address.line2;
                addressPassed.line3 = fetchedData.address.line3;
                addressPassed.city = fetchedData.address.city;
                addressPassed.area = fetchedData.address.area;
                addressPassed.countryIso3Code = fetchedData.address.countryIso3Code;
                addressPassed.languageCode = i18n.language;
                addressPassed.addressTypeName = 'Location';
                addressPassed.latitude = fetchedData.address.latitude;
                addressPassed.longitude = fetchedData.address.longitude;
                addressPassed.zip = fetchedData.address.zip;

                location!.address = addressPassed
                setUseAddress(addressPassed);
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        if (isSuccess && location) {

            setUseLocation(location);
            setUseCountry(location.countryIso3Code);

            if (!location.address) {
                addressPassed.countryIso3Code = location.countryIso3Code;
                addressPassed.entityKey = location.id;
                setUseAddress(addressPassed);
                setIsCollapseOpen(true);
            } else {
                setUseAddress(location.address);
                setIsCollapseOpen(false);
            }


        }
    }, [isSuccess, location]);

    //#region Navigation
    const linkCallBackCollection = (item: SummaryItemDto) => {

        let url = '';
        switch (item.name) {
            case "PhoneSummary":
                url = `/locations/${params.id}/communications/phone`;
                break;
            case "EmailSummary":
                url = `/locations/${params.id}/communications/email`;
                break;
            case "SocialSummary":
                url = `/locations/${params.id}/communications/social`;
                break;
            case "WebSummary":
                url = `/locations/${params.id}/communications/web`;
                break;
            default:
                alert(`Item ${item.name} does not have a link yet`);
                break;
        }

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    }

    const linkCallBack = () => {
        let url = `/locations/${location!.id}/summary`;

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    }


    //#endregion

    //#region changeState
    const setAddress = (value: AddressDto) => {
        if (value !== null && value !== undefined) {
            useAddress.id = value.id;
            useAddress.latitude = value.latitude;
            useAddress.longitude = value.longitude;
            useAddress.line1 = value.line1;
            useAddress.line2 = value.line2;
            useAddress.line3 = value.line3;
            useAddress.area = value.area;
            useAddress.city = value.city;
            useAddress.zip = value.zip;
            useAddress.countryIso3Code = value.countryIso3Code;
            setUseAddress({ ...useAddress, line1: useAddress.line1 });
        };
    }

    const changeCountry = (value: string) => {
        setUseCountry(value);
        setUseAddress({ ...useAddress, countryIso3Code: value });
        setUseLocation({ ...useLocation, countryIso3Code: value })
    }
    //#endregion

    //#region Dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const handleConfirm = (operator: MemberResponseDto) => {

        if (useLocation.name) {
            setUseLocation({ ...useLocation, legalEntityMemberId: operator.id });
            handleDirty();
        }

    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }

    //#endregion

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        try {
            setShowConfirmation(false);
            unsetDirty();
            useAddress.entityKey = useLocation.id;
            useAddress.entityName = "Location";
            useLocation.address = useAddress;
            if (useAddress.countryIso3Code !== useLocation.countryIso3Code) {
                useLocation.countryIso3Code = useAddress.countryIso3Code;
            }
            mutate(useLocation);
            e.preventDefault();

        } catch (err: any) {
            console.error(err);
        }
    }


    if (!isSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!location) return <MessageAlert message="MEMBER_LABEL_MEMBERNOTFOUND" params={{ param0: params.id }}></MessageAlert>;

    return (
        <form onSubmit={onFormSubmit}>
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen}></SaveSnackBar>

            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
 

            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t("LOCATION_SUMMARY_LOCATIONMAINPAGE")!} name={`${location.name} (${location.externalReference})`} title={i18n.t('COMMON_SUMMARY_LOCATIONDETAILS')} description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')}/>

            <LabelAndHelp mandatory={true} message='LOCATION_LABEL_NAME'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center">
                    <input type="text"
                        value={useLocation.name}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseLocation({ ...useLocation, name: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("LOCATION_LABEL_NAME").toString()}
                        required>
                    </input>
                </div>
            </div>


            <LabelAndHelp mandatory={true} message='LOCATION_LABEL_SHORTDESCRIPTION' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="text"
                        maxLength={500}
                        className="form-control fa-form"
                        placeholder={i18n.t("LOCATION_LABEL_SHORTDESCRIPTION").toString()}
                        onChange={(e) => {
                            setUseLocation({ ...useLocation, shortDescription: e.target.value });
                            handleDirty();
                        }}
                        value={useLocation.shortDescription}>
                    </input>
                </div>
            </div>

            <LabelAndHelp mandatory={true} message='LOCATION_LABEL_BRANDNAME' ></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="text"
                        maxLength={100}
                        className="form-control fa-form"
                        placeholder={i18n.t("LOCATION_LABEL_BRANDNAME").toString()}
                        onChange={(e) => {
                            setUseLocation({ ...useLocation, brandName: e.target.value });
                            handleDirty();
                        }}
                        value={useLocation.brandName}>
                    </input>
                </div>
            </div>



            <LabelAndHelp mandatory={false} message='LOCATION_LABEL_LEGALENTITY' helpMessage='COMMON_LABEL_HELP_SELECTLEGALENTITY'></LabelAndHelp>
            <div className="row d-flex justify-content-center mb-2">
                <div className="col-md-5 col-11 justify-content-center">
                    <ParentMemberSelector flagType={512} legalEntityMemberId={useLocation.legalEntityMemberId} handleConfirm={handleConfirm} operatorId={useLocation.operatorMemberId}></ParentMemberSelector>
                </div>
            </div>


            <LabelAndHelp mandatory={false} message='LOCATION_LABEL_INTERNALREFERENCE' helpMessage='LOCATION_LABEL_HELP_INTERNALREFERENCE'></LabelAndHelp>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center" style={{ display: 'flex', alignItems: 'center' }}>
                    <input type="text"
                        maxLength={100}
                        value={useLocation.internalIdentifier}
                        className="form-control fa-form"
                        onChange={(e) => {
                            setUseLocation({ ...useLocation, internalIdentifier: e.target.value });
                            handleDirty();
                        }}
                        placeholder={i18n.t("LOCATION_LABEL_INTERNALREFERENCE").toString()}>
                    </input>

                </div>
            </div>



            <LabelAndHelp mandatory={true} message='COMMON_LABEL_COUNTRY' ></LabelAndHelp>

            <div className="row d-flex justify-content-center mb-3">
                <div className="col-md-5 col-11 justify-content-center">
                    <CountryDropDown valueChanged={changeCountry} inputCountry={useLocation.countryIso3Code}></CountryDropDown>
                </div>
            </div>

            <AddressContainer copyaddressCallback={copyAddressFromOperator} source={useLocation.operatorMemberId ? 'location' : ''} collapseStatus={isCollapseOpen} collapseCallback={() => setIsCollapseOpen(!isCollapseOpen)} address={useAddress} handleDirty={handleDirty} callback={setAddress} country={useLocation.countryIso3Code} originalAddress={location.address!}></AddressContainer>

            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block"
                        disabled={!isDirty ||
                            !useAddress.line1 ||
                            !useAddress.city ||
                            !useAddress.countryIso3Code ||
                            !useAddress.zip ||
                            useAddress.line1.length < 3 ||
                            useAddress.city.length < 3 ||
                            useAddress.countryIso3Code.length < 3 ||
                            useAddress.zip.length < 3 ||
                            useLocation.name.length < 3 ||
                            !useLocation.name
                        }
                    >
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
        </form>
    );
};


export { LocationDetails }