import React, { useEffect, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { ProgressBar, Button, Collapse } from 'react-bootstrap';
import { Card, CardActions, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { LR } from '../Help/LR';
import MessageAlert, { AlertStyle } from '../Errors/MessageAlert';
import { useQuery, useQueryClient } from 'react-query';
import './FileUploader.css'
import FileIcon from './FileIcon';
import i18n from '../../../i18n';
import { DigitalAssetDto } from '../../../types/Common/DigitalAssetDto';
import { FileSearchResponseDto } from '../../../types/Common/FileSearchResponseDto';
import { IconDisplayer } from '../Help/IconDisplayer';
import InfoIcon from '@mui/icons-material/Info';
import { isMobile } from 'react-device-detect';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import { isImageFile } from '../../../functions/utils/images';
import { operatorFileUploadHeaders, operatorDefaultHeaders } from "../../../functions/api/api";
import { GetFileNameFromResponseHeaders, GetFileNameWithoutExtension } from '../../../functions/utils/helper';

type Args = {
  item: DigitalAssetDto
  groupName: string;
  version: string;
  minimalUpload?: boolean | false
  defaultOpen: boolean;
  setFilesCount: (key: string, value: number) => void;

}


const FileUploader = ({ item, defaultOpen, groupName, version, minimalUpload, setFilesCount }: Args) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [uploadStatus, setUploadStatus] = useState<string>('');
  const [uploadError, setUploadError] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [order, setOrder] = useState<number>(0);
  const [fileName, setFileName] = useState<string>('');
  const [overwrite, setOverwrite] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [expandedFileId, setExpandedFileId] = useState<string | null>(null);
  const [expandedFileUpload, setexpandedFileUpload] = useState<string | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [loadingIndexes, setLoadingIndexes] = useState<number[]>([]);
  const queryClient = useQueryClient();


  const { data, status, isSuccess } = useQuery<FileSearchResponseDto[], AxiosError>(
    ['files', item.entityKey, item.entityName, item.sourceKey, item.sourceName],
    () =>
      axios
        .post(
          `/file-management/v${version}/Files/search`,
          {
            entityKey: item.entityKey,
            entityName: item.entityName,
            sourceName: item.sourceName,
            sourceKey: item.sourceKey
          },
          {
            withCredentials: true,
            headers: operatorDefaultHeaders()
          }
        )
        .then((resp) => resp.data)
  );


  useEffect(() => {

    if (defaultOpen) {
      setexpandedFileUpload(`${item.entityName}-${item.entityKey}`)
    }

  }, [defaultOpen, item]);


  useEffect(() => {
    if (data) {
      setLoadingIndexes(data!.map((_, index) => index));

      var approved = data.filter((item) => item.status === 'COMMON_LABEL_FILECOMPLETED').length;
      var rejected = data.filter((item) => item.status === 'COMMON_LABEL_FILEREJECTED').length;

      // if there is an item rejected we are setting the count to -1, in order that we can display a particular icon
      setFilesCount(item.label, (rejected > 0) ? -1 : approved);
    }

  }, [data]);


  const handleImageLoaded = (index: number) => {
    setLoadingIndexes(prevLoadingIndexes => prevLoadingIndexes.filter(i => i !== index));
  };


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  

  const handleUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('FileName', (fileName ? fileName : selectedFile.name));
    formData.append('Description', description);
    formData.append('GroupName', groupName);
    formData.append('UserComment', comment);
    formData.append('Order', order.toString());
    formData.append('SourceKey', item.sourceKey);
    formData.append('SourceName', item.sourceName);
    formData.append('EntityName', item.entityName);
    formData.append('UniqueFile', (item.maximumFileCount === 1 ? "true" : "false"));
    formData.append('Overwrite', overwrite.toString());
    formData.append('EntityKey', item.entityKey);
    formData.append('File', selectedFile);
    formData.append('ServiceName', item.serviceName);
    try {
      console.log('handleUpload');
      setUploadStatus('');
      setUploadError('');
      const response = await axios.post(`/file-management/v${version}/Files/`, formData, {
        withCredentials: true,
        headers: operatorFileUploadHeaders(),
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let precentage = Math.floor((loaded * 100) / total!);
          setUploadProgress(precentage);

        },
      });
      const status = i18n.t('FILEUPLOAD_LABEL_FILES_UPLOADSUCCESS');
      setUploadStatus(status);
      setUploadProgress(0);
      queryClient.invalidateQueries(['files', item.entityKey, item.entityName, item.sourceKey, item.sourceName]);

    } catch (error) {

      let errorMessage = "";

      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;

        switch (axiosError.response!.status) {
          case 409:
            errorMessage = "FILEUPLOAD_ERROR_FILES_FILEEXISTS";
            break;
          case 405:
            errorMessage = "FILEUPLOAD_ERROR_FILES_WRONGEXTENSION";
            break;
          default:
            errorMessage = "FILEUPLOAD_ERROR_FILES_GENERIC";
        }


      } else {
        errorMessage = "FILEUPLOAD_ERROR_FILES_GENERIC";
      }

      setUploadError(errorMessage);

    }

    setUploadProgress(0);
  };

  const handleViewFile = async (fileId: string, index: number, download: boolean) => {
    try {
      console.log('handleViewFile')
      setUploadStatus('');
      setUploadError('');
      const response = await axios.get(`/file-management/v${version}/Files/${fileId}`, {
        responseType: 'blob',
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
      );

      const file = new Blob([response.data], { type: response.headers['content-type'], });
      const fileUrl = URL.createObjectURL(file);

      setPreviewUrls((prevUrls) => {
        const updatedUrls = [...prevUrls];
        updatedUrls[index] = fileUrl;
        return updatedUrls;
      });

      if (download) {
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        link.download = GetFileNameFromResponseHeaders(response.headers);
        link.rel = 'noopener noreferrer';
        link.click();
      }

    } catch (error) {
      setUploadError("Error downloading the file");
    }
  };


  useEffect(() => {
    if (data) {
      data!.forEach((item: FileSearchResponseDto, index: number) => {
        handleViewFile(item.id, index, false);
      });
    }

  }, [data!]);

  const handleDeleteFile = async (fileId: string) => {
    try {
      setUploadStatus('');
      setUploadError('');
      const response = await axios.delete(`/file-management/v${version}/Files/${fileId}`,
        {
          withCredentials: true,
          headers: operatorDefaultHeaders()
        }
      );
      queryClient.invalidateQueries(['files', item.entityKey, item.entityName, item.sourceKey, item.sourceName])
      const deleteStatus = i18n.t('FILEUPLOAD_LABEL_FILES_DELETESUCCESS')
      setUploadStatus(deleteStatus);
    } catch (error) {
      const deleteErrorStatus = i18n.t('FILEUPLOAD_LABEL_FILES_DELETEERROR')
      setUploadError(deleteErrorStatus);
    }
  };

  return (
    <>
      <Paper elevation={3} className='fa-paper' key={`${item.entityName}-${item.entityKey}`}>

        <div className="row d-flex my-auto">
          <div className="col-auto my-auto justify-content-end">
            <Button className="btn btn-link btn-block btn-upload " onClick={() => (expandedFileUpload ? setexpandedFileUpload('') : setexpandedFileUpload(`${item.entityName}-${item.entityKey}`))}>
              <DriveFolderUploadRoundedIcon className='uploadIcon' />
              <LR localResource='FILEUPLOAD_BUTTON_UPLOADTITLE' />
            </Button>
          </div>
        </div>

        <Collapse in={expandedFileUpload === `${item.entityName}-${item.entityKey}`} unmountOnExit>
          <div>
            <div className="row d-flex justify-content-center ">
              <div className="col-md-11 col-11 justify-content-center">
                <small className="form-text text-muted ml-2">
                  <LR localResource='FILEUPLOAD_LABEL_FILEINPUT'></LR>
                  ({item.fileTypeCsv})
                </small>
              </div>
            </div>

            <div className="row d-flex justify-content-center  mb-1">
              <div className="col-md-11 col-11 justify-content-center">
                <input type="file" className='fileInput form-control fa-form' accept={item.fileTypeCsv} onChange={handleFileChange} />
              </div>
            </div>
            {
              item.maximumFileCount > 1 &&
              <>
                <div className="row d-flex justify-content-center ">
                  <div className="col-md-11 col-11 justify-content-center">
                    <small className="form-text text-muted ml-2">
                      <LR localResource='FILEUPLOAD_LABEL_FILENAME'></LR>
                    </small>
                  </div>
                </div>
                <div className="row d-flex justify-content-center  mb-1">
                  <div className="col-md-11 col-11 justify-content-center">
                    <input type="text"
                      value={fileName}
                      onChange={e => setFileName(e.target.value)}
                      className="form-control fa-form"
                      placeholder={i18n.t("FILEUPLOAD_LABEL_FILENAME").toString()}>
                    </input>
                  </div>
                </div>
              </>
            }


            {
              !minimalUpload &&
              <>
                <div className="row d-flex justify-content-center ">
                  <div className="col-md-11 col-11 justify-content-center">
                    <small className="form-text text-muted ml-2">
                      <LR localResource='FILEUPLOAD_LABEL_DESCRIPTION'></LR>
                    </small>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-1">
                  <div className="col-md-11 col-11 justify-content-center">
                    <textarea rows={2}
                      value={description}
                      onChange={e => setDescription(e.target.value)}
                      className="form-control fa-form"
                      placeholder={i18n.t("FILEUPLOAD_LABEL_DESCRIPTION").toString()}>
                    </textarea>
                  </div>
                </div>
              </>
            }

            <div className="row d-flex justify-content-center ">
              <div className="col-md-11 col-11 justify-content-center">
                <small className="form-text text-muted ml-2">
                  <LR localResource='FILEUPLOAD_LABEL_COMMENTS'></LR>
                </small>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-11 col-11 justify-content-center  mb-1">
                <textarea rows={2}
                  value={comment}
                  onChange={e => setComment(e.target.value)}
                  className="form-control fa-form"
                  placeholder={i18n.t("FILEUPLOAD_LABEL_COMMENTS").toString()}>
                </textarea>
              </div>
            </div>
            {!minimalUpload && <>
              <div className="row d-flex justify-content-center ">
              <div className="col-md-11 col-11 justify-content-center">
                <small className="form-text text-muted ml-2">
                  <LR localResource='FILEUPLOAD_LABEL_ORDER'></LR>
                </small>
              </div>
            </div>
            <div className="row d-flex justify-content-center  mb-2">
              <div className="col-md-11 col-11 justify-content-center">
                <input type="number"
                  value={order}
                  onChange={e => setOrder(parseInt(e.target.value))}
                  className="form-control fa-form"
                  placeholder={i18n.t("FILEUPLOAD_LABEL_ORDER").toString()}
                  min="0"
                  >
                </input>
              </div>
            </div>
            </>}
            

            {data && ((data?.length! > 0 && item.maximumFileCount === 1) || (selectedFile && (data.find(x => GetFileNameWithoutExtension(x.fileName) === GetFileNameWithoutExtension(fileName) || GetFileNameWithoutExtension(x.fileName) === GetFileNameWithoutExtension(selectedFile!.name))))) && (
              <div className="row d-flex justify-content-center">
                <div className="col-md-auto col-auto justify-content-center overwriteContainer">
                  <input type="checkbox" onChange={() => setOverwrite((state) => !state)} placeholder='Overwrite'></input>
                  <span><LR localResource='FILEUPLOAD_LABEL_FILES_OVERWRITE'></LR></span>
                </div>
              </div>)
            }

            <div className="row d-flex justify-content-center mb-3">
              <div className="col-md-5 col-5 justify-content-center">
                <button className="btn btn-green btn-primary btn-block" disabled={!selectedFile} onClick={handleUpload}>
                  <LR localResource="FILEUPLOAD_BUTTON_UPLOAD"></LR>
                </button>
              </div>
            </div>
          </div>
        </Collapse>

        <div className="row d-flex justify-content-center mb-3 ">
          <div className="col-md-11 col-11 justify-content-center">
            {uploadProgress > 0 && (
              <div className="row d-flex my-auto justify-content-center">
                <div className="col-10 ">
                  <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} animated striped />
                </div>
              </div>
            )}
            {
              (uploadStatus || uploadError) &&
              <div className="row d-flex my-auto justify-content-center">
                <div className="mt-3 col-10 small justify-content-center">
                  {uploadStatus && <MessageAlert message={uploadStatus} variant="success" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>}
                  {uploadError && <MessageAlert message={uploadError} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>}
                </div>
              </div>

            }
          </div></div>

        {isSuccess && !minimalUpload && (
          <Grid container spacing={2} >
            {data.map((file: FileSearchResponseDto, index: number) => (
              <Grid item xs={12} sm={6} md={4} key={file.id} >
                <Card>
                  <CardHeader
                    title={
                      <Typography variant="caption">
                        {!isMobile && (file.fileName.length > 50 ? `${file.fileName.substring(0, 50)}...` : file.fileName)}
                        {isMobile && (file.fileName.length > 40 ? `${file.fileName.substring(0, 40)}...` : file.fileName)}
                      </Typography>
                    }
                  />
                  <CardContent>
                    {isImageFile(file.fileName) && (
                      <>
                        <div style={{ display: loadingIndexes.includes(index) ? 'flex' : 'none', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                        <img
                          src={previewUrls[index]}
                          alt={file.fileName}
                          style={{ width: '100%', display: loadingIndexes.includes(index) ? 'none' : 'block' }}
                          onLoad={() => handleImageLoaded(index)}
                        />
                      </>
                    )}

                    {!isImageFile(file.fileName) && (
                      <div className='centeredDiv' style={{ display: loadingIndexes.includes(index) ? 'flex' : 'none', justifyContent: 'center' }}>
                        <FileIcon fileName={file.fileName} />
                      </div>
                    )}

                  </CardContent>
                  <CardActions style={{ justifyContent: 'center' }}>
                    <Tooltip title={i18n.t(file.status)}>
                      <IconButton onClick={(e) => e.preventDefault()}>
                        <IconDisplayer titleAccess={file.status} type={file.status} />
                      </IconButton>
                    </Tooltip>
                    {(file.details || file.description || file.userComment) && (
                      <Tooltip title={i18n.t('COMMON_TOOLTIP_DETAILS')}>
                        <IconButton onClick={() => (expandedFileId ? setExpandedFileId('') : setExpandedFileId(file.id))}>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    <IconButton onClick={(e) => handleViewFile(file.id, index, true)}>
                      <Tooltip title={i18n.t('COMMON_TOOLTIP_DOWNLOAD', { param0: file.fileName })}>
                        <DownloadRoundedIcon className='downloadFile' />
                      </Tooltip>
                    </IconButton>
                    <IconButton onClick={(e) => setDeleteConfirmation(true)}>
                      <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: file.fileName })}>
                        <DeleteRoundedIcon color="error" />
                      </Tooltip>
                    </IconButton>
                  </CardActions>
                  <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
                    <Card>
                      <CardContent>
                        <DialogTitle><LR localResource='COMMON_DIGITALASSET_DELETECONFIRMATIONTITLE' /></DialogTitle>
                        <DialogContent>
                          <DialogContentText>
                            <Typography variant="body1" color="textSecondary">
                              <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: file.fileName }} />
                            </Typography>
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                            <LR localResource='COMMON_BUTTON_CANCEL' />
                          </Button>
                          <Button className='btn-danger' onClick={() => { handleDeleteFile(file.id); setDeleteConfirmation(false); }} color="error" autoFocus>
                            <LR localResource='COMMON_BUTTON_DELETE' />
                          </Button>
                        </DialogActions>
                      </CardContent>
                    </Card>
                  </Dialog>
                  {(file.details || file.description || file.userComment) && <div>
                    <Collapse in={expandedFileId === file.id} unmountOnExit>
                      <div className="row mt-1 d-flex my-auto fileDetails">
                        <div className="col my-auto smaller">
                          {file.details &&
                            <>
                              <Typography variant="h6"><LR localResource='FILEUPLOAD_LABEL_FILEUPLOADDETAILS'></LR></Typography>
                              <p>{file.details}</p>
                            </>
                          }

                          {file.userComment &&
                            <>
                              <Typography variant="h6"><LR localResource='FILEUPLOAD_LABEL_COMMENTS'></LR></Typography>
                              <p>{file.userComment}</p>
                            </>
                          }
                          {
                            file.description &&
                            <>
                              <Typography variant="h6"><LR localResource='FILEUPLOAD_LABEL_DESCRIPTION'></LR></Typography>
                              <p>{file.description}</p>
                            </>
                          }
                        </div>
                      </div>
                    </Collapse>
                  </div>}

                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Paper>

    </>
  );
};

export default FileUploader;
