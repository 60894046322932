import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import "./Menu.css"
import useFetchMenu from '../../hooks/Navigation/Menu';
import { MenuItem } from '../../types/Navigation/MenuItem';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import { LR } from '../Common/Help/LR';
import i18n from '../../i18n';
import Avatar from '@mui/material/Avatar';
import { Badge, Tooltip } from '@mui/material';
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { OperatorContext } from '../Member/OperatorContext';
import { doFetchNotificationCount } from '../../hooks/Notification/notification';
import React, { useEffect, useState } from 'react';

type Args = {
  userInfo: ApplicationUser
};

const Menu = ({ userInfo }: Args) => {
  const { data: menuItems, status, isSuccess } = useFetchMenu();
  const [notificationsCount, setNotificationsCount] = useState(0);

  function refreshNotifications() {
    doFetchNotificationCount(userInfo.memberId).then((data) => {
      setNotificationsCount(data.unReadCount);
    });
  }

  useEffect(() => {
    refreshNotifications();
    // Poll every 2 minutes
    const pollingInterval = 1000 * 60 * 2; // 2 minutes in milliseconds
    const pollingTimer = setInterval(refreshNotifications, pollingInterval);

    // Clean up the interval on unmount
    return () => {
      clearInterval(pollingTimer);
    };
  }, []);


  function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
  }

  function stringAvatar(name: string) {
    if (name !== '') {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
      };
    }
  }

  const renderMenuItems = (items: MenuItem[], parentIndex = ""): JSX.Element[] => {
    return items.map((item: MenuItem, index: number) => {
      const key = `${parentIndex}-${index}`;
      if (item.children && item.children.length > 0) {
        switch (item.position) {
          case 0: //left
            return (
              <NavDropdown key={key} title={i18n.t(item.label)} id={`dropdown-${key}`}>
                {renderMenuItems(item.children, key)}
              </NavDropdown>
            );
          case 1: //right
            return (
              <div key={key} style={{ display: 'flex' }} className='ms-auto'>
                {userInfo.name && <Avatar {...stringAvatar(userInfo.name)} />}
                <NavDropdown key={key} title={i18n.t(item.label)} id={`dropdown-${key}`} >
                  {renderMenuItems(item.children, key)}
                </NavDropdown>
                <Nav.Link href={`/me/${userInfo.operator!.id}/notifications`}>
                  <Badge key="badge" badgeContent={notificationsCount} color="secondary" className='bell-Icon' >
                    <NotificationsRoundedIcon>
                    </NotificationsRoundedIcon>
                  </Badge>
                </Nav.Link>
              </div>
            );
        }
        return (<></>);
      } else {
        switch (item.url) {
          case "#signout":
            return (
              <Nav.Link key={key} href={userInfo.logoutUrl}>
                <LR key={`resource_${key}`} localResource={item.label}></LR>
              </Nav.Link>
            );
          case "/#manageoperator":
            return (
              <Nav.Link key={key} href={`/operators/${userInfo.operator!.id}/summary`}>
                <LR key={`resource_${key}`} localResource={item.label}></LR>
              </Nav.Link>
            );
          case "/#accountsettings":
            return (
              <Nav.Link key={key} href={`/me/accountsettings/${userInfo.memberId}/`}>
                <LR key={`resource_${key}`} localResource={item.label}></LR>
              </Nav.Link>
            );
          case "/myaccount/manage":
            return (
              <Nav.Link key={key} href={`/me/accountsettings/${userInfo.memberId}`}>
                <LR key={`resource_${key}`} localResource={item.label}></LR>
              </Nav.Link>
            );
          default:
            return (
                <Nav.Link key={key} href={item.url} target={item.targetWindow ?? ""}>
                <LR key={`resource_${key}`} localResource={item.label}></LR>
              </Nav.Link>
            );
        }
      }
    });
  };

  if (!isSuccess) return <><span></span></>;
  if (status !== "success") return <><span>{status}...</span></>;

  return (
    <>
      <Navbar className='fa-NavBar menu' variant="dark" fixed="top" expand="sm">
        <Container>
          <Navbar.Brand href="/" key="Brand">
            <Tooltip title={'Home'}>
              <div className="menu-icon">
                <img src="/images/general/home.png"></img>
              </div>
            </Tooltip>

          </Navbar.Brand>
          <OperatorContext userInfo={userInfo}></OperatorContext>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" key="Toogle_1" />
          <Navbar.Collapse id="responsive-navbar-nav" key="Collapse_1">
            {renderMenuItems(menuItems)}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Menu;

