import React, { useState, useEffect } from "react";
import { PriceDto } from "../../../types/Price/PriceDto";
import i18n from "../../../i18n";

type Args = {
    item: PriceDto,
    currencyValue: string,
    value: number,
    allowEmpty: boolean,
    onChange: (item: PriceDto, value: string) => void,
};


const FormattedNumberInput = ({ item, value, currencyValue, allowEmpty, onChange }: Args) => {
    const numberFormatter = new Intl.NumberFormat(i18n.language, {
        style: "currency",
        currency: currencyValue,
    });

    const inputRef = React.createRef<HTMLInputElement>();
    const [currentValue, setCurrentValue] = useState(numberFormatter.format(value));
    const [currentFloatValue, setCurrentFloatValue] = useState(value);

    useEffect(() => {
        setCurrentValue(numberFormatter.format(value));
        setCurrentFloatValue(value);

    }, [value]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        let sanitizedValue = inputValue.replace(/[^0-9.]/g, ''); // Remove non-numeric characters
        setCurrentValue(sanitizedValue);
        setCurrentFloatValue(parseFloat(sanitizedValue));
    };

    const handleInputFocus = () => {
        if(!allowEmpty)
            setCurrentValue(currentFloatValue.toString());
    };


    const handleInputOnBlur = () => {
        onChange(item, currentFloatValue.toString());
        if(currentValue === '') {
            if(!allowEmpty) {
                setCurrentValue('0');
                setCurrentFloatValue(parseFloat('0'));
            }
            return;
        }
        
        setCurrentValue(numberFormatter.format(currentFloatValue));
    };

    return (
        <>
            <input
                className="form-control fa-form"
                ref={inputRef}
                type="text"
                value={currentValue}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputOnBlur}
                style={{ textAlign: "right" }}
            />
        </>
    );
};

export default FormattedNumberInput;
