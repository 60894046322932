import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import '../../Common/Features/FeatureGroup.css';
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import { FeaturesDto } from "../../../types/Common/FeaturesDto";
import { useDirtyState } from "../../../hooks/Common/dirty";
import ApiStatus from "../../Common/Errors/ApiStatus";
import { ConfirmationChangesDialog } from "../../Common/ConfirmationChangesDialog";
import { SectionHead } from "../../Common/Help/SectionHead";
import { FeaturesTreeview } from "../../Common/Features/FeaturesTreeview";
import i18n from "../../../i18n";
import { LR } from "../../Common/Help/LR";
import { useFetchPostFeatureHierarchy, useManagePostFeatures } from "../../../hooks/Posts/postFeatures";
import { useFetchPostById } from "../../../hooks/Posts/posts";
import CustomQuillEditor from "../../Common/Input/CustomQuillEditor";
import { SaveSnackBar } from '../../Common/SaveSnackBar';
import '../../../main/App.css';

type Args = {
    userLogged: ApplicationUser
};

const PostFeatureGroup = ({ userLogged }: Args) => {
    const nav = useNavigate();
    const [selectedFeatures, setSelectedFeatures] = useState<FeaturesDto[]>([]);
    const [description, setDescription] = useState<string>('');
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [unsavedChanges, setUnsavedChanges] = useState(false); // Added to track unsaved changes directly
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const queryStringParams = useParams();
    const { mutate } = useManagePostFeatures();
    if (!queryStringParams.postId) throw Error("Post id needed");
    if (!queryStringParams.id) throw Error("Application id needed");
    const applicationId = queryStringParams.id;
    const postId = queryStringParams.postId;
    const groupName = queryStringParams.featuregroup;
    const { data, status, isSuccess } = useFetchPostFeatureHierarchy(postId, groupName!);
    const { data: post, status: postStatus, isSuccess: postIsSuccess } = useFetchPostById(postId);

    const linkCallBack = () => {
        let url = `/application/${applicationId}/configuration/posts/${postId}/features`;
        if (isDirty) {
            setShowConfirmation(true);
            setTargetUrl(url);
        } else {
            nav(url);
        }
    };

    const callBack = (features: FeaturesDto[]) => {
        setSelectedFeatures(features);
        setUnsavedChanges(true);  // Mark unsaved changes on feature selection
    };

    const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setShowConfirmation(false);
        unsetDirty();
        if(selectedFeatures.find(x => x.value === data.value) === null) {
            selectedFeatures.push({ description: description, value: data.value });
        }
        mutate([applicationId, postId, queryStringParams.featuregroup!, selectedFeatures]);
        setSnackbarOpen(true); // Show snackbar
        setUnsavedChanges(false);  // Reset unsaved changes on successful save
    };

    const handleDirty = () => {
        setDirty();
        setUnsavedChanges(true);  // Set unsaved changes when form becomes dirty
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    };

    const dialogCancel = () => {
        setShowConfirmation(false);
    };

    const handleSnackClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    useEffect(() => {
        if (data) {
            setDescription(data.description);
        }
    }, [data]);

    if ((!isSuccess || !postIsSuccess) && (status !== 'success') && (postStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    );

    if (!data || !post) return <ApiStatus status={status} />;

    return (
        <form onSubmit={onFormSubmit}>
            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation} />
            <SectionHead
                linkCallBack={linkCallBack}
                ctaText={i18n.t("COMMON_SUMMARY_FEATURES") || ''}
                name={`${post.title}`}
                title={i18n.t(data.label) || ''}
                description={i18n.t('COMMON_SUMMARY_POSTS') || ''}
            />

            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-10 justify-content-center">
                    <FeaturesTreeview handleDirty={handleDirty} items={data} callback={callBack} />
                </div>
            </div>
            <CustomQuillEditor
                value={description ?? ''}
                onChange={(newDescription) => { setDescription(newDescription); handleDirty(); }}
                placeholder={i18n.t("POST_LABEL_FEATUREDESCRIPTION", { featureName: data.label }).toString()}
            />
            <div className="row d-flex justify-content-center mt-3">
                <div className="col-md-2 col-6 justify-content-center">
                    <button type="submit" className="btn btn-green btn-primary btn-block" disabled={!isDirty}>
                        <LR localResource="COMMON_LABEL_SAVE"></LR>
                    </button>
                </div>
            </div>
            {unsavedChanges && (
                <div 
                    className="alert alert-center " 
                    role="alert"
                >
                    <p className='warning-label'>
                        {i18n.t('WARNING_LABEL')}
                    </p>
                </div>
            )}
            <SaveSnackBar onClose={handleSnackClose} isOpen={snackbarOpen} />
        </form>
    );
};

export { PostFeatureGroup };
