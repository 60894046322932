import { createTheme } from '@mui/material/styles';

const PontTheme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#2c2c2c',
    },
    secondary: {
      main: '#00be95',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        outlined : {
          color:'#fff'
        },
        contained: {
          backgroundColor: '#00be95',
          
        },
      },
    },    
    MuiDialog: {
      styleOverrides: {

       paper:{  
        border: 'solid 1px #fff',      
        backgroundColor : '#dddddd',
        color:'#2c2c2c'
       },
       
        // paper: {
        //   backgroundColor: '#2c2c2c',
        // },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: '#2c2c2c',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          '& .MuiDataGrid-filterPanel': {
            backgroundColor: '#2c2c2c',
          }
        },
      },
      
    },
  },
});

export { PontTheme };
