import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { SummaryIcon, SummaryItem } from "../Common/SummaryItems/SummaryItem";
import { useFetchMemberById, } from "../../hooks/Member/member";
import ApiStatus from "../Common/Errors/ApiStatus";
import { useFetchMemberDomains } from "../../hooks/Member/domains";
import { DomainCollection } from "./DomainCollection";
import i18n from "../../i18n";
import { useDirtyState } from "../../hooks/Common/dirty";
import { useState } from "react";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { TitleWithIcon } from "../Common/Help/TitleWithIcon";

type Args =
    {
        userLogged: ApplicationUser
    }

const HostDomains = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    if (!queryStringParams.id) throw Error("Operator id needed");

    //#region navigation
    const linkCallBack = () => {
        let url = `/operators/${queryStringParams.id}/companydetails`;
        if(isDirty)
        {
            setShowConfirmation(true);
            setTargetUrl(url);
        }else
        {
            nav(url);
        }
        
    }

    const linkCallBackList = () => {
        alert('not yet implemented')
    }
    //#endregion
    
    //#region dirty
    const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(queryStringParams.id!)
    const { data, status, isSuccess } = useFetchMemberDomains(queryStringParams.id!);

    if ((!isSuccess || !operatorIsSuccess) && (status !== 'success') && (operatorStatus !== 'success')) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={status} />
                </div>
            </div>
        </>
    )

    if (!data || !operator) return <ApiStatus status={status} />;

    return (
        <>
         <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
            <div className="row d-flex justify-content-center fixed-top fixSummary">
                <div className="col-md-5 col-11 justify-content-center">
                    <SummaryItem linkCallBack={linkCallBackList} icon={SummaryIcon.None} backgroundClass='hostSummaryBackground' name={operator.fullName} details={operator.externalReference} countryISOCode3={operator.countryIso3Code} ></SummaryItem>
                </div>
            </div>
            <div className="row d-flex justify-content-center firstElement">
                <div className="col-md-5 col-11 justify-content-center">
                    <SummaryItem linkCallBack={linkCallBack} name={i18n.t("COMMON_SUMMARY_COMPANYDETAILS")}></SummaryItem>
                </div>
            </div>

            <TitleWithIcon label="COMMON_SUMMARY_DOMAINS"/>


            <DomainCollection handleDirty={handleDirty} items={data} entityName="Member" entityKey={queryStringParams.id!}></DomainCollection>

        </>
    );
};


export { HostDomains }

