import { useEffect, useState } from "react";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import axios from "axios";
import { useFetchMembers } from "../Member/member";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";


const useIsUserLogged = (): ApplicationUser => {  
  const user: ApplicationUser =
  {
    memberId: "",
    isDeclined: false,
    doesNeedSetup: undefined,
    isLogged: false,
    name: "",
    familyName: "",
    givenName: "",
    country: "",
    email: "",
    id: "",
    logoutUrl: "",

  }

  const [userLogged, setUserStatus] = useState<ApplicationUser>(user);
  const [member, setMember] = useState<MemberResponseDto | null>(null);
  const { data: operators, status, isSuccess } = useFetchMembers(4, '', 2);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [checkOperator, setCheckOperator] = useState(false);

  useEffect(() => {
    const fetchUserLoginStatus = async () => {
      const response = await fetch("/bff/user", { headers: operatorDefaultHeaders()} );

      const returnedUser: ApplicationUser =
      {
        isDeclined: false,
        isLogged: false,
        doesNeedSetup: undefined,
        memberId: "",
        name: "",
        familyName: "",
        givenName: "",
        country: "",
        email: "",
        id: "",
        logoutUrl: "",
      }

      if (response.ok && response.status === 200) {
        returnedUser.isLogged = true;
        const data = await response.json();
        returnedUser.id = data.find((claim: { type: string; }) => claim.type === "sub")?.value ?? "";

        if (returnedUser.id === "") {
          returnedUser.isLogged = false;
          setUserStatus(userLogged);
        }

        returnedUser.logoutUrl = data.find((claim: { type: string; }) => claim.type === "bff:logout_url")?.value ?? "/bff/logout";
        returnedUser.name = data.find((claim: { type: string; }) => claim.type === "name")?.value;
        returnedUser.givenName = data.find((claim: { type: string; }) => claim.type === "given_name")?.value;
        returnedUser.familyName = data.find((claim: { type: string; }) => claim.type === "family_name")?.value;
        returnedUser.email = data.find((claim: { type: string; }) => claim.type === "preferred_username")?.value;
        returnedUser.country = data.find((claim: { type: string; }) => claim.type === "locale")?.value;
        setUserStatus(returnedUser);
      } else {

        userLogged.doesNeedSetup = false;
        returnedUser.isLogged = false;
        returnedUser.isDeclined = true;
        returnedUser.doesNeedSetup = false;
        checkDataLoaded();
        setUserStatus(returnedUser);
      }
    };
    fetchUserLoginStatus();
  }, []);

  
  useEffect(() => {
    if (userLogged.isLogged && !userLogged.doesNeedSetup) {
      const fetchMember = async () => {
        try {
          const member = await axios.get<MemberResponseDto>(`${HookConfig.memberUrl}/me`, {
            withCredentials: true,
            headers: operatorDefaultHeaders(),
          });

          setUserStatus(prev => ({ ...prev, memberId: member.data.id, doesNeedSetup: false }));
          setCheckOperator(true);
          checkDataLoaded();
        } catch (error: any) {
          userLogged.doesNeedSetup = true;
          setUserStatus(prev => ({ ...prev, doesNeedSetup: true }));
          checkDataLoaded();
        }
      };

      fetchMember();
    }
  }, [userLogged.isLogged]);

  useEffect(() => {
    if (userLogged.isLogged && !userLogged.doesNeedSetup && operators && checkOperator) {
      if (operators && operators.length > 0 && (userLogged && !userLogged.operator && !userLogged.doesNeedSetup)) {
        const cachedOperator: MemberResponseDto = JSON.parse(localStorage.getItem(userLogged.id + "_currentOperator")!) as MemberResponseDto;
        if (cachedOperator) {
          userLogged.doesNeedSetup = false;
          setUserStatus({ ...userLogged, operator: cachedOperator, doesNeedSetup: false });
        } else {
          const selectedOperator = operators[0];
          localStorage.setItem(userLogged.id + "_currentOperator", JSON.stringify(selectedOperator));
          userLogged.doesNeedSetup = false;
          setUserStatus({ ...userLogged, operator: selectedOperator, doesNeedSetup: false });
        }
      }

      if (operators && operators.length === 0) // no operator created
      {
        userLogged.doesNeedSetup = true;
        setUserStatus({ ...userLogged, doesNeedSetup: true });
      }
      checkDataLoaded();

    }
  }, [userLogged.isLogged, checkOperator, operators]);


  const checkDataLoaded = () => {
    if (
      userLogged.doesNeedSetup !== undefined
    ) {
      setIsDataLoaded(true);
    }
  };


  return isDataLoaded ? userLogged : user;
};

export {
  useIsUserLogged
} 