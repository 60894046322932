import axios, { AxiosError, AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import Problem from "../../types/Problem";
import { DomainDto } from "../../types/Common/DomainDto";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { FiscalDetailDto } from "../../types/Member/FiscalDetailDto";



const   useManageMemberFiscalDetails = () => {
    const queryClient = useQueryClient();
    const nav = useNavigate();
    const manageFiscalDetails = async (
        [id, items]: [string, FiscalDetailDto[]]
      ): Promise<AxiosResponse<any, any>[]> => {
        const promises = items
        .map(async (item) => {
          const url = item.id === "00000000-0000-0000-0000-000000000000" ?  `${HookConfig.memberUrl}/${id}/fiscal-details` :`${HookConfig.memberUrl}/${id}/fiscal-details/${item.id}`;
          const method = item.id === "00000000-0000-0000-0000-000000000000" ? "post" : "put";

        const body = item.id === "00000000-0000-0000-0000-000000000000" ? 
            {value: item.value, memberId : id, fiscalDetailTypeName: item.fiscalDetailTypeName, countryIso3Code :item.countryIso3Code } :
            {id: item.id, memberId : id, value: item.value, fiscalDetailTypeName: item.fiscalDetailTypeName, countryIso3Code :item.countryIso3Code} 

          const response = await axios[method](
            url, body,
            {
              withCredentials: true,
               headers: operatorDefaultHeaders() ,
            }
          );
          return response;
        });
        const responses = await Promise.all(promises);
        return responses;
      };
  
    const mutation = useMutation<
      AxiosResponse<any, any>[],
      AxiosError<Problem>,
      [string, FiscalDetailDto[]]>(manageFiscalDetails, {
        onSuccess: (responses, [id, comunicationType] ) => {
            queryClient.invalidateQueries(["member-fiscaldetails", id]);
            nav(`/operators/${id}/summary`);
          },
    });
  
    return mutation;
  };

  const useFetchMemberFiscalDetails = (id: string, country:string) => {
    return useQuery<FiscalDetailDto[],AxiosError>(["member-fiscaldetails", id], () =>
      axios.get(`${HookConfig.memberUrl}/${id}/fiscal-details?countryIso3Code=${country}`,
        {
          withCredentials: true,
          headers: operatorDefaultHeaders() 
        })
        .then(
          (response) => {
            return response.data;
          }
        ));
  };


  

  export{
    useManageMemberFiscalDetails,
    useFetchMemberFiscalDetails
  }
 