import React, { createContext, ReactNode, useState } from 'react';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';


interface GlobalStateContextProps {
  globalFetchifytExecuted: any;
  setGlobalFetchifytExecuted: React.Dispatch<React.SetStateAction<any>>;
  globalLoggedUser: ApplicationUser | null;
  setGlobalLoggedUser: React.Dispatch<React.SetStateAction<ApplicationUser | null>>;
}

export const GlobalStateContext = createContext<GlobalStateContextProps | undefined>(undefined);

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [globalFetchifytExecuted, _setGlobalFetchifytExecuted] = useState<any>({});
  const [globalLoggedUser, setGlobalLoggedUser] = useState<ApplicationUser | null>(null);

  const setGlobalFetchifytExecuted = (value: any) => {
    _setGlobalFetchifytExecuted((prevState: any) => ({ ...prevState, ...value }));
  };

  return (
    <GlobalStateContext.Provider value={{ globalFetchifytExecuted, setGlobalFetchifytExecuted, globalLoggedUser, setGlobalLoggedUser }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
