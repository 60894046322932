import { useAddMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { MemberForm } from "./MemberForm";
import { LR } from "../Common/Help/LR";
import { useParams } from "react-router";
import { useState } from "react";
import { SectionHead } from "../Common/Help/SectionHead";
import { useNavigate } from "react-router-dom";

type Args =
    {
        userLogged: ApplicationUser
    }

const MemberAdd = ({ userLogged }: Args) => {

    const params = useParams();
    const nav = useNavigate();
    const parentId = params.parentId;
    const memberType = 'Operator'
    const [submitting, setSubmitting] = useState(false);
    const addMemberMutation = useAddMember(memberType, parentId);
    const AddMember = (member: MemberRequestDto) => {
        setSubmitting(true);
        addMemberMutation.mutate(member)
        setSubmitting(false);
    }


    const member: MemberRequestDto = {
        countryIso3Code: '',
        enrolmentCode: '',
        fullName: '',
        languageCode: i18n.language,
        memberTypeName: memberType
    };

       //#region Dirty
       const handleDirty = () => {
        
    };

    const homeCallBack = () => {
        nav(`/`);
    }

   

    return (
        <>
            <SectionHead linkCallBack={homeCallBack}
                ctaText={i18n.t('COMMON_SUMMARY_DASHBOARD')!}
                name=""
                title={i18n.t('MEMBER_TITLE_NEWOPERATOR'!)}
                description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />

            <div className="row mb-3 d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <LR localResource="MEMBER_MESSAGE_NEWOPERATOR"></LR>
                </div>
            </div>


            <div className="row d-flex justify-content-center">
                <div className="col-md-4 col-11 justify-content-center">
                    <MemberForm
                        handleDirty={handleDirty}
                        type={memberType}
                        submitting={submitting}
                        member={member}
                        isEdit={false}
                        submitted={(member) => AddMember(member)}
                    />
                </div>
            </div>

            {(addMemberMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-4 col-11 justify-content-center">
                        <MessageAlert message={addMemberMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                    </div>
                </div>
            }
        </>
    );
};


export { MemberAdd }