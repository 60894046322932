import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import i18n from "../../../i18n";
import { useFetchLocationById } from "../../../hooks/Location/location";
import { InventoryVariantConfiguration } from "./InventoryVariantConfiguration";
import { InventoryBasePricing } from "./InventoryBasePricing";
import { SectionHead } from "../../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
    }

const LocationConfigurationDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.id) throw Error("Location id needed");
    if (!queryStringParams.configurationName) throw Error("configurationName id needed");

    const linkCallBack = () => {
        nav(`/locations/${queryStringParams.id}/configuration/`);
    }

    const { data: location, status: locationStatus, isSuccess: locationIsSuccess } = useFetchLocationById(queryStringParams.id!)
    
    

    if ((!locationIsSuccess) ) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={locationStatus} />
                </div>
            </div>
        </>
    )

    if (!location) return <ApiStatus status={locationStatus} />;

    const label = `LOCATION_CONFIGURATION_${queryStringParams.configurationName.toUpperCase()}`  

    return (
        <>

<SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('LOCATION_LABEL_CONFIGURATION')!}
                name={`${location.name} (${location.externalReference})`}
                title={i18n.t(label)}
                description={i18n.t('COMMON_SUMMARY_LOCATIONMANAGEMENT')} />



            {
                queryStringParams.configurationName === 'variants' &&
                <div className="row d-flex justify-content-center">
                    
                    <div className="col-md-5 col-11 justify-content-center">
                        <InventoryVariantConfiguration locationId={queryStringParams.id}></InventoryVariantConfiguration>
                    </div>
                </div>
            }

{
                queryStringParams.configurationName === 'base-prices' &&
                <div className="row d-flex justify-content-center">
                    
                    <div className="col-md-5 col-11 justify-content-center">
                        <InventoryBasePricing countryIsoCode3={location.countryIso3Code} locationId={queryStringParams.id}></InventoryBasePricing>
                    </div>
                </div>
            }



        </>
    );
};


export { LocationConfigurationDetails }