import { Button, Card, CardContent, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import ".././Common/SummaryItems/SummaryItem.css"
import { LR } from "../Common/Help/LR";
import { useNavigate } from "react-router";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import { FormatDate } from "../../functions/utils/helper";
import i18n from "../../i18n";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useDeleteUnavailability } from "../../hooks/Inventory/inventory";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { LabelAndHelp } from "../Common/Help/LabelAndHelp";
import { AddDaysToDate, DateShim } from "../../functions/utils/helper";

type Args = {
  items: InventoryUnavailabilityDto[],
  locationId?: string
};

const InventoryUnavailabilities = ({ items, locationId }: Args) => {
  const nav = useNavigate();
  const deleteUnavailabilities = useDeleteUnavailability();
  const [unavailabilities, setUnavailabilities] = useState<InventoryUnavailabilityDto[]>(items);
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [deleteName, setDeleteName] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [deleteInventoryId, setDeleteInventoryId] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    setUnavailabilities(items);
  }, [items]);

  const handleDelete = (name: string, id: string, inventoryId: string) => {
    setDeleteName(name);
    setDeleteId(id);
    setDeleteInventoryId(inventoryId);
    setDeleteConfirmation(true);
  }

  const confirmDeleteItem = async (id: string, inventoryId: string) => {
    const resultDelete = await deleteUnavailabilities(id, inventoryId);
    if (resultDelete.success) {
      setUnavailabilities(unavailabilities.filter(x => x.id !== id))
    } else {
      setError("INVENTORY_ERROR_ALLOCATIONDELETION");
    }
    setDeleteConfirmation(false);
  }

  const addItem = () => {
    !!locationId ? nav(`${locationId}/unavailability/add`) : nav(`unavailability/add`);
  }

  return (
    <>
      <LabelAndHelp mandatory={false} fullscreen={true} message='INVENTORY_LABEL_UNAVAILABILITYTEXT' ></LabelAndHelp>
      <Paper elevation={3} className="fa-paper-light">
        <div className="d-flex align-items-center">
          <div className="fa-name flex-grow-1">
            <Tooltip title={i18n.t('INVENTORY_LABEL_NEWUNAVAILABILITY')}>
              <IconButton onClick={addItem} className="btn plusButton">
                <AddCircleRoundedIcon fontSize="medium" sx={{ color: '#00be95' }} />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" className="linkbutton">
              <LR localResource="INVENTORY_LABEL_UNAVAILABILITIES" /> ({unavailabilities.length})
            </Typography>
          </div>
          <div className="fa-icons">
            <IconButton className="fa-arrow" onClick={() => setIsCollapseOpen(!isCollapseOpen)}>
              {!isCollapseOpen && <ArrowForwardIosRoundedIcon />}
              {isCollapseOpen && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
            </IconButton>
          </div>
        </div>
      </Paper>

      <Collapse in={isCollapseOpen}>
        <div >
          {unavailabilities.map((item, index) => (
            <Paper key={item.id} elevation={24} className="fa-paper-grid">
              <div className="d-flex align-items-center">
                <div className="fa-name flex-grow-1">
                  {item.inventoryUnavailabilityTypeName},
                  From {FormatDate(item.fromDate!)}
                          &nbsp;to {FormatDate(AddDaysToDate(item.toDate!, -1) !)}
                </div>
                <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.reason })}>
                  <IconButton onClick={(e) => handleDelete(item.inventoryUnavailabilityTypeName!, item.id!.toString(), item.inventoryId!.toString())}>
                    <DeleteRoundedIcon key={index} color={'error'} />
                  </IconButton>
                </Tooltip>
              </div>
            </Paper>
          ))}

          {
            error && <div className="row d-flex justify-content-center">
              <div className="justify-content-center">
                <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }

        </div>
      </Collapse>
      <Dialog open={deleteConfirmation} onClose={() => setDeleteConfirmation(false)}>
        <Card>
          <CardContent>
            <DialogTitle><LR localResource='COMMON_CONTACT_DELETECONFIRMATIONTITLE' /></DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography variant="body1" color="textSecondary">
                  <LR localResource='COMMON_BUTTON_DELETETEXT' params={{ name: deleteName }} />
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button className='btn-green' onClick={() => setDeleteConfirmation(false)}>
                <LR localResource='COMMON_BUTTON_CANCEL' />
              </Button>
              <Button className='btn-danger' onClick={() => { confirmDeleteItem(deleteId, deleteInventoryId); }} color="error" autoFocus>
                <LR localResource='COMMON_BUTTON_DELETE' />
              </Button>
            </DialogActions>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );
}

export {
  InventoryUnavailabilities
}