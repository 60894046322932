import { useMutation, useQuery, useQueryClient } from "react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { HookConfig } from "../../config/HookConfig";
import { operatorDefaultHeaders } from "../../functions/api/api";
import { InventoryDto } from "../../types/Inventory/InventoryDto";
import { InventoryTypeDto } from "../../types/Inventory/InventoryTypeDto";
import { InventoryAllocationDto } from "../../types/Inventory/InventoryAllocationDto";
import { PriceDto } from "../../types/Price/PriceDto";
import Problem from "../../types/Problem";
import { InventoryUnavailabilityDto } from "../../types/Inventory/InventoryUnavailabilityDto";
import { InventoryUnavailabilityTypeDto } from "../../types/Inventory/InventoryUnavailabilityTypeDto";
import { InventoryVariantDto } from "../../types/Inventory/InventoryVariantDto";


const useFetchInventoryByLocationId = (id: string, operatorId:string) => {
    return useQuery<InventoryDto[], AxiosError>(["location-inventory", id, operatorId], () =>
    axios.get(

        `${HookConfig.inventoryUrl}/inventories?locationId=${id}&operatormemberid=${operatorId}`
      ,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};


const useFetchInventoryTypes = () => {
  return useQuery<InventoryTypeDto[], AxiosError>(["inventory-types"], () =>
    axios.get(

      `${HookConfig.inventoryUrl}/inventory-types`
      ,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};

const useFetchInventoryUnavailabilityTypes = () => {
  return useQuery<InventoryUnavailabilityTypeDto[], AxiosError>(["inventory-unavailabilitytypes"], () =>
    axios.get(

      `${HookConfig.inventoryUrl}/inventory-unavailability-types`
      ,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};



const useFetchInventoryById = (id: string) => {
  return useQuery<InventoryDto, AxiosError>(["inventory", id], () =>
    axios.get(`${HookConfig.inventoryUrl}/inventories/${id}`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};




const useManageInventory = (callback: (inventoryId? : string) => void, update: boolean) => {
  const queryClient = useQueryClient();  
  const method = update ? 'put' : 'post';
  return useMutation<AxiosResponse, AxiosError, InventoryDto>(
    (inventory) => axios[method](
      update ? `${HookConfig.inventoryUrl}/inventories/${inventory.id}` : `${HookConfig.inventoryUrl}/inventories/`
      , inventory,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, inventory) => {
        queryClient.invalidateQueries(["inventory", inventory.id]);
        queryClient.invalidateQueries(["location-inventory", inventory.locationId]);
        callback(_.data.id);
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useManageInventoryUnavailability = (callback: () => void, update: boolean) => {
  const queryClient = useQueryClient();  
  const method = update ? 'put' : 'post';
  return useMutation<AxiosResponse, AxiosError, InventoryUnavailabilityDto>(
    (inventoryUnavailability) => axios[method](
      update ? `${HookConfig.inventoryUrl}/inventories/${inventoryUnavailability.inventoryId}/unavailabilities` : `${HookConfig.inventoryUrl}/inventories/${inventoryUnavailability.inventoryId}/unavailabilities`
      , inventoryUnavailability,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (_, inventory) => {
        queryClient.invalidateQueries(["inventory", inventory.inventoryId]);
        callback();
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useFetchInventoryAllocationById = (inventoryId: string, id: string) => {
  return useQuery<InventoryAllocationDto, AxiosError>(["inventory-allocation", inventoryId, id], () =>
    axios.get(`${HookConfig.inventoryUrl}/inventories/${inventoryId}/allocations/${id}/`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};


const useDeleteAllocation = () => {
  const queryClient = useQueryClient();
  return (id: string, inventoryId : string) => {
    return axios
        .delete(`${HookConfig.inventoryUrl}/inventories/${inventoryId}/allocations/${id}`, {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        queryClient.invalidateQueries(["inventory", inventoryId]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error deleting allocation:", error);
        return { success: false, error }; 
      });
  };
};


const useDeleteUnavailability = () => {
  const queryClient = useQueryClient();
  return (id: string, inventoryId : string) => {
    return axios
      .delete(`${HookConfig.inventoryUrl}/inventories/${inventoryId}/unavailabilities/${id}`, {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        queryClient.invalidateQueries(["inventory", inventoryId]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error deleting unavailaibility:", error);
        return { success: false, error }; 
      });
  };
};

const useDeleteInventory = () => {
  const queryClient = useQueryClient();
  return (id: string) => {
    return axios
      .delete(`${HookConfig.inventoryUrl}/inventories/${id}/`, {
        withCredentials: true,
         headers: operatorDefaultHeaders() ,
      })
      .then(() => {
        queryClient.invalidateQueries(["inventory", id]);
        return { success: true }; 
      })
      .catch((error) => {
        console.log("Error deleting unavailaibility:", error);
        return { success: false, error }; 
      });
  };
};



const useFetchInventoryAllocationPrices = (inventoryId:string, id: string) => {
  return useQuery<PriceDto[], AxiosError>(["inventory-allocaton-prices", inventoryId, id], () =>
      axios.get(`${HookConfig.inventoryUrl}/inventories/${inventoryId}/allocations/${id}/prices`,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      })
      .then(
        (response) => {
          return response.data;
        }
      ));
};



const useManageInventoryAllocationPrices = () => {
  const queryClient = useQueryClient();
  
  const managePrices = async (
    [priceType,  items, callback]: [string, PriceDto[], () => void]
  ): Promise<AxiosResponse<any, any>[]> => {

    const endpoint =  HookConfig.inventoryUrl;
    const baseUrl  = priceType === 'allocation' ? `${endpoint}/allocation-prices` : `${endpoint}/base-prices`
    const promises = items.filter(item => item.price !== 0)
      .map(async (item) => {
        const url = item.id === 0 ? `${baseUrl}`: `${baseUrl}/${item.id}`;
        const method = item.id === 0 ? "post" : "put";

       

        const response = await axios[method](
          url, item,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders() ,
          }
        );
        return response;
      });
    const responses = await Promise.all(promises);
    return responses;
  };

  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [priceType : string,  prices: PriceDto[], callback : () => void ]
  >(managePrices, {
    onSuccess: (responses, [priceType, items, callback]) => {
        callback();
      }
  });

  return mutation;
};




const useManageAllocationInventory = (callback: (inventoryAllocation: InventoryAllocationDto) => void, update: boolean) => {
  const queryClient = useQueryClient();  
  const method = update ? 'put' : 'post';
  return  useMutation<AxiosResponse, AxiosError, InventoryAllocationDto> (
    (inventoryAllocation) => axios[method](
      update ? `${HookConfig.inventoryUrl}/inventories/${inventoryAllocation.inventoryId}/allocations/${inventoryAllocation.id}` : `${HookConfig.inventoryUrl}/inventories/${inventoryAllocation.inventoryId}/allocations`
      , inventoryAllocation,
      {
        withCredentials: true,
        headers: operatorDefaultHeaders()
      }
    ),
    {
      onSuccess: (response, inventoryAllocation) => {

        queryClient.invalidateQueries(["inventory", inventoryAllocation.inventoryId]);
        const fetchData = async (id : string, inventoryId : string) => {
          try {
            const response = await fetch(`${HookConfig.inventoryUrl}/inventories/${inventoryId}/allocations/${id}/`, { credentials: "include", headers: operatorDefaultHeaders() });
            const data = await response.json();
            callback(data);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
      
        fetchData(response.data.id, inventoryAllocation.inventoryId);
        
      },
      onError: (_, error) => {
        console.log(error);
      }
    }
  );
};


const useManageInventoryVariants = () => {
  const queryClient = useQueryClient();
  
  const manageVariants = async (
    [ items, callback]: [ InventoryVariantDto[], () => void]
  ): Promise<AxiosResponse<any, any>[]> => {

    const endpoint =  HookConfig.inventoryUrl;
    const baseUrl  = `${endpoint}/inventory-variants/`//`${endpoint}/inventories/${inventoryId}/allocations/${inventoryAllocationId}/prices`
    const promises = items.filter(item => item.value.trim() !== '')
      .map(async (item) => {
        const url = item.id === '00000000-0000-0000-0000-000000000000' ? `${baseUrl}`: `${baseUrl}/${item.id}`;
        const method = item.id === '00000000-0000-0000-0000-000000000000' ? "post" : "put";

        const body = item.id === "00000000-0000-0000-0000-000000000000" ?
        {
          locationId: `${item.locationId}`,
          inventoryTypeName: `${item.inventoryTypeName}`,
          variantName: `${item.name}`,
          value: `${item.value}`
        } :
        {
          locationId: `${item.locationId}`,
          id : `${item.id}`,
          value: `${item.value}`
        }

        const response = await axios[method](
          url, body,
          {
            withCredentials: true,
            headers: operatorDefaultHeaders() ,
          }
        );
        return response;
      });
    const responses = await Promise.all(promises);
    return responses;
  };

  const mutation = useMutation<
    AxiosResponse<any, any>[],
    AxiosError<Problem>,
    [ variants: InventoryVariantDto[], callback : () => void ]
  >(manageVariants, {
    onSuccess: (responses, [items, callback]) => {
        callback();
      }
  });

  return mutation;
};


export {
  useFetchInventoryByLocationId,
  useManageInventory,
  useFetchInventoryTypes,
  useFetchInventoryById,
  useFetchInventoryAllocationById,
  useManageAllocationInventory,
  useFetchInventoryAllocationPrices,
  useManageInventoryAllocationPrices,
  useFetchInventoryUnavailabilityTypes,
  useManageInventoryUnavailability,
  useDeleteAllocation,
  useDeleteUnavailability,
  useDeleteInventory,
  useManageInventoryVariants
}