import { Typography } from "@mui/material";
import { SummaryIcon, SummaryItem } from "../SummaryItems/SummaryItem";

type Args = {
    linkCallBack? : () => void
    ctaText? : string,
    name: string,
    title: string, 
    description: string,
};

const SectionHead = ({ctaText, name, title, description, linkCallBack}: Args) => {
    return (
        <>
        <div className="row d-flex justify-content-center fixed-top fixSummary">
                <div className="col-md-2 col-4 justify-content-center">
                    {
                        ctaText && 
                        <SummaryItem linkOnText={true} simpleText={true} linkCallBack={linkCallBack} name={ctaText} ></SummaryItem>
                    }
                </div>
                <div className="col-md-3 col-7 justify-content-center">
                    <SummaryItem  icon={SummaryIcon.None} textAlignFlex='flex-end' textClass='entityName' simpleText={true} name={description} ></SummaryItem>
                </div>
            </div>
            <div className="row d-flex justify-content-center firstElement">
                <div className="col-md-5 col-11 justify-content-center">
                    <Typography variant="h5">{title}</Typography>
                </div>
            </div>
            <div className="row d-flex mb-4 justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <Typography variant="body2" className="entityName">{name}</Typography>
                </div>
            </div>
            </>
    );
}

export {
    SectionHead
}