import { useAddMember, useFetchMemberById, useUpdateMember } from "../../hooks/Member/member";
import i18n from "../../i18n";
import { ApplicationUser } from "../../types/Authentication/ApplicationUser";
import { MemberRequestDto } from "../../types/Member/MemberRequestDto";
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { MemberForm } from "./MemberForm";
import { useNavigate, useParams } from "react-router";
import ApiStatus from "../Common/Errors/ApiStatus";
import { UseRegisterDelegatedEmail } from "../../hooks/Login/login";
import { UserRegistrationResponse } from "../../types/Login/UserRegistrationResponse";
import {  useState } from "react";import { UserRegistrationRequest } from "../../types/Login/UserRegistrationRequest";
import { MemberResponseDto } from "../../types/Member/MemberResponseDto";
import { useDirtyState } from "../../hooks/Common/dirty";
import { ConfirmationChangesDialog } from "../Common/ConfirmationChangesDialog";
import { GenerateExternalReference } from "../../functions/utils/helper";
import { Backdrop, CircularProgress } from "@mui/material";
import { SectionHead } from "../Common/Help/SectionHead";

type Args =
    {
        userLogged: ApplicationUser
        member?: MemberRequestDto
        isEdit: boolean
    }

const ContactManage = ({ userLogged, member, isEdit }: Args) => {
    const memberType = 'OperatorContact'
    const nav = useNavigate();
    const params = useParams();
    const parentId = params.parentId;

    if (!parentId) {
        Error("Operator id needed. Wrong operation attempted");
    }

    const emptyUserRegistration: UserRegistrationRequest =
    {
        firstName: "",
        lastName: "",
        confirmPassword: "",
        email: "",
        password: "",
        countryISOCode: ""
    }

    if (!member) {
        member = {
            countryIso3Code: '',
            enrolmentCode: '',
            fullName: '',
            languageCode: i18n.language,
            memberTypeName: memberType,
            person: {
                email: '',
                firstName: '',
                jobTitle: '',
                lastName: '',
            }
        };
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(parentId!);
    const addMemberMutation = useAddMember(memberType, parentId);
    const updateMemberMutation = useUpdateMember("OperatorContact", parentId);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [isDirty, setDirty, unsetDirty] = useDirtyState();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [targetUrl, setTargetUrl] = useState('');
    const [error, setError] = useState<string>('');

    const linkCallBack = () => {

        const url = `/operators/${parentId}/contacts`;

        if (isDirty) {
            setTargetUrl(url);
            setShowConfirmation(true);
        }
        else {
            nav(url);
        }
    }

     //#region Dirty
     const handleDirty = () => {
        setDirty();
    };

    const dialogConfirm = () => {
        setShowConfirmation(false);
        unsetDirty();
        nav(targetUrl);
    }

    const dialogCancel = () => {
        setShowConfirmation(false);
    }
    //#endregion

    const AddMember = async (member: MemberRequestDto) => {
        unsetDirty();
        setError('');
        setSubmitting(true);
        member.parentMemberId = parentId;
        member.fullName = `${member.person?.firstName} ${member.person?.lastName}`
        member.externalReference = GenerateExternalReference(member.fullName);

        emptyUserRegistration.authorUserId = userLogged.id!;
        emptyUserRegistration.firstName = member.person?.firstName!;
        emptyUserRegistration.lastName = member.person?.lastName!;
        emptyUserRegistration.countryISOCode = member.countryIso3Code;
        emptyUserRegistration.email = member.person?.email!;

        UseRegisterDelegatedEmail(emptyUserRegistration, function (response: UserRegistrationResponse) {

            if (response.id && !response.error) {
                member.registeredUserId = response.id;
                addMemberMutation.mutate(member)

            } else {
                setError(response.error);
                setSubmitting(false);
            }
        });
    }

    const UpdateMember = async (member: MemberRequestDto) => {
        unsetDirty();
        setSubmitting(true);

        let memberUpdate: MemberResponseDto =
        {
            fullName: `${member.person?.firstName} ${member.person?.lastName}`,
            person: member.person,
            externalReference : member.externalReference,
            countryIso3Code: member.countryIso3Code,
            id: params.id!,
            languageCode: member.languageCode,
            memberTypeFlags: 0
        };
        updateMemberMutation.mutate(memberUpdate);
    }


    if (!operatorIsSuccess) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;


    return (
        <>

     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={submitting}>
        <CircularProgress color="inherit" />
      </Backdrop>

            <ConfirmationChangesDialog handleConfirm={dialogConfirm} handleCancel={dialogCancel} showConfirmation={showConfirmation}></ConfirmationChangesDialog>
           
            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t('COMMON_SUMMARY_CONTACTS')!}
                    name={`${operator.fullName} (${operator.externalReference})`}
                    title={isEdit ? i18n.t('MEMBER_TITLE_EDITCONTACT') :i18n.t('MEMBER_TITLE_NEWCONTACT')}
                    description={i18n.t('COMMON_SUMMARY_OPERATORMANAGEMENT')} />


            <div className="row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <MemberForm
                        type={memberType}
                        submitting={submitting}
                        member={member}
                        handleDirty={handleDirty}
                        isEdit={isEdit}
                        submitted={(member) => (isEdit) ? UpdateMember(member) : AddMember(member)}
                    ></MemberForm>
                </div>
            </div>
            {(error || addMemberMutation.isError || updateMemberMutation.isError) &&
                <div className="row d-flex justify-content-center">
                    <div className="col-md-5 col-11 justify-content-center">
                        {
                            error &&
                            <MessageAlert message={error} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        }
                        {
                            addMemberMutation.isError &&
                            <MessageAlert message={addMemberMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        }
                        {
                            updateMemberMutation.isError &&
                            <MessageAlert message={updateMemberMutation.error.message} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
                        }

                    </div>
                </div>
            }
        </>
    );
};


export { ContactManage }