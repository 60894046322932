import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import "./App.css"
import Footer from '../components/Navigation/Footer';
import Login from '../components/Login/Login';
import Register from '../components/Login/Register';
import RecoverAccount from '../components/Login/ResetPassword';
import Menu from '../components/Navigation/Menu';
import { Dashboard } from '../components/Home/Dashboard';
import { ApplicationUser } from '../types/Authentication/ApplicationUser';
import { useIsUserLogged } from '../hooks/Authentication/userinfo';
import { MemberAdd } from '../components/Member/MemberAdd';
import { HostSummary } from '../components/Member/HostSummary';
import { CompanyDetails } from '../components/Member/CompanyDetails';
import { GlobalStateContext } from '../components/Common/GlobalStateProvider';
import { HostCommunicationTypes } from '../components/Member/HostCommunicationTypes';
import { HostDomains } from '../components/Member/HostDomains';
import { Notifications } from '../components/Notification/Notifications';
import Logout from '../components/Login/Logout';
import ResetPasswordAlreadyRegistered from '../components/Login/ResetPasswordAlreadyRegistered';
import { KycDetails } from '../components/Member/KycDetails';
import { LocationAdd } from '../components/Location/LocationAdd';
import { LocationSummary } from '../components/Location/LocationSummary';
import { LocationDetails } from '../components/Location/LocationDetails';
import { LocationCommunicationTypes } from '../components/Location/LocationCommunicationTypes';
import { ThemeProvider } from '@mui/material';
import { HostLocations } from '../components/Member/HostLocations';
import { LocationFeatures } from '../components/Location/LocationFeatures';
import { LocationDigitalAssets } from '../components/Location/LocationDigitalAssets';
import { LocationDescription } from '../components/Location/LocationDescription';
import { LocationFeatureGroup } from '../components/Location/LocationFeatureGroup';
import { PontTheme } from './Theme';
import { Contacts } from '../components/Member/Contacts';
import { ContactManage } from '../components/Member/ContactManage';
import { ContactEdit } from '../components/Member/ContactEdit';
import { HostFiscalDetails } from '../components/Member/HostFiscalDetails';
import { LocationCommunications } from '../components/Location/LocationCommunications';
import { HostDigitalAssets } from '../components/Member/HostDigitalAssets';
import { Operators } from '../components/Member/Operators';
import { Locations } from '../components/Location/Locations';
import { LocationInventories } from '../components/Location/LocationInventories';
import { InventoryAdd } from '../components/Inventory/InventoryAdd';
import { InventoryEdit } from '../components/Inventory/InventoryEdit';
import { AllocationAdd } from '../components/Inventory/AllocationAdd';
import { AllocationEdit } from '../components/Inventory/AllocationEdit';
import { UnavailabilityAdd } from '../components/Inventory/UnavailabilityAdd';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import i18n from '../i18n';

// English locales
import 'dayjs/locale/en'; // default English locale, used in many countries
import 'dayjs/locale/en-ca'; // Canada
import 'dayjs/locale/en-gb'; // United Kingdom
import 'dayjs/locale/en-au'; // Australia

// European locales
import 'dayjs/locale/de'; // Germany
import 'dayjs/locale/fr'; // France
import 'dayjs/locale/es'; // Spain
import 'dayjs/locale/it'; // Italy
import 'dayjs/locale/nl'; // Netherlands
import 'dayjs/locale/pl'; // Poland
import 'dayjs/locale/pt'; // Portugal
import 'dayjs/locale/ru'; // Russia
import 'dayjs/locale/sv'; // Sweden
import 'dayjs/locale/fi'; // Finland
import 'dayjs/locale/el'; // Greece
import 'dayjs/locale/ro'; // Romania
import 'dayjs/locale/cs'; // Czech Republic
import 'dayjs/locale/hu'; // Hungary
import 'dayjs/locale/sk'; // Slovakia

// Asian locales
import 'dayjs/locale/ja'; // Japan
import 'dayjs/locale/ko'; // Korea
import 'dayjs/locale/zh-cn'; // China
import 'dayjs/locale/zh-tw'; // Taiwan
import 'dayjs/locale/th'; // Thailand
import 'dayjs/locale/vi'; // Vietnam
import 'dayjs/locale/id'; // Indonesia
import { LocationConfiguration } from '../components/Location/Configuration/LocationConfiguration';
import { LocationConfigurationDetails } from '../components/Location/Configuration/LocationConfigurationDetails';
import { useContext, useEffect, useState } from 'react';
import { Home } from '../components/Home/Home';
import { LegalEntityAdd } from '../components/Member/LegalEntityAdd';
import { LegalEntities } from '../components/Member/LegalEntities';
import { ProductDetails } from '../components/Product/ProductDetails';
import { Products } from '../components/Product/Products';
import { ProductConfiguration } from '../components/Product/Configuration/ProductConfiguration';
import { ProductConfigurationDetails } from '../components/Product/Configuration/ProductConfigurationDetails';
import { ProductRuleDetails } from '../components/Product/Configuration/ProductRuleDetails';
import { ProductPortfolioDetails } from '../components/Product/Configuration/ProductPortfolioDetails';
import { MemberSetup } from '../components/Member/MemberSetup';
import { GlobalLocationConfiguration } from '../components/Location/GlobalConfiguration/GlobalLocationConfiguration';
import { GlobalLocationConfigurationDetails } from '../components/Location/GlobalConfiguration/GlobalLocationConfigurationDetails';
import { Events } from '../components/Event/Events';
import { EventSummary } from '../components/Event/EventSummary';
import { EventDigitalAssets } from '../components/Event/EventDigitalAssets';
import { EventFeatureGroup } from '../components/Event/EventFeatureGroup';
import { EventDescription } from '../components/Event/EventDescription';
import { EventCheckoutNote } from '../components/Event/EventCheckoutNote';
import { MembershipSummary } from '../components/Membership/MembershipSummary';
import { MembershipFeatureGroup } from '../components/Membership/MembershipFeatureGroup';
import { MembershipDescription } from '../components/Membership/MembershipDescription';
import { MembershipDigitalAssets } from '../components/Membership/MembershipDigitalAssets';
import { Memberships } from '../components/Membership/Memberships';
import { ConnectedAccountAdd } from '../components/Order/ConnectedAccountAdd';
import { ConnectedAccountEdit } from '../components/Order/ConnectedAccountEdit';
import { ApplicationConfiguration } from '../components/Application/ApplicationConfiguration';
import { ApplicationConfigurationDetails } from '../components/Application/ApplicationConfigurationDetails';
import { PostDetails } from '../components/Application/Post/PostDetails';
import { PostContent } from '../components/Application/Post/PostContent';
import { PostFeatureGroup } from '../components/Application/Post/PostFeatureGroup';
import { PostDigitalAssets } from '../components/Application/Post/PostDigitalAssets';
import { PostFeatures } from '../components/Application/Post/PostFeatures';
import { ProductDigitalAssets } from '../components/Product/ProductDigitalAssets';
import { BenefitDetails } from '../components/Product/BenefitDetails';

function Pont() {

  const globalState = useContext(GlobalStateContext);
  if (!globalState) {
    throw new Error('Used GlobalStateContext outside of GlobalStateProvider');
  }

  const { globalLoggedUser, setGlobalLoggedUser } = globalState;


  const userInfo: ApplicationUser = useIsUserLogged();
  const navigate = useNavigate();
  const [userLogged, setUserLogged] = useState<ApplicationUser>();
  const [dataLoaded, setDataLoaded] = useState(false);
  const location = useLocation();
  let mainbg = 'pontbg';

  // if (location.pathname.includes('/account/')) {
  //   mainbg = 'earthbg'
  // }
  // // if (location.pathname.includes('locations')) {
  // //   mainbg = 'locationsbg'
  // // }

  const handleLogout = () => {
    navigate(userInfo.logoutUrl);
  };


  const setupCallBack = (updatedUser: ApplicationUser) => {
    setUserLogged(updatedUser);
    setDataLoaded(true);
    setGlobalLoggedUser(updatedUser);
    localStorage.setItem(updatedUser.id + "_currentOperator", JSON.stringify(updatedUser.operator));
    navigate("/");
  }

  useEffect(() => {
    if (userInfo.isLogged && !dataLoaded) {      
      setDataLoaded(true);
      setUserLogged(userInfo);
      setGlobalLoggedUser(userInfo);
    }
  }, [userInfo, userInfo.isLogged, dataLoaded]);

  return (

    <>
      {
        globalLoggedUser &&
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language.toLowerCase()} >
          <div className={`${mainbg} fixed-bottom`}>
            {globalLoggedUser.isLogged && globalLoggedUser.doesNeedSetup === false &&
              <ThemeProvider theme={PontTheme}>
                <Menu userInfo={globalLoggedUser!}></Menu>
                {
                  dataLoaded && <>
                    <div className="myContainer">
                      <div className="renderZone">
                        <Routes>
                          <Route path="/loading" element={<Home userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/" element={<Dashboard userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/dashboard" element={<Dashboard userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/add" element={<LegalEntityAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/locations" element={<HostLocations userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/:id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/legal-entities/" element={<Operators userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/add" element={<MemberAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/summary" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/companydetails" element={<CompanyDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/domains" element={<HostDomains userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/communications/:communicationType" element={<HostCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/contacts" element={<Contacts userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:parentId/contacts/add" element={<ContactManage isEdit={false} userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:parentId/contacts/:id" element={<ContactEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/locations" element={<HostLocations userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/legal-entities" element={<LegalEntities userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/summary/:summaryName" element={<HostSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/digital-assets/:groupName" element={<HostDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/contact-management/notifications" element={<Notifications userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/fiscal-details/:country" element={<HostFiscalDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/kyc-details/:country" element={<KycDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/" element={<Locations userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/add" element={<LocationAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:operatorId/location/add" element={<LocationAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/summary" element={<LocationSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories" element={<LocationInventories userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories/add" element={<InventoryAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories/:inventoryId" element={<InventoryEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories/:inventoryId/unavailability/add" element={<UnavailabilityAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories/:inventoryId/allocations/add" element={<AllocationAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/inventories/:inventoryId/allocations/:inventoryAllocationId" element={<AllocationEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/locationdetails" element={<LocationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/communications" element={<LocationCommunications userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/configuration" element={<LocationConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/configuration/:configurationName" element={<LocationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/features" element={<LocationFeatures userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/features/:featuregroup" element={<LocationFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/summary/:summaryName" element={<LocationSummary userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/digital-assets/:groupName" element={<LocationDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/description" element={<LocationDescription userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/:id/communications/:communicationType" element={<LocationCommunicationTypes userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/logout" element={<Logout onLogout={handleLogout}></Logout>}></Route>
                          <Route path="/me/resetpassword/" element={<ResetPasswordAlreadyRegistered userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/me/:id/notifications" element={<Notifications userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/operators/:id/" element={<ResetPasswordAlreadyRegistered userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/me/accountsettings/:id" element={<ContactEdit userLogged={globalLoggedUser!} isCurrentUserEdit={true} />}></Route>
                          <Route path="/products" element={<Products userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/add" element={<ProductDetails userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/products/configuration" element={<ProductConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/:configurationName" element={<ProductConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/productrules/:id" element={<ProductRuleDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/configuration/pricingportfolio/:id" element={<ProductPortfolioDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/:id" element={<ProductDetails userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/products/:id/digital-assets/:groupName" element={<ProductDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/products/:productId/benefits/add" element={<BenefitDetails key={Math.random()} userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/products/:productId/benefits/:id" element={<BenefitDetails key={Math.random()} userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/locations/configuration" element={<GlobalLocationConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/locations/configuration/:configurationName" element={<GlobalLocationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/" element={<Events userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/add" element={<EventSummary userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/events/:id/summary" element={<EventSummary userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/events/:id/digital-assets/:groupName" element={<EventDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/features" element={<EventFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/description" element={<EventDescription userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/events/:id/checkoutnote" element={<EventCheckoutNote userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/" element={<Memberships userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/add" element={<MembershipSummary userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/memberships/:id/summary" element={<MembershipSummary userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/memberships/:id/digital-assets/:groupName" element={<MembershipDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:id/features" element={<MembershipFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:id/description" element={<MembershipDescription userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/memberships/:inventoryId/summary/:id/unavailability/add" element={<UnavailabilityAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/connected-accounts/add" element={<ConnectedAccountAdd userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/system/connected-accounts/:id" element={<ConnectedAccountEdit userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/configuration" element={<ApplicationConfiguration userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/:configurationName" element={<ApplicationConfigurationDetails userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/add" element={<PostDetails userLogged={globalLoggedUser!} isEdit={false} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId" element={<PostDetails userLogged={globalLoggedUser!} isEdit={true} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/digital-assets/:groupName" element={<PostDigitalAssets userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/content" element={<PostContent userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/features" element={<PostFeatures userLogged={globalLoggedUser!} />}></Route>
                          <Route path="/application/:id/configuration/posts/:postId/features/:featuregroup" element={<PostFeatureGroup userLogged={globalLoggedUser!} />}></Route>
                        </Routes>
                      </div>
                    </div>
                  </>
                }
                {!dataLoaded &&
                  <>
                    <h1>Loading...</h1>
                  </>
                }

              </ThemeProvider>
            }

            <Footer></Footer>
          </div>
        </LocalizationProvider>
      }

      {globalLoggedUser && globalLoggedUser.doesNeedSetup &&
        <div className={`${mainbg} fixed-bottom`}>
          <div>
            <div className="myContainer">
              <div className="renderZone">
                <Routes>
                  <Route path="/" element={<MemberSetup setupCallBack={setupCallBack} userLogged={globalLoggedUser!} />}></Route>
                  <Route path="/logout" element={<Logout onLogout={handleLogout}></Logout>}></Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      }


      {userInfo.isDeclined &&
        <div className={`earthbg fixed-bottom`}>
          <div>
            <div className="myContainer">
              <div className="renderZone">
                <Routes>
                  <Route path="/" element={<Login />}></Route>
                  <Route path="/account/login" element={<Login />}></Route>
                  <Route path="/account/resetpassword/" element={<RecoverAccount />}></Route>
                  <Route path="/account/register/" element={<Register />}></Route>
                  <Route path="/account/register/:email" element={<Register />}></Route>
                </Routes>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      }

      {!globalLoggedUser && !userInfo &&

        <h1>Loading 2...</h1>
      }
    </>

  );
}

export default Pont;
