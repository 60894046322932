import { useNavigate, useParams } from "react-router-dom";
import { ApplicationUser } from "../../../types/Authentication/ApplicationUser";
import ApiStatus from "../../Common/Errors/ApiStatus";
import i18n from "../../../i18n";
import { useFetchMemberById } from "../../../hooks/Member/member";
import { SectionHead } from "../../Common/Help/SectionHead";
import { BulkUpload } from "./BulkUpload";

type Args =
    {
        userLogged: ApplicationUser
    }

const GlobalLocationConfigurationDetails = ({ userLogged }: Args) => {

    const nav = useNavigate();
    const queryStringParams = useParams();
    if (!queryStringParams.configurationName) throw Error("configurationName id needed");

    const label = `LOCATION_LABEL_${queryStringParams.configurationName.toUpperCase()}`
    const callBackUrl = queryStringParams.configurationName === 'memberupload' ? '/' : `/locations/configuration/`;
    const sectionHeadDescription = queryStringParams.configurationName === 'memberupload' ? 'COMMON_SUMMARY_OPERATORMANAGEMENT' : 'COMMON_SUMMARY_LOCATIONMANAGEMENT';
    const callToActionText = queryStringParams.configurationName === 'memberupload' ? 'COMMON_SUMMARY_DASHBOARD' : 'LOCATION_SUMMARY_CONFIGURATION';  

    const linkCallBack = () => {
        nav(callBackUrl);
    }

    const { data: operator, status: operatorStatus, isSuccess: operatorIsSuccess } = useFetchMemberById(userLogged.operator?.id!)

    if ((!operatorIsSuccess)) return (
        <>
            <div className="mt-5 row d-flex justify-content-center">
                <div className="col-md-5 col-11 justify-content-center">
                    <ApiStatus status={operatorStatus} />
                </div>
            </div>
        </>
    )

    if (!operator) return <ApiStatus status={operatorStatus} />;


    return (
        <>



            <SectionHead linkCallBack={linkCallBack} ctaText={i18n.t(callToActionText)!} name='' title={i18n.t(label)} description={i18n.t(sectionHeadDescription)} />


            {
                (queryStringParams.configurationName === 'locationsupload' || queryStringParams.configurationName === 'inventoryupload' || queryStringParams.configurationName === 'memberupload' || queryStringParams.configurationName === 'orderupload' )&&
                <BulkUpload type={queryStringParams.configurationName === 'locationsupload' ? 'location' : queryStringParams.configurationName === 'inventoryupload' ? 'inventory' : queryStringParams.configurationName === 'memberupload' ? 'member' : 'order'} userLogged={userLogged}/>
            }
        </>
    );
};


export { GlobalLocationConfigurationDetails }