import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { Collapse, IconButton, List, Paper, Tooltip } from '@mui/material';
import "./cards.css"
import ApiStatus from '../Common/Errors/ApiStatus';
import MessageAlert from '../Common/Errors/MessageAlert';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import { useFetchLocations } from '../../hooks/Location/location';
import i18n from '../../i18n';
import HelpIcon from '@mui/icons-material/Help';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { ApplicationUser } from '../../types/Authentication/ApplicationUser';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { LR } from '../Common/Help/LR';
import { useFetchMemberships } from '../../hooks/Memberships/membership';

type Args = {
  userLogged: ApplicationUser
  setHelp: (value: string) => void
}

const MembershipsCard = ({ userLogged, setHelp }: Args) => {
  const nav = useNavigate();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isListVisible, setIsListVisible] = useState(false);

  const handleToggleList = () => {
    setIsListVisible(!isListVisible);
  };

  const { data: memberships, status, isSuccess } = useFetchMemberships(userLogged.operator?.id!);//globalLoggedUser?.operator?.id!;

  const editMembership = (membershipId: string) => {
    nav(`/memberships/${membershipId}/summary`);
  }

  if (!isSuccess) return (
    <>
      <div className="mt-5 row d-flex justify-content-center">
        <div className="col-md-5 col-11 justify-content-center">
          <ApiStatus status={status} />
        </div>
      </div>
    </>
  )

  if (!memberships) return <MessageAlert message="MEMBERSHIPS_LABEL_MEMBERSHIPSNOTFOUND"></MessageAlert>;

  return (
    <>

      <Box sx={{ minWidth: 300, maxWidth: 300, minHeight: 400, maxHeight: 400 }}>
        <Card>
          <React.Fragment>
            {/* Header with Title and Arrow */}
            <CardContent className='DashBoardCard' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography className='cardTitle' gutterBottom>
                <LR localResource='COMMON_LABEL_MEMBERSHIPS'></LR>
              </Typography>
              <IconButton className="fa-arrow" onClick={handleToggleList}>
                {!isListVisible && <ArrowForwardIosRoundedIcon />}
                {isListVisible && <ArrowForwardIosRoundedIcon className='fa-arrow-down' />}
              </IconButton>
            </CardContent>

            <div className="list-container" >
              {/* Initial Display */}
              {!isListVisible && (
                <div className='cardCounter locationCardCounter'>
                  <Typography variant="h1">{memberships.length}</Typography>
                </div>
              )}

              {/* List Container */}
              <Collapse in={isListVisible}>
                <List component="nav" aria-label="main mailbox folders">
                  {memberships.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Paper key={index} elevation={3} className="fa-paper-card">
                          <div className="d-flex align-items-center">
                            <div className="fa-name flex-grow-1 fa-name-card">
                              {item.name}
                            </div>
                            <div className="fa-icons">
                              <IconButton onClick={(event) => editMembership(item.id.toString())}>
                                <ArrowForwardIosRoundedIcon className='fa-arrow-small' />
                              </IconButton>
                            </div>
                          </div>
                        </Paper>
                      </React.Fragment>
                    );
                  })}
                </List>

              </Collapse>
            </div>

            {/* Card Actions */}
            <CardActions className='DashBoardCard'
              disableSpacing
              sx={{
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-start",
                p: 0,
              }}>
              <IconButton aria-label="share" className='btn plusButton mr-2' href="/memberships/add">
                <Tooltip title={i18n.t('MEMBERSHIP_TITLE_NEWMEMBERSHIP')}>
                  <AddCircleRoundedIcon sx={{ color: '#00be95' }} />
                </Tooltip>
              </IconButton>
              <IconButton aria-label="share" href="/memberships">
                <Tooltip title={i18n.t('MEMBERSHIP_TITLE_SEARCHMEMBERSHIPS')}>
                  <SearchRoundedIcon />
                </Tooltip>
              </IconButton>
              <IconButton aria-label="share" onClick={(e) => setHelp('MEMBERSHIP_MESSAGE_NEWMEMBERSHIP')}>
                <Tooltip title={i18n.t('COMMON_LABEL_HELP')}>
                  <HelpIcon />
                </Tooltip>
              </IconButton>
            </CardActions>
          </React.Fragment>
        </Card>
      </Box>




    </>
  );

}

export { MembershipsCard as MembershipsCard }
