import { useState } from "react";
import { LR } from "../Common/Help/LR";
import i18n from "../../i18n";
import React from "react";
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import MessageAlert, { AlertStyle } from "../Common/Errors/MessageAlert";
import { DomainDto } from "../../types/Common/DomainDto";
import { useDeleteDomain, useManageMemberDomains } from "../../hooks/Member/domains";
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import { Tooltip } from "@mui/material";

type Args = {
  entityKey: string,
  entityName : string,
  items: DomainDto[],
  handleDirty: () => void
};

const DomainCollection = ({ entityKey, entityName, items, handleDirty }: Args) => {

  const isFormValid = items.every((item) => item.value.trim() !== '');
  const [itemsState, setItemsState] = useState<DomainDto[]>(items);
  const [isFormValidState, setIsFormValidState] = useState<boolean>(isFormValid);
  const deleteDomain = useDeleteDomain();
  const { mutate } = useManageMemberDomains();

  const setItemValue = (item: DomainDto, value: string) => {
    let updatedItem = { ...item, value: value };
    updatedItem = validateForm(updatedItem);
    const updatedItemsState = itemsState.map((i) => (i === item ? updatedItem : i));
    setIsFormValidState(updatedItemsState.every((item) => item.value.trim() !== '' && !item.errorMessage));
    setItemsState(updatedItemsState);
  };

  const onFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      itemsState.forEach(item => {
        if (item.isDeleted) {
          executeDelete(item);
        }
      });

      mutate([entityKey, itemsState.filter((i) => !i.isDeleted && i.value !== '')])
    } catch (err: any) {
      console.error(err);
    }
  }

  const executeDelete = async (item: DomainDto) => {

    const deletionResult = await deleteDomain(item) as { success: boolean; error?: any };;
    if (deletionResult.success) {
      const updatedItemsState = itemsState.filter((i) => i.id !== item.id);
      setItemsState([...updatedItemsState]);
      console.log('Deletion successful');
    } else {
      // Deletion encountered an error, handle accordingly
      console.log('Error deleting item:', deletionResult.error!);
    }
  };

  const deleteItem = async (item: DomainDto) => {

    if (item.id !== "00000000-0000-0000-0000-000000000000") {
      handleDirty();
      const updatedItem = { ...item, isDeleted: true };
      const updatedItemsState = itemsState.map((i) => (i.id === item.id ? updatedItem : i));
      setItemsState([...updatedItemsState]);
      setIsFormValidState(true); // we need to be able to save
    } else {
      const updatedItemsState = itemsState.filter((i) => i.id !== item.id);
      setItemsState([...updatedItemsState]);
    }
  };

  const validateForm = (item: DomainDto): DomainDto => {
    let domain = item.value.replace(/^(https?|http):\/\/(www\.)?/i, '');
    const pattern = /^[a-zA-Z0-9-]{3,}(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;
    if (!pattern.test(domain)) {
      item.errorMessage = 'COMMON_ERROR_DOMAINFORMATTING';
    }else
    {
      item.errorMessage = ''
    }
    return item;
  };

  const addNewItem = () => {
    const hasBlankDomain = itemsState.some((item) => item.value.trim() === '');
    if (!hasBlankDomain) {
      const newItem: DomainDto = { id: "00000000-0000-0000-0000-000000000000", entityKey: entityKey, entityName : entityName, value: '', errorMessage: '', isDeleted: false };
      const updatedItemsState = [...itemsState, newItem];
      setItemsState(updatedItemsState);
      setIsFormValidState(false); // Disable form until the new item is filled
    }
  };

  return (
    <form onSubmit={onFormSubmit}>

      <div className="mt-4 row d-flex justify-content-center">
        <div className="col-md-5 col-11 d-flex align-items-center ml-auto">
          <button
            type="button"
            className="btn"
            onClick={addNewItem}>
            <AddBoxRoundedIcon fontSize="medium" sx={{ color: '#00be95' }} />
          </button>
          <span className="lead align-self-center">
            <LR localResource="COMMON_TITLE_DOMAIN" />
          </span>
        </div>
      </div>

      {itemsState.map((item, index) => (
        <React.Fragment key={item.id}>
          <div className="row d-flex justify-content-center mt-1 mb-3">
            <div className="col-md-5 col-11 d-flex align-items-center">
              <input
                type="text"
                value={item.value}
                className={`form-control fa-form ${item.isDeleted ? 'strikethrough' : ''}`}
                onChange={(e) => {setItemValue(item, e.target.value); handleDirty()}}
                disabled={item.isDeleted}
                placeholder={i18n.t(`COMMON_PLACEHOLDER_DOMAIN`).toString()}
              />
              <div>
              <Tooltip title={i18n.t('COMMON_TOOLTIP_DELETE', { param0: item.value })}>
                <button
                  className="btn btn-link fa-info"
                  onClick={() => deleteItem(item)} type="button">
                  <DeleteRoundedIcon color="error" />
                </button>
                </Tooltip>
              </div>
            </div>
          </div>
          {(item.errorMessage) &&
            <div className="row d-flex justify-content-center">   
              <div className="col-md-5 col-11 small justify-content-center">
                <MessageAlert message={item.errorMessage} variant="danger" style={AlertStyle.Tiny} additionalClassName="fa-alert-tiny"></MessageAlert>
              </div>
            </div>
          }
        </React.Fragment>
      ))}

      <div className="row d-flex justify-content-center mt-3">
        <div className="col-md-2 col-6 justify-content-center">
          <button
            type="submit"
            disabled={!isFormValidState}
            className="btn btn-green btn-primary btn-block"
          >
            <LR localResource="COMMON_LABEL_SAVE" />
          </button>
        </div>
      </div>
    </form>
  );
}

export { DomainCollection };
